import React, { useEffect, useState } from "react";
import {
  Row,
  Col,
  Card,
  Statistic,
  Button,
  List,
  Descriptions,
  Avatar,
  Typography,
  Tabs,
  Table,
} from "antd";
import "./Client.scss";

import {
  DownloadOutlined,
  PlusOutlined,
  LeftOutlined,
  UserOutlined,
} from "@ant-design/icons";
import file from "./../assets/images/file.png";
import bank from "./../assets/images/bank.png";

import { withRouter } from "react-router-dom";
import moment from "moment";
import { useDispatch, useSelector } from "react-redux";
import { fetchClientImage, getClientCustomField, getClientDocuments } from "../actions";
import { openNotifcation } from "../components/Notification";
import payrollApi from "../api/payrollApi";
import { nanoid } from "nanoid";

const { Title } = Typography;
const { TabPane } = Tabs;

const Client = withRouter(({ history }) => {
  const [client, setClient] = useState(history.location.state.client);
  const loadingImage = useSelector(({ clients }) => clients.loadingImage);
  const image = useSelector(({ clients }) => clients.image);
  const imageError = useSelector(({ clients }) => clients.imageError);
  const imageErrorMsg = useSelector(({ clients }) => clients.imageErrorMsg);
  const customFields = useSelector(({ clients }) => clients.customFields);
  const clientDocs = useSelector(({ clients }) => clients.documents);
  const [idData, setIdData] = useState([]);
  const [bankData, setBankData] = useState([]);
  const loadingCustomFields = useSelector(
    ({ clients }) => clients.loadingClientCustomFields
  );
  const dispatch = useDispatch();

  const id_columns = [
    {
      title: "ID Type",
      dataIndex: "idType",
    },
    {
      title: "ID Number",
      dataIndex: "idNumber",
    },
  ];

  useEffect(() => {
    customFields.forEach((field) => {
      if (field.name === "Bank Account Information") {
        field.values.map((value) => {
          const data = [
            {
              key: `bank-${nanoid()}`,
              bank: (
                <>
                  <Avatar.Group>
                    <Avatar
                      className="shape-avatar"
                      src={bank}
                      size={40}
                      alt="file-icon"
                    />
                    <div className="avatar-info">
                      <Title level={5}>{value["Bank Name"]}</Title>
                    </div>
                  </Avatar.Group>
                </>
              ),
              accountNo: (
                <>
                  <Title level={5}>{value["Account Number"]}</Title>
                </>
              ),
            },
          ];

          setBankData(data);
        });
      }
    });
  }, [customFields]);

  const bank_columns = [
    {
      title: "Bank",
      dataIndex: "bank",
    },
    {
      title: "Account Number",
      dataIndex: "accountNo",
    },
  ];

  // const bankData = [
  //   {
  //     key: "1",
  //     bank: (
  //       <>
  //         <Avatar.Group>
  //           <Avatar
  //             className="shape-avatar"
  //             src={bank}
  //             size={60}
  //             alt="file-icon"
  //           />
  //           <div className="avatar-info">
  //             <Title level={5}>GCB</Title>
  //           </div>
  //         </Avatar.Group>
  //       </>
  //     ),
  //     accountNo: (
  //       <>
  //         <Title level={5}>7021010052232</Title>
  //       </>
  //     ),
  //   },
  // ];

  const loansColumns = [
    {
      title: "loanID",
      dataIndex: "loanID",
    },
    {
      title: "Amount requested",
      dataIndex: "amountrequested",
    },
    {
      title: "approved",
      dataIndex: "approved",
    },
    {
      title: "Status",
      dataIndex: "status",
    },
    {
      title: "Report",
      dataIndex: "report",
    },
  ];

  const loansData = [
    {
      key: "1",
      loanID: (
        <>
          <div className="author-info">
            <Title level={5}>PN88924</Title>
          </div>
        </>
      ),
      amountrequested: (
        <>
          <div className="author-info">
            <Title level={5}>2000</Title>
          </div>
        </>
      ),
      approved: (
        <>
          <div className="author-info">
            <Title level={5}>1000</Title>
          </div>
        </>
      ),
      status: (
        <>
          <div className="author-info">
            <Title level={5}>CLOSED</Title>
          </div>
        </>
      ),
      report: (
        <>
          <div className="author-info">
            <Button icon={<DownloadOutlined />}>Download</Button>
          </div>
        </>
      ),
    },
  ];

  const routeToAddLoan = () => {
    history.push("/add-loan", { clientID: client.id });
  };

  const getAddress = (id) => {
    payrollApi.get(`clients/${id}/addresses`);
  };

  const getImages = () => {
    dispatch(fetchClientImage(client.id, { onError: handleGetImageError }));
  };

  const getDocuments = (id) => {
    payrollApi.get(`clients/${id}/identifiers`);
  };

  const handleCustomFieldError = () => {
    openNotifcation("error", "Error getting client custom field");
  };

  const handleCustomFieldSuccess = () => {
    openNotifcation("success", "Custom fields loaded");
  };

  const handleGetImageError = () => {
    openNotifcation("error", "No client image");
  };

  useEffect(() => {
    dispatch(
      getClientCustomField(client.id, {
        onError: handleCustomFieldError,
      })
    );
  }, []);

  useEffect(() => {
    dispatch(getClientDocuments(client.id));
    getImages();
  }, []);

  useEffect(() => {
    formatIdDetails();
  }, [clientDocs]);

  let _idDetails = [];
  const formatIdDetails = () => {
    clientDocs.map((doc, i) => {
      const _doc = {
        key: `id-${i}`,
        idType: (
          <>
            <div className="avatar-info">
              <Title level={5}>{doc.idType}</Title>
            </div>
          </>
        ),
        idNumber: (
          <>
            <div className="avatar-info">
              <Title level={5}>{doc.idNumber}</Title>
            </div>
          </>
        ),

      };
      _idDetails.push(_doc);

    });
    setIdData(_idDetails);
  }

  const goBack = () => {
    history.goBack();
  };

  return (
    <div className="tabled client">
      <Row gutter={[24, 0]}>
        <div className="client__row">
          <div className="client__row--btns">
            <Button onClick={() => goBack()} icon={<LeftOutlined />}>
              Back
            </Button>

            <Button
              onClick={() => routeToAddLoan()}
              icon={<PlusOutlined />}
              type="primary"
            >
              Add Loan
            </Button>
          </div>
        </div>

        <Col xs={24} md={16}>
          <Card className="mb-24">
            {/* <Title level={4}>Tab Section</Title> */}
            <Tabs defaultActiveKey="1">
              <TabPane key="1" tab="Personal">
                <Col span={24}>
                  <Card className="card-billing-info" bordered="false">
                    <div className="col-info">
                      <Descriptions>
                        <Descriptions.Item label="Client ID" span={3}>
                          {client.id}
                        </Descriptions.Item>
                        <Descriptions.Item label="External ID" span={3}>
                          {client.externalId}
                        </Descriptions.Item>
                        <Descriptions.Item label="First Name" span={3}>
                          {client.firstname}
                        </Descriptions.Item>
                        <Descriptions.Item label="Last Name" span={3}>
                          {client.lastname}
                        </Descriptions.Item>
                        <Descriptions.Item label="Middle Name" span={3}>
                          N/A
                        </Descriptions.Item>
                        <Descriptions.Item label="Date of Birth" span={3}>
                          {client.dob}
                        </Descriptions.Item>
                        <Descriptions.Item label="Age ~ Approximate" span={3}>
                          {moment().diff(client.dob, "years")}
                        </Descriptions.Item>
                        {/* <Descriptions.Item label="Marital Status" span={3}>
                          Married
                        </Descriptions.Item>
                        <Descriptions.Item label="Phone Number" span={3}>
                          {client.mobileNo}
                        </Descriptions.Item>
                        <Descriptions.Item label="Alt. Phone Number" span={3}>
                          N/A
                        </Descriptions.Item>
                        <Descriptions.Item label="Data Hub" span={3}>
                          Kumasi
                        </Descriptions.Item>
                        <Descriptions.Item label="Email" span={3}>
                          N/A
                        </Descriptions.Item>
                        <Descriptions.Item label="Postal Address" span={3}>
                          P.O.BOX 43,Kumasi
                        </Descriptions.Item>
                        <Descriptions.Item label="Residential" span={3}>
                          House Number AC12 Asaam, , Near Community Center
                          Mampong
                        </Descriptions.Item> */}
                      </Descriptions>
                    </div>
                  </Card>
                </Col>
              </TabPane>

              {/* <TabPane key="2" tab="Employment" disabled={loadingCustomFields}>
                <Col span={24}>
                  <Card className="card-billing-info" bordered="false">
                    <div className="col-info">
                      <Descriptions>
                        {customFields.map((field) => {
                          if (field.name.trim() === "Employment Information") {
                            return field.values.map((value) => (
                              <React.Fragment key={`employment-${nanoid()}`}>
                                <Descriptions.Item label="Employer" span={3}>
                                  {value.Employer}
                                </Descriptions.Item>
                                <Descriptions.Item label="Department" span={3}>
                                  {value.Department}
                                </Descriptions.Item>
                                <Descriptions.Item
                                  label="Old Staff ID"
                                  span={3}
                                >
                                  {value["Old Staff ID"]}
                                </Descriptions.Item>
                                <Descriptions.Item
                                  label="Authorising Officer"
                                  span={3}
                                >
                                  {value["Authorising Officer Name"]}
                                </Descriptions.Item>
                                <Descriptions.Item
                                  label="Authorising Officer Phone"
                                  span={3}
                                >
                                  {value["Authorising Officer Telephone"]}
                                </Descriptions.Item>
                              </React.Fragment>
                            ));
                          }
                        })}
                      </Descriptions>
                    </div>
                  </Card>
                </Col>
              </TabPane> */}
              <TabPane key="3" tab="ID" disabled={loadingCustomFields}>
                <Col span={24}>
                  <div className="table-responsive">
                    <Table
                      columns={id_columns}
                      dataSource={idData}
                      className="ant-border-space"
                      pagination={false}
                    />
                  </div>
                </Col>
              </TabPane>
              {/* <TabPane key="4" tab="Bank" disabled={loadingCustomFields}>
                <Col span={24}>
                  <div className="table-responsive">
                    <Table
                      columns={bank_columns}
                      dataSource={bankData}
                      className="ant-border-space"
                      pagination={false}
                    />
                  </div>
                </Col>
              </TabPane> */}
              {/* <TabPane key="5" tab="Loans" disabled={loadingCustomFields}>
                <Col span={24}>
                  <div className="table-responsive">
                    <Table
                      columns={loansColumns}
                      dataSource={loansData}
                      className="ant-border-space"
                      pagination={false}
                    />
                  </div>
                </Col>
              </TabPane> */}
            </Tabs>
          </Card>
        </Col>

        <Col span={24} md={8} className="mb-24">
          <Card bordered={false} className="client__imgCard">
            {/* <Title level={4}>Image Section</Title> */}

            <Avatar icon={<UserOutlined />} size={200} src={image} />
          </Card>
        </Col>
      </Row>
    </div>
  );
});

export default Client;
