import React, { useEffect, useState } from "react";

import {
  LeftOutlined,
  CheckCircleOutlined,
  UserOutlined,
} from "@ant-design/icons";
import {
  Button,
  Row,
  Card,
  Col,
  Typography,
  Form,
  Input,
  Checkbox,
  Divider,
  Avatar,
  Select,
  DatePicker,
} from "antd";
import { withRouter } from "react-router-dom";
import "./ApproveLoan.scss";
import TextArea from "antd/lib/input/TextArea";
import face4 from "../assets/images/face-4.jpg";
import { useDispatch, useSelector } from "react-redux";
import moment from "moment";
import { openNotifcation } from "../components/Notification";
import { approveLoan, getAllLoanProducts, processApprovalChecklist, fetchClientImage } from "../actions";
import { DATE_FORMAT } from "../constants";
import { nanoid } from "nanoid";
import { isCompositeComponent } from "react-dom/test-utils";

const { Title, Text } = Typography;
const CheckboxGroup = Checkbox.Group;

const ApproveLoan = withRouter(({ history }) => {
  const mandatoryCheckList = [
    "Client's Affordability Locked in TPRS",
    "Client's Documents Upload in TPRS",
    "Date of Birth Verified Against Loan Product",
    "Payslip History Verified",
    "Client ID Verified",
    "Loan/Advance Form Signed",
    "Authority Note Form Signed",
    "Client Image Captured",
    "Application Form Signed",
    "Direct Debit Forms Signed",
  ];

  const optionalCheckList = [
    "Guarantor(s) Signature",
    "Visit Paid To Client's Residence",
    "Visit Paid To Client's Workplace",
  ];
  const [checkedList, setCheckedList] = useState();
  const [optionCheckList, setOptionalList] = useState();
  const [indeterminate, setIndeterminate] = useState(false);
  const [checkAll, setCheckAll] = useState(false);
  const [checkAllOptional, setCheckAllOptional] = useState(false);
  const [loan, setLoan] = useState(history.location.state.loan);
  const [approvalNote, setApprovalNote] = useState("");
  const [disable, setDisable] = useState(true);
  const dispatch = useDispatch();
  const approvedLoan = useSelector(({ loans }) => loans.approvedLoan);
  const approvingLoan = useSelector(({ loans }) => loans.approvingLoan);
  const [showLoanApprovalForm, toggleApprovalForm] = useState(false);
  const [disbaleForm, setDisableForm] = useState(true);
  const [approvalForm] = Form.useForm();
  const loanProducts = useSelector(
    ({ loanProducts }) => loanProducts.loanProducts
  );
  const loadingLoanProducts = useSelector(
    ({ loanProducts }) => loanProducts.loading
  );

  const loanProductsError = useSelector(
    ({ loanProducts }) => loanProducts.error
  );

  const loanProductsErrorMsg = useSelector(
    ({ loanProducts }) => loanProducts.errorMsg
  );

  const approvalChecklistData = useSelector(
    ({ loans }) => loans.approval_checklist_data
  );

  const approvalChecklistError = useSelector(
    ({ loans }) => loans.approvalChecklistError
  );

  const approvalChecklistErrorMsg = useSelector(
    ({ loans }) => loans.approvalChecklistErrorMsg
  );

  const approvalChecklistSuccess = useSelector(
    ({ loans }) => loans.approvalChecklistSuccess
  );

  const disableApprovalButton = useSelector(
    ({ loans }) => loans.disableApprovalButton
  );

  const image = useSelector(({ clients }) => clients.image);

  const handleGetImageError = () => {
    openNotifcation("error", "No client image");
  };

  const getImages = () => {
    dispatch(fetchClientImage(loan.clientId, { onError: handleGetImageError }));
  };

  useEffect(() => {
    getImages();
    dispatch(getAllLoanProducts(loan.clientId));
  }, []);

  const errorApprovingLoan = useSelector(
    ({ loans }) => loans.loanApprovalError
  );

  const loanApprovalErrorMsg = useSelector(
    ({ loans }) => loans.loanApprovalErrorMsg
  );

  const [validCalc, setCalculationValid] = useState(false);

  const handleConfirmCalc = () => {
    //if()
  }

  useEffect(() => {
    {
      // checkAll && approvalNote.trim() !== ""
      //   ? setDisable(false)
      //   : setDisable(true);
      checkAll && validCalc
        ? setDisable(false)
        : setDisable(true);
    }
  }, [checkAll, approvalNote, validCalc]);

  const goBack = () => {
    history.goBack();
  };

  const routeToLoans = () => {
    history.push("/loans");
  };

  const onSubmitBtnClicked = () => {
    if (loanApprovalDate === "Invalid date") {
      openNotifcation("error", "Invalid date. Kindly use the date picker to select the date.");
    } else {
      const payload = {
        action: "approve",
        approvedOnDate: loanApprovalDate || moment(new Date()).format(DATE_FORMAT), // use selected loan approval date or default to current date
        note: approvalNote,
        approvedLoanAmount: approvedAmount || loan.principal,
        processingFee: processingFee, //|| loan.feeChargesAtDisbursementCharged
        tenure: updatedTenure || loan.numberOfRepayments,
        interestRate: prodInterestRate || loan.interestRatePerPeriod
      };

      console.log("[APPROVED LOAN PAYLOAD]:: ", payload);

      dispatch(approveLoan(loan.id, payload, routeToLoans));
    }
  };

  const onChange = (list) => {
    setCheckedList(list);
    setIndeterminate(!!list.length && list.length < mandatoryCheckList.length);
    setCheckAll(list.length === mandatoryCheckList.length);
  };

  const onCheckAllChange = (e) => {
    setCheckedList(e.target.checked ? mandatoryCheckList : []);
    setIndeterminate(false);
    setCheckAll(e.target.checked);

    setDisableForm(e.target.checked ? false : true);
  };

  const [optCount, setOptCount] = useState(0);

  const handleOptionalChecked = (event) => {

    let selectedCount = optCount;

    if (event.target.checked) {
      selectedCount = selectedCount + 1;
    } else {
      selectedCount = selectedCount - 1;
    }

    setOptCount(selectedCount);
    setCheckAllOptional(selectedCount === optionalCheckList.length);

  };

  useEffect(() => {
    loadApprovalFormValues();
  }, [optCount, checkAllOptional, approvalChecklistData]);

  const loadApprovalFormValues = () => {
    approvalForm.setFieldsValue({ amtApproved: approvalChecklistData.loanAmount });
    approvalForm.setFieldsValue({ loanTenure: approvalChecklistData.tenure });
  }

  const processChecklist = () => {
    const payload = {
      loanId: loan.id,
      allFieldsChecked: (checkAll && checkAllOptional)
    };

    console.log("[ApprovalChecklist Payload]:: ", payload);

    dispatch(processApprovalChecklist(payload));
    toggleApprovalForm(true);
  };

  const [prodInterestRate, setProdInterestRate] = useState();
  const [approvedAmount, setApprovedAmount] = useState();
  const [updatedTenure, setUpdatedTenure] = useState();
  const [processingFee, setProcessingFee] = useState();

  const updateProcessedChecklist = () => {
    console.log("checklist processed");
    const payload = {
      loanId: loan.id,
      allFieldsChecked: (checkAll && checkAllOptional),
      approvalUpdated: true,
      processingFee: processingFee, //|| loan.feeChargesAtDisbursementCharged,

      loanAmount: approvedAmount || loan.principal,
      tenure: updatedTenure || approvalChecklistData.tenure,
      interestRate: prodInterestRate || approvalChecklistData.interestRate

    };

    console.log("[ApprovalChecklist Payload]:: ", payload);

    dispatch(processApprovalChecklist(payload));
  };

  const onLoanProductSelected = (e) => {
    console.log("[UPDATED LOAN PROD SELECTED::" + e)
    let selectedProd = loanProducts.find(prod => prod.id == e);
    setProdInterestRate(selectedProd.interest);
    setUpdatedTenure(selectedProd.tenure);
  };

  const [loanApprovalDate, setLoanApprovalDate] = useState(null);

  const onApprovalDateChanged = (e) => {
    console.log("--SELECTED APPROVAL DATE:: ", e);

    const requestDate = loan.timeline.submittedOnDate.reverse().join("-");
    if (moment(e).isBefore(requestDate)) {
      openNotifcation("error", "Approval date cannot be before request date");
    } else {
      setLoanApprovalDate(moment(e).format(DATE_FORMAT));
    }

  }

  const renderClientInfo = () => {
    return (
      <>
        <Card
          bordered={false}
          className="card-project"
          cover={
            <Avatar
              className="shape-avatar"
              shape="circle"
              size={200}
              icon={<UserOutlined />}
              src={image}
            />
          }
        >
          <div className="card-tag">Name: {loan.clientName}</div>
          <div className="card-tag">Client ID: {loan.clientId}</div>
        </Card>
      </>
    );
  };

  const onGeneralInfoError = (err) => {
    console.log("[GENERAL INFO ERROR]:: ", err);
  };

  function disabledDate(current) {
    // Can not select days after current month
    return current > moment().endOf('day');
  }

  const renderLoanApprovalChecklist = () => {
    return (
      <>
        {showLoanApprovalForm ? (
          <>
            <Form form={approvalForm} layout="vertical" className="row-col" onFinishFailed={onGeneralInfoError}>
              <Form.Item
                className="approveLoan__grid--section__dropdown"
                label="Loan Tenure"
                name="loanTenure"
              >
                <Form.Item name="loanTenure">
                  <Select defaultValue={approvalChecklistData.tenure} loading={loadingLoanProducts} onSelect={(evt) => onLoanProductSelected(evt)} >
                    {loanProducts.map((product) => (
                      <Select.Option key={`loan-product-${nanoid()}`} value={product.id}>
                        {product.name}
                      </Select.Option>
                    ))}
                  </Select>
                </Form.Item>
              </Form.Item>

              <Form.Item
                className="approveLoan__grid--section__input"
                label="Amount Approved"
                name="amtApproved"
              >
                <Form.Item name="amtApproved">
                  {/* <Input type="number" /> */}
                  <Input type="number"
                    defaultValue={approvalChecklistData.loanAmount}
                    // value={approvedAmount}
                    onChange={(e) => setApprovedAmount(e.target.value)} />
                </Form.Item>
              </Form.Item>

              <Form.Item
                className="approveLoan__grid--section__input"
                label="Processing Fee"
                name="processingFee"
                rules={[
                  { required: true, message: "Please provide processing fee!" },
                ]}
              >
                <Form.Item name="processingFee">
                  <Input onChange={(e) => setProcessingFee(e.target.value)}
                    value={processingFee}
                    //defaultValue={approvalChecklistData.processingFee}
                    placeholder={"Enter loan processing fee"} />

                </Form.Item>
              </Form.Item>

              <Form.Item
                className="approveLoan__grid--section__input"
                label="Approval Date"
                name="approvalDate"
              >
                <Form.Item name="approvalDate">
                  {/* <Input type="date" onSelect={(e) => onApprovalDateChanged(e.target.value)} /> */}
                  <DatePicker inputReadOnly={true} disabledDate={disabledDate} onChange={(e) => onApprovalDateChanged(e)} />
                </Form.Item>
              </Form.Item>

              <Form.Item
                className="approveLoan__grid--section__input"
                label="Interest Rate"
                name="interestRate"
              >
                <Form.Item name="interestRate">
                  <Input disabled placeholder={prodInterestRate || approvalChecklistData.interestRate} value={prodInterestRate} />
                </Form.Item>
              </Form.Item>

              <Form.Item
                className="approveLoan__grid--section__input"
                label="Max Allowable Principal"
                name="maxAllowablePrinciple"
              >
                <Form.Item name="maxAllowablePrinciple">
                  <Input disabled placeholder={approvalChecklistData.maxAllowablePrinciple} />
                </Form.Item>
              </Form.Item>

              <Form.Item
                className="approveLoan__grid--section__input"
                label="Estimated Monthly Deductions"
                name="estMonthlyDeduc"
              >
                <Form.Item name="estMonthlyDeduc">
                  <Input disabled placeholder={approvalChecklistData.estimatedMonthlyDeduction} />
                </Form.Item>
              </Form.Item>

              <Form.Item
                className="approveLoan__grid--section__input"
                label="Total Deductions"
                name="totalDeductions"
              >
                <Form.Item name="totalDeductions">
                  <Input disabled placeholder={approvalChecklistData.totalDeduction} />
                </Form.Item>
              </Form.Item>

              <Form.Item
                className="approveLoan__grid--section__input"
                label="Estimated Total Interest"
                name="totalInterest"
              >
                <Form.Item name="totalInterest">
                  <Input disabled placeholder={approvalChecklistData.estimatedTotalInterest} />
                </Form.Item>
              </Form.Item>

              <Form.Item
                className="approveLoan__grid--section__input"
                label="Comments"
                name="comments"
              >
                <Form.Item name="comments">
                  <Input disabled />
                </Form.Item>
              </Form.Item>
            </Form>
            <div className="approveLoan__row--btns">
              <Button
                type="primary"
                onClick={() => updateProcessedChecklist()}
              >
                Update Calculation
              </Button>

              <Button
                type="primary"
                disabled={disableApprovalButton}
                onClick={() => setCalculationValid(true)} htmlType="submit"
              >
                Approve
              </Button>
            </div>
          </>
        ) : (
          <>
            <div className="approveLoan__grid--section__row">
              <Text strong>Mandatory</Text>
              <Checkbox
                onChange={onCheckAllChange}
                checked={checkAll}
                indeterminate={indeterminate}
              >
                Check all
              </Checkbox>
            </div>
            <Divider />

            <div className="approveLoan__grid--section__col">
              <CheckboxGroup
                className="approveLoan__grid--section__col"
                options={mandatoryCheckList}
                onChange={onChange}
                value={checkedList}
              />
            </div>

            <Divider />

            <Text strong> Optional</Text>
            <div className="approveLoan__grid--section__col">
              {optionalCheckList.map((option, i) => (
                <Checkbox value={option} key={`option-${i}`} onChange={(e) => handleOptionalChecked(e)}>
                  {option}
                </Checkbox>
              ))}
            </div>

            <Button
              type="primary"
              disabled={disbaleForm}
              onClick={() => processChecklist()}
            >
              Next
            </Button>
          </>
        )}


      </>
    );
  };

  const renderLoanRequestInfo = () => {
    return (
      <>
        <Form className="row-col " layout="vertical">
          <Form.Item
            className="approveLoan__grid--section__input"
            label="Amount Requested"
            name="amountRequested"
          >
            <Form.Item name="amountRequested">
              <Input disabled defaultValue={loan.principal} type="number" />
            </Form.Item>
          </Form.Item>

          <Form.Item
            className="approveLoan__grid--section__input"
            label="Request Date"
            name="requestDate"
          >
            <Form.Item name="requestDate">
              <Input
                disabled
                defaultValue={loan.timeline.submittedOnDate.reverse().join("/")}
                type="text"
              />
            </Form.Item>
          </Form.Item>

          <Form.Item
            className="approveLoan__grid--section__input"
            label="Loan Tenure (Months)"
            name="loanTenure"
          >
            <Form.Item name="loanTenure">
              <Input
                disabled
                defaultValue={loan.numberOfRepayments}
                type="text"
              />
            </Form.Item>
          </Form.Item>

          {/* <Form.Item
            className="approveLoan__grid--section__input"
            label="Total Expected Repayment"
            name="estimatedAMD"
          >
            <Form.Item>
              <Input
                disabled
                defaultValue={loan.summary.totalExpectedRepayment}
                type="number"
              />
            </Form.Item>
          </Form.Item> */}

          <Form.Item
            className="approveLoan__grid--section__input"
            label="Loan Approval Note"
            name="Loan Approval Note"
          >
            <Form.Item>
              <TextArea
                value={approvalNote}
                onChange={(e) => setApprovalNote(e.target.value)}
                type="text"
              />
            </Form.Item>
          </Form.Item>
        </Form>
      </>
    );
  };

  return (
    <>
      <div className="tabled approveLoan">
        <div className="approveLoan__row">
          <div className="approveLoan__row--btns">
            <Button onClick={() => goBack()} icon={<LeftOutlined />}>
              Back
            </Button>

            {approvingLoan ? (
              <Button
                disabled
                icon={<CheckCircleOutlined />}
                onClick={() => onSubmitBtnClicked()}
              >
                Submitting Loan...
              </Button>
            ) : (
              <Button
                // disabled={!checkAll}
                disabled={disable}
                icon={<CheckCircleOutlined />}
                onClick={() => onSubmitBtnClicked()}
              >
                Submit Loan
              </Button>
            )}
          </div>
        </div>

        <div className="approveLoan__grid">
          <div className="approveLoan__grid--section">
            <h1 className="approveLoan__grid--section__title">Checklist</h1>
            {renderLoanApprovalChecklist()}
          </div>
          <div className="approveLoan__grid--section">
            <h1 className="approveLoan__grid--section__title">
              Loan Request Information
            </h1>
            {renderLoanRequestInfo()}
          </div>
          <div className="approveLoan__grid--section">
            <h1 className="approveLoan__grid--section__title">
              Client Information
            </h1>
            {renderClientInfo()}
          </div>
        </div>
      </div>

      {errorApprovingLoan &&
        openNotifcation("error", "Error approving loan", loanApprovalErrorMsg)}
      {approvedLoan && openNotifcation("success", "Loan has been approved!")}
      {approvalChecklistError &&
        openNotifcation("error", "Calculation Error", approvalChecklistErrorMsg)}
      {/* {approvalChecklistSuccess  && openNotifcation("success", "Calculation Valid!")} */}

      {loanProductsError &&
        openNotifcation(
          "error",
          "Loan Products Error",
          `${loanProductsErrorMsg}`
        )}

    </>
  );
});

export default ApproveLoan;
