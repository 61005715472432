import payrollApi from "../api/payrollApi";
import {
  EMAIL_CHANGED,
  LOGGING_IN,
  LOGIN_ERROR,
  LOGIN_SUCCESS,
  LOG_OUT,
  PASSWORD_CHANGED,
} from "./types";

export const emailChanged = (text) => {
  return {
    type: EMAIL_CHANGED,
    payload: text,
  };
};

export const passwordChanged = (password) => {
  return {
    type: PASSWORD_CHANGED,
    payload: password,
  };
};

const loginSuccess = (dispatch, loginRes) => {
  dispatch({
    type: LOGIN_SUCCESS,
    payload: loginRes,
  });
};

const loginFail = (dispatch, error) => {
  dispatch({
    type: LOGIN_ERROR,
    payload: error,
  });
};

const handleAuthResponse = (dispatch, res) => {
  switch (res.status) {
    case 500:
      return loginFail(dispatch, res);

    default:
      localStorage.setItem("user", JSON.stringify(res));
      return loginSuccess(dispatch, res);
  }
};

export const login = (payload, { onSuccess, onError }) => {
  return (dispatch) => {
    dispatch({
      type: LOGGING_IN,
    });

    return new Promise((resolve, reject) => {
      // remove any stored expired authorization token before making new call. 
      // Added to prevent token from being added to login request after token expires
      delete payrollApi.defaults.headers.common["Authorization"]; 
      localStorage.clear();

      payrollApi
        .post("/auth", payload)
        .then((loginRes) => {
          const { data } = loginRes;
          handleAuthResponse(dispatch, data);
          resolve(data);
          onSuccess();
        })
        .catch((err) => {
          console.log("[LOGIN ERROR]:: ", err);
          delete payrollApi.defaults.headers.common["Authorization"];
          reject(err);
          loginFail(dispatch, err);
          onError();
        });
    });
  };
};

export const logOut = () => {
  delete payrollApi.defaults.headers.common["Authorization"];

  return (dispatch) => {
    localStorage.clear();
    dispatch({
      type: LOG_OUT,
    });

    delete payrollApi.defaults.headers.common["Authorization"];
  };
};
