import {
  ADDING_LOAN,
  ADDING_LOAN_ERROR,
  ADDING_LOAN_SUCCESS,
  CALCULATE_ALLOWANCE,
  CALCULATE_ALLOWANCE_ERROR,
  CALCULATE_ALLOWANCE_SUCCESS,
  GET_LOANS,
  GET_LOANS_ERROR,
  GET_LOANS_SUCCESS,
  GET_SINGLE_LOAN,
  GET_SINGLE_LOAN_ERROR,
  GET_SINGLE_LOAN_SUCCESS,
  APPROVING_LOAN,
  APPROVE_LOAN_SUCCESS,
  APPROVE_LOAN_ERROR,
  CALCULATE_LOAN_SCHEDULE,
  CALCULATE_LOAN_SCHEDULE_SUCCESS,
  CALCULATE_LOAN_SCHEDULE_ERROR,
  SEARCH_LOANS,
  SEARCH_LOANS_SUCCESS,
  SEARCH_LOANS_ERROR,
  PROCESS_APPROVAL_CHECKLIST,
  PROCESS_CHECKLIST_SUCCESS,
  PROCESS_CHECKLIST_ERROR,
  RESET_NOTIFICATION,
  GET_PAYROLL_LOANS_CUSTOM_FIELD,
  GET_PAYROLL_LOANS_CF_SUCCESS,
  GET_PAYROLL_LOANS_CF_ERROR,
  UPDATING_LOAN,
  LOAN_UPDATE_SUCCESS,
  LOAN_UPDATE_ERROR,
  CALCULATE_LOAN,
  CALCULATE_LOAN_SUCCESS,
  CALCULATE_LOAN_ERROR
} from "../actions";

const INITIAL_STATE = {
  loading: false,
  error: false,
  data: [],
  totalLoans: 0,
  adding_loan: false,
  error_adding_loan: false,
  added_loan: null,
  calculating_affordability: false,
  error_calculating_affordability: false,
  affordability: null,
  loading_loan: false,
  loan_error: false,
  loan: null,
  approvingLoan: false,
  approvedLoan: null,
  loanApprovalError: false,
  loanApprovalErrorMsg: "",
  schedule: null,
  calculatingSchedule: false,
  scheduleError: false,
  scheduleSuccess: false,
  scheduleErrorMsg: "",
  searching: false,
  search_results: [],
  search_error: false,
  approval_checklist_data: {},
  approvalChecklistError: false,
  approvalChecklistSuccess: false,
  approvalChecklistErrorMsg: "",
  disableApprovalButton: false,
  payrollLoansDetails: [],
  loanDetailsSuccess: false,
  loanDetailsFailure: false,
  updated_loan: null,
  loan_update_success: false,
  loan_update_error: false,
  calculatedLoanDetails: null,
  calculateLoanSuccess: false,
  calculateLoanError: false

};

const LoansReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case GET_LOANS:
      return {
        ...state,
        loading: true,
        error: false,
        totalLoans: 0
      };

    case GET_LOANS_SUCCESS:
      return {
        ...state,
        loading: false,
        error: false,
        data: action.payload.pageItems,
        totalLoans: action.payload.totalFilteredRecords
      };

    case GET_LOANS_ERROR:
      return {
        ...state,
        loading: false,
        error: true,
      };

    case ADDING_LOAN:
      return {
        ...state,
        adding_loan: true,
        error: false,
      };

    case ADDING_LOAN_SUCCESS:
      return {
        ...state,
        adding_loan: false,
        error: false,
        added_loan: action.payload,
      };

    case ADDING_LOAN_ERROR:
      return {
        ...state,
        adding_loan: false,
        error: true,
      };

    case CALCULATE_ALLOWANCE:
      return {
        ...state,
        calculating_affordability: true,
        error_calculating_affordability: false,
      };

    case CALCULATE_ALLOWANCE_ERROR:
      return {
        ...state,
        calculating_affordability: false,
        error_calculating_affordability: true,
      };

    case CALCULATE_ALLOWANCE_SUCCESS:
      return {
        ...state,
        calculating_affordability: false,
        error_calculating_affordability: false,
        affordability: action.payload,
      };
    case PROCESS_APPROVAL_CHECKLIST:
      return {
        ...state,
        loading: true,
        approvalChecklistError: false,
        disableApprovalButton: true,
        approvalChecklistSuccess: false

      };
    case PROCESS_CHECKLIST_SUCCESS:
      return {
        ...state,
        loading: false,
        approvalChecklistError: false,
        approval_checklist_data: action.payload,
        disableApprovalButton: false,
        approvalChecklistSuccess: true
      };

    case PROCESS_CHECKLIST_ERROR:
      return {
        ...state,
        loading: false,
        approvalChecklistError: true,
        approvalChecklistErrorMsg: action.error.response.data.message,
        disableApprovalButton: true,
        approvalChecklistSuccess: false
      };

    case GET_SINGLE_LOAN:
      return {
        ...state,
        loading_loan: true,
        loan_error: false,
      };

    case GET_SINGLE_LOAN_SUCCESS:
      return {
        ...state,
        loading_loan: false,
        loan_error: false,
        loan: action.payload,
      };

    case GET_SINGLE_LOAN_ERROR:
      return {
        ...state,
        loading_loan: false,
        loan_error: true,
      };

    case APPROVING_LOAN:
      return {
        ...state,
        approvingLoan: true,
        loanApprovalError: false,
      };

    case APPROVE_LOAN_SUCCESS:
      return {
        ...state,
        approvingLoan: false,
        loanApprovalError: false,
        approvedLoan: action.payload,
      };

    case APPROVE_LOAN_ERROR:
      return {
        ...state,
        approvingLoan: false,
        loanApprovalError: true,
        loanApprovalErrorMsg: action.payload,
      };

    case CALCULATE_LOAN_SCHEDULE:
      return {
        ...state,
        calculatingSchedule: true,
        scheduleError: false,
        scheduleSuccess: false,
      };

    case CALCULATE_LOAN_SCHEDULE_SUCCESS:
      return {
        ...state,
        calculatingSchedule: false,
        scheduleSuccess: true,
        scheduleError: false,
        schedule: action.payload,
      };

    case CALCULATE_LOAN_SCHEDULE_ERROR:
      return {
        ...state,
        calculatingSchedule: false,
        scheduleSuccess: false,
        scheduleError: true,
        scheduleErrorMsg: action.payload.error,
      };

    case SEARCH_LOANS:
      return {
        ...state,
        searching: true,
      };

    case SEARCH_LOANS_SUCCESS:
      return {
        ...state,
        searching: false,
        search_results: action.payload,
      };

    case SEARCH_LOANS_ERROR:
      return {
        ...state,
        searching: false,
        search_results: [],
      };

    case GET_PAYROLL_LOANS_CUSTOM_FIELD:
      return {
        ...state,
        // searching: true,
        loanDetailsSuccess: false,
        loanDetailsFailure: false,
        payrollLoansDetails: []
      };

    case GET_PAYROLL_LOANS_CF_SUCCESS:
      return {
        ...state,
        loanDetailsSuccess: true,
        loanDetailsFailure: false,
        payrollLoansDetails: action.payload,
      };

    case GET_PAYROLL_LOANS_CF_ERROR:
      return {
        ...state,
        loanDetailsSuccess: false,
        loanDetailsFailure: true,
        payrollLoansDetails: [],
      };

    case UPDATING_LOAN:
      return {
        ...state,

        loan_update_success: false,
        loan_update_error: false
      };

    case LOAN_UPDATE_SUCCESS:
      return {
        ...state,
        updated_loan: action.payload,
        loan_update_success: true,
        loan_update_error: false
      };

    case LOAN_UPDATE_ERROR:
      return {
        ...state,
        updated_loan: null,
        loan_update_success: false,
        loan_update_error: true
      };

    case CALCULATE_LOAN:
      return {
        ...state,
        calculateLoanError: false,
        calculateLoanSuccess: false
      };

    case CALCULATE_LOAN_SUCCESS:
      return {
        ...state,
        calculatedLoanDetails: action.payload,
        calculateLoanError: false,
        calculateLoanSuccess: true
      };

    case CALCULATE_LOAN_ERROR:
      return {
        ...state,
        calculatedLoanDetails: action.payload,
        calculateLoanError: true,
        calculateLoanSuccess: false
      };

    // reset all notification flags
    case RESET_NOTIFICATION:
      return {
        ...state,
        added_loan: null,
        addLoanError: false,
        error: false,
        error_calculating_affordability: false,
        approvalChecklistError: false,
        loanApprovalError: false,
        scheduleSuccess: false,
        scheduleError: false,
        loanProductsError: false,
        agentsError: false,
        allowancesError: false,
        affordability_error: false,
        errorApprovingLoan: false,
        approvedLoan: false,
        approvalChecklistSuccess: false,
        calculateLoanError: false,
        calculateLoanSuccess: false,
        loan_update_success: false

      };

    default:
      return state;
  }
};

export default LoansReducer;
