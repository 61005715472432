import {
  CLIENTS_ERROR,
  CLIENTS_SUCCESS,
  CLIENT_ERROR,
  CLIENT_SUCCESS,
  GET_CLIENT_CUSTOM_FIELD,
  GET_CLIENT_CUSTOM_FIELD_ERROR,
  GET_CLIENT_CUSTOM_FIELD_SUCCESS,
  IMAGE_ERROR,
  IMAGE_SUCCESS,
  LOADING_CLIENT,
  LOADING_CLIENTS,
  LOADING_IMAGE,
  SEARCH_CLIENTS,
  SEARCH_CLIENTS_ERROR,
  SEARCH_CLIENTS_SUCCESS,
  LOADING_CLIENT_IDS,
  CLIENT_ID_LOADED_SUCCESS,
  CLIENT_ID_LOADED_ERROR
} from "../actions";

const INITIAL_STATE = {
  loading_clients: false,
  error_getting_clients: false,
  loading_client: false,
  error_getting_client: false,
  clients: [],
  client: null,
  loadingImage: false,
  image: null,
  imageError: false,
  imageErroMsg: "",
  loadingClientCustomFields: false,
  errorLoadingCustomFields: false,
  customFields: [],
  searching: false,
  search_results: [],
  documents: [],
  loading_docs: false,
  error_loading_docs: false,
  pageNumber: 0,
  pageSize: 0,
  totalPages: 0,
  totalFilteredRecords: 0
};

const ClientReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case LOADING_CLIENTS:
      return {
        ...state,
        loading_clients: true,
        error_getting_clients: false,
        pageNumber: 0,
        pageSize: 0,
        totalPages: 0,
        totalFilteredRecords: 0
      };

    case CLIENTS_SUCCESS:
      return {
        ...state,
        loading_clients: false,
        error_getting_clients: false,
        clients: action.payload.pageItems,
        pageNumber: action.payload.pageNumber,
        pageSize: action.payload.pageSize,
        totalPages: action.payload.totalPages,
        totalFilteredRecords: action.payload.totalFilteredRecords
      };

    case CLIENTS_ERROR:
      return {
        ...state,
        loading_clients: false,
        error_getting_clients: true,
        pageNumber: 0,
        pageSize: 0,
        totalPages: 0,
        totalFilteredRecords: 0
      };

    case LOADING_CLIENT:
      return {
        ...state,
        loading_client: true,
        error_getting_client: false,
      };

    case CLIENT_SUCCESS:
      return {
        ...state,
        loading_client: false,
        error_getting_client: false,
        client: action.payload,
      };

    case CLIENT_ERROR:
      return {
        ...state,
        loading_client: false,
        error_getting_client: true,
      };

    case LOADING_IMAGE:
      return {
        ...state,
        image: null,
        loadingImage: true,
        imageError: false,
      };

    case IMAGE_SUCCESS:
      return {
        ...state,
        loadingImage: false,
        image: action.payload,
        imageError: false,
      };

    case IMAGE_ERROR:
      return {
        ...state,
        image: null,
        loadingImage: false,
        imageError: true,
        imageErroMsg: action.payload.error,
      };

    case GET_CLIENT_CUSTOM_FIELD:
      return {
        ...state,
        loadingClientCustomFields: true,
        errorLoadingCustomFields: false,
      };

    case GET_CLIENT_CUSTOM_FIELD_SUCCESS:
      return {
        ...state,
        loadingClientCustomFields: false,
        customFields: action.payload,
      };

    case GET_CLIENT_CUSTOM_FIELD_ERROR:
      return {
        ...state,
        loadingClientCustomFields: false,
        errorLoadingCustomFields: true,
      };

    case SEARCH_CLIENTS:
      return {
        ...state,
        searching: true,
      };

    case SEARCH_CLIENTS_SUCCESS:
      return {
        ...state,
        searching: false,
        search_results: action.payload,
      };

    case SEARCH_CLIENTS_ERROR:
      return {
        ...state,
        searching: false,
        search_results: [],
      };

    case LOADING_CLIENT_IDS:
      return {
        ...state,
        loading_docs: true,
        error_loading_docs: false,
      };

    case CLIENT_ID_LOADED_SUCCESS:
      return {
        ...state,
        loading_docs: false,
        error_loading_docs: false,
        documents: action.payload,
      };

    case CLIENT_ID_LOADED_ERROR:
      return {
        ...state,
        loading_docs: false,
        error_loading_docs: false,
      };

    default:
      return state;
  }
};

export default ClientReducer;
