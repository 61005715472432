import {
  GET_ALL_AGENTS,
  GET_ALL_AGENTS_ERROR,
  GET_ALL_AGENTS_SUCCESS,
} from ".";
import payrollApi from "../api/payrollApi";
// import payrollApi from "../api/payrollApi";
import { store } from "../store";
import {
  GET_AGENT_LOANS,
  GET_AGENT_LOANS_ERROR,
  GET_AGENT_LOANS_SUCCESS,
} from "./types";

store.subscribe(listener);

let token;
function select(state) {
  if (state.auth.user) {
    return state.auth.user.token;
  }
}

function listener() {
  token = select(store.getState());
  if (token) {
    payrollApi.defaults.headers.common["Authorization"] = `Bearer ${token}`;
  }
}

const getAllAgentsSuccess = (dispatch, agents) => {
  dispatch({ type: GET_ALL_AGENTS_SUCCESS, payload: agents });
};

const getAllAgentsError = (dispatch, error) => {
  dispatch({ type: GET_ALL_AGENTS_ERROR, payload: error });
};

export const getAllAgents = () => {
  return (dispatch) => {
    dispatch({ type: GET_ALL_AGENTS });

    return new Promise((resolve, reject) => {
      payrollApi
        .get(`/agents`)
        .then((agents_res) => {
          const { data } = agents_res;

          console.log("[AGENTS]:: ", data.pageItems);
          //TODO:: include getAllAgentsSuccess here
          getAllAgentsSuccess(dispatch, data.pageItems);
        })
        .catch((err) => {
          console.log("[AGENTS ERROR]:: ", err);

          getAllAgentsError(dispatch, err);
          //TODO:: include getAllAgentsError here

          reject(err);
        });
    });
  };
};

const handleGetAgentLoanSuccess = (dispatch, payload) => {
  dispatch({ type: GET_AGENT_LOANS_SUCCESS, payload });
};

const handleGetAgentLoanError = (dispatch, error) => {
  dispatch({ type: GET_AGENT_LOANS_ERROR, payload: error });
};

export const getAgentLoans = (id) => {
  return (dispatch) => {
    dispatch({ type: GET_AGENT_LOANS });

    payrollApi
      .get(`agents/${id}/loans`)
      .then((res) => {
        const { data } = res;
        handleGetAgentLoanSuccess(dispatch, data.pageItems);
      })
      .catch((err) => {
        handleGetAgentLoanError(dispatch, err);
      });
  };
};
