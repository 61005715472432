import {
  ADDING_USER,
  ADDING_USER_ERROR,
  ADDING_USER_SUCCESS,
  GET_USERS,
  GET_USERS_ERROR,
  GET_USERS_SUCCESS
} from "../actions";

const INITIAL_STATE = {
  loading: false,
  error: false,
  data: [],
  adding_user: false,
  error_adding_user: false,
  added_user: null,
  calculating_affordability: false,
  error_calculating_affordability: false,
  affordability: null,
};

const UsersReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case GET_USERS:
      return {
        ...state,
        loading: true,
        error: false,
      };

    case GET_USERS_SUCCESS:
      return {
        ...state,
        loading: false,
        error: false,
        data: action.payload,
      };

    case GET_USERS_ERROR:
      return {
        ...state,
        loading: false,
        error: true,
      };

    case ADDING_USER:
      return {
        ...state,
        adding_user: true,
        error: false,
      };

    case ADDING_USER_SUCCESS:
      return {
        ...state,
        adding_user: false,
        error: false,
        added_user: action.payload,
      };

    case ADDING_USER_ERROR:
      return {
        ...state,
        adding_user: false,
        error: true,
      };

    default:
      return state;
  }
};

export default UsersReducer;
