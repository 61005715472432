import { combineReducers } from "redux";
import AgentsReducer from "./AgentsReducer";
import AllowanceReducer from "./AllowanceReducer";
import AuthReducer from "./AuthReducer";
import ClientReducer from "./ClientsReducer";
import CommissionsReducer from "./CommissionsReducer";
import LoanProductsReducer from "./LoanProductsReducer";
import LoansReducer from "./LoanReducer";
import RepaymentReducer from "./RepaymentsReducer";
import RolesReducer from "./RoleReducer";
import UsersReducer from "./UserReducer";

export default combineReducers({
  auth: AuthReducer,
  clients: ClientReducer,
  agents: AgentsReducer,
  loanProducts: LoanProductsReducer,
  allowances: AllowanceReducer,
  loans: LoansReducer,
  repayments: RepaymentReducer,
  users: UsersReducer,
  roles: RolesReducer,
  commissions: CommissionsReducer,
});
