import React, { useState, useEffect } from "react";
import { withRouter } from "react-router-dom";
import {
  Row,
  Col,
  Card,
  Progress,
  Button,
  Typography,
  Form,
  Input,
  Select,
  notification,
  Tooltip,
  Skeleton,
  Avatar,
  Descriptions
} from "antd";
import "./EditLoan.scss";
import {
  PlusOutlined,
  CalculatorOutlined,
  CloseCircleOutlined,
  DeleteOutlined,
  UserOutlined
} from "@ant-design/icons";

import { useDispatch, useSelector } from "react-redux";
import {
  calculateAllowance,
  getAllowances,
} from "../actions";

const { Title } = Typography;

const EditLoan = withRouter(({ history }) => {
  const [title, setTitle] = useState("Affordability Calculator");
  const [fields, setFields] = useState([]);
  const [affordabilityForm] = Form.useForm();

  const allowances = useSelector(({ allowances }) => allowances.allowances);
  const loadingAllowances = useSelector(({ allowances }) => allowances.loading);
  const allowancesError = useSelector(({ allowances }) => allowances.error);

  const calculating = useSelector(
    ({ loans }) => loans.calculating_affordability
  );
  const affordability_error = useSelector(
    ({ loans }) => loans.error_calculating_affordability
  );
  const affordability = useSelector(({ loans }) => loans.affordability);

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getAllowances());
  }, []);

  const openNotifcation = (type, message, description) => {
    notification[type]({
      message,
      description,
    });
  };

  const [rerender, setRerender] = useState(false);

  //** Change Allowance */
  const changeAllowance = (value, i) => {

    const allowance = filterAllowance(value);
    fields[i]["name"] = allowance.name;
    fields[i]["permanent"] = allowance.permanent;
    console.log("[_ADDED FIELD]:: ", fields);

    setRerender(!rerender);
  };

  const changeAllowanceAmount = (value, i) => {

    fields[i]["amount"] = value;

    setRerender(!rerender);
  };

  useEffect(() => {

  }, [rerender])

  const filterAllowance = (name) => {
    return allowances.find((allowance) => allowance.name === name);
  };

  //** Add allowance field  */
  const addAllowance = () => {
    // set first added allowance to default value (first on list)
    const field = {
      name: allowances[0].name,
      permanent: allowances[0].permanent,
      amount: "",
    };

    setFields([...fields, field]);
  };

  const deleteField = (value) => {
    setFields(fields.filter((s) => s !== value));
  };

  //** Calculate Affordability */
  const onCalculateClicked = () => {
    const affordabilityPayload = {
      basicSalary: affordabilityForm.getFieldValue("basicSalary"),
      tax: affordabilityForm.getFieldValue("tax"),
      ssf: affordabilityForm.getFieldValue("socialSecurity"),
      thirdParty: affordabilityForm.getFieldValue("thirdPartyCont"),
      reservedAfford: affordabilityForm.getFieldValue("reservedAffordability"),
      allowances: [...fields],
    };

    console.log("[AFFORDABILITY PAYLOAD]:: ", affordabilityPayload);

    dispatch(calculateAllowance(affordabilityPayload)).then((afford) => {
      affordabilityForm.setFieldsValue({ grossSalary: afford.grossSalary });
      affordabilityForm.setFieldsValue({ netSalary: afford.netSalary });
      affordabilityForm.setFieldsValue({ allowableMonthDeduction: afford.amd });
    });

  };

  const renderLoanEditables = () => {
    return (
      <Col >
        <Form
          onFinish={onCalculateClicked}
          className="row-col"
          layout="vertical"
          form={affordabilityForm}
        >
          <Row>

            <Form.Item
              label="Basic Salary"
              name="basicSalary"
              className="addLoan__card--form__input"
              rules={[
                {
                  required: true,
                  message: "Please provide the basic salary amount",
                },
              ]}
            >
              <Form.Item name="basicSalary">
                <Input type="number" />
              </Form.Item>
            </Form.Item>

            <Form.Item
              label="Social Security"
              name="socialSecurity"
              className="addLoan__card--form__input"
              rules={[
                {
                  required: true,
                  message: "Please provide the social security number",
                },
              ]}
            >
              <Form.Item name="socialSecurity">
                <Input type="number" />
              </Form.Item>
            </Form.Item>

            <Form.Item
              label="Tax"
              name="tax"
              className="addLoan__card--form__input"
              rules={[
                {
                  required: true,
                  message: "Please provide the taxable amount",
                },
              ]}
            >
              <Form.Item name="tax">
                <Input type="number" />
              </Form.Item>
            </Form.Item>
          </Row>

          <Row>

            <Form.Item
              label="Third Party Contribution"
              name="thirdPartyCont"
              className="addLoan__card--form__input"
              rules={[
                {
                  required: true,
                  message:
                    "Please provide the third party contribution amount",
                },
              ]}
            >
              <Form.Item name="thirdPartyCont">
                <Input type="number" />
              </Form.Item>
            </Form.Item>

            <Form.Item
              label="Reserved Afford. "
              name="reservedAffordability"
              className="addLoan__card--form__input"
              rules={[
                {
                  required: true,
                  message:
                    "Please provide the reserved affordability amount",
                },
              ]}
            >
              <Form.Item name="reservedAffordability">
                <Input type="number" />
              </Form.Item>
            </Form.Item>
          </Row>

          <Row>
            <Form.Item
              label="Gross Salary"
              name="grossSalary"
              className="addLoan__card--form__input"

            >
              <Form.Item name="grossSalary">
                <Input disabled type="number" />
              </Form.Item>
            </Form.Item>

            <Form.Item
              label="Net Salary"
              name="netSalary"
              className="addLoan__card--form__input"

            >
              <Form.Item name="netSalary">
                <Input disabled type="number" />
              </Form.Item>
            </Form.Item>

            <Form.Item
              label="Allowable Monthly Deduction"
              name="allowableMonthDeduction"
              className="addLoan__card--form__input"

            >
              <Form.Item name="allowableMonthDeduction">
                <Input disabled type="number" />
              </Form.Item>
            </Form.Item>
          </Row>

          <div className="addLoan__card--form__allowanceRow">
            <h3 className="addLoan__card--form__allowanceRow--title">
              Allowances
            </h3>

            <div className="addLoan__card--form__allowanceRow--btn">
              <Button
                onClick={() => addAllowance()}
                icon={<PlusOutlined />}
              >
                Add Allowance
              </Button>

            </div>
          </div>

          <div className="addLoan__card--form__line"></div>

          <Row>
            {fields.map((field, i) => (
              <Col
                className="addLoan__card--form__allowances"
                key={`allowances-${i}`}
              >
                {loadingAllowances ? (
                  <Skeleton paragraph={false} rows={1} />
                ) : (
                  <select
                    onChange={(e) => changeAllowance(e.target.value, i)}
                    className="addLoan__card--form__custom_select"
                  >
                    {allowances.map((allowance, j) => (
                      <option key={j} value={allowance.name}>
                        {allowance.name}
                      </option>
                    ))}
                  </select>
                )}

                <Input
                  onChange={(evt) => changeAllowanceAmount(evt.target.value, i)}
                  type="number"
                />

                <Tooltip title="Delete allowance field">
                  <Button
                    icon={<DeleteOutlined />}
                    size="middle"
                    onClick={() => deleteField(field)}
                    // type="danger"
                    danger
                    shape="round"
                    className="addLoan__card--form__allowances--del"
                  ></Button>
                </Tooltip>
              </Col>
            ))}
          </Row>

          <div className="addLoan__card--form__line"></div>

          <div className="addLoan__card--form__btnRow">
            <Button>

            </Button>

            <Button htmlType="submit" type="primary" icon={<CalculatorOutlined />}>
              Calculate
            </Button>
          </div>
        </Form>
      </Col>
    );
  };

  return (

    <div className="tabled addLoan">
      <Row gutter={[24, 0]}>


        <Col xs="24" xl={24}>
          <Card
            bordered={false}
            className="circlebox tablespace mb-24 addLoan__card"
            title={title}
          >
            {renderLoanEditables()}
          </Card>
        </Col>

        {affordability_error &&
          openNotifcation(
            "error",
            "Error Calculating Affordability"
          )}

        {allowancesError &&
          openNotifcation(
            "error",
            "Error Fetching Allowances"
          )}
      </Row>
    </div>

  );
});

export default EditLoan;
