import React, { useState, useEffect } from "react";
import { withRouter } from "react-router-dom";
import {
  Row,
  Col,
  Card,
  Progress,
  Button,
  Typography,
  Form,
  Input,
  Select,
  notification,
  Tooltip,
  Skeleton,
  Avatar,
  Descriptions
} from "antd";
import "./EditLoan.scss";
import {
  PlusOutlined,
  CalculatorOutlined,
  CloseCircleOutlined,
  DeleteOutlined,
  UserOutlined
} from "@ant-design/icons";

import { useDispatch, useSelector } from "react-redux";
import {
  calculateAllowance,
  getAllowances,
  getLoanCustomFieldByName,
  updateLoan
} from "../actions";

const { Title } = Typography;

const EditLoan = withRouter(({ history }) => {
  const [salaryInfo, setSalaryInfo] = useState(null);
  const [title, setTitle] = useState("Edit Loan Request Information");
  const [fields, setFields] = useState([]);
  const [salaryForm] = Form.useForm();
  const [basicForm] = Form.useForm();

  const [loanToEdit, setLoanToEdit] = useState(history.location.state.loanToEdit);

  const allowances = useSelector(({ allowances }) => allowances.allowances);
  const loadingAllowances = useSelector(({ allowances }) => allowances.loading);
  const allowancesError = useSelector(({ allowances }) => allowances.error);

  const calculating = useSelector(
    ({ loans }) => loans.calculating_affordability
  );
  const affordability_error = useSelector(
    ({ loans }) => loans.error_calculating_affordability
  );
  const affordability = useSelector(({ loans }) => loans.affordability);

  const payrollLoansDetails = useSelector(({ loans }) => loans.payrollLoansDetails);
  const updateLoanSuccess = useSelector(({ loans }) => loans.loan_update_success);
  const updateLoanError = useSelector(({ loans }) => loans.loan_update_error);

  const dispatch = useDispatch();

  const fetchAllDropDowns = () => {
    dispatch(getAllowances());
    // dispatch(getAllAgents());
    // dispatch(getAllLoanProducts());
  };

  useEffect(() => {
    fetchAllDropDowns();
    console.log("---[LOAN TO EDITED::", loanToEdit);
  }, []);

  const openNotifcation = (type, message, description) => {
    notification[type]({
      message,
      description,
    });
  };

  useEffect(() => {
    console.log("---FETCHING PAYROLL LOAN DETAILS CUSTOM FIELD::");
    dispatch(getLoanCustomFieldByName(loanToEdit.id, "Payroll Loan Details"));
  }, []);

  useEffect(() => {
    loadBasicFormValues();

    //load allowances for select options
    loanAllowanceFields(payrollLoansDetails.allowances || [])
  }, [payrollLoansDetails])

  const loanAllowanceFields = (loanAllowances) => {
    console.log("--LOAN_ALLOWANCES::", loanAllowances);

    let localAllowances = [];
    loanAllowances.map((loanAl) => {
      const _field = {
        name: loanAl.name,
        amount: loanAl.amount,
        permanent: loanAl.permanent,
      };

      localAllowances.push(_field);
    });

    setFields(localAllowances);
  }


  const onSalaryInfoFinish = (values) => {
    const form = {
      ...values,
      ...salaryInfo
    };

    console.log("[ADD LOAN VALUES]:: ", form);

    let allowancesName = [];

    fields.map((field) => {
      allowancesName.push(field.name);
    });

    // Loan term frequency = Loan product tenure
    // Number of repayments = Loan product tenure
    // Interest Rate = Loan Product interest rate

    // Loan Deductions not on payslip = Reserved Affordability
    // affordability = amd
    // total deducations =
    // authorityNoteNumber =
    // Total Deductions = allowableMoth * loan product tenure

    const loanInfo = {
      principal: form.amountrequested,
      customFieldUpdate:
      {
        basicSalary: form.basicSalary,
        grossSalary: form.grossSalary,
        netSalary: form.netSalary,
        thirdPartyDeductions: form.thirdPartyCont,
        socialSecuritySSF: form.socialSecurity,
        tax: form.tax,
        allowances: allowancesName.join(),
        loanDeductionsNotOnPayslip: form.reservedAffordability,
        affordability: form.allowableMonthDeduction,
        allowanceDetails: [...fields],
        totalDeductions:
          form.allowableMonthDeduction * loanToEdit.numberOfRepayments,
      }


    };

    console.log("[COMPLETE PAYLOAD]:: ", loanInfo);

    dispatch(updateLoan(loanToEdit.id, loanInfo, routeToLoans));

  };

  const routeToLoan = () => {
    history.push(`/loan/${loanToEdit.id}`);
  };

  const routeToLoans = () => {
    history.push("/loans");
  };

  const [rerender, setRerender] = useState(false);

  const viewLoanDetails = (id) => {
    history.push(`/loan/${id}`, { from: 'createLoan' });
  };

  //** Change Allowance */
  const changeAllowance = (value, i) => {

    console.log("***** VALUE::", value);

    if (value !== "-1") {
      const allowance = filterAllowance(value);
      fields[i]["name"] = allowance.name;
      fields[i]["permanent"] = allowance.permanent;
      console.log("[_ADDED FIELD]:: ", fields);
    } else {
      // reset selected option
      fields[i]["name"] = "";
      fields[i]["permanent"] = "";
      fields[i]["amount"] = "";
    }

    setRerender(!rerender);
  };

  const changeAllowanceAmount = (value, i) => {

    fields[i]["amount"] = value;

    setRerender(!rerender);
  };

  useEffect(() => {

  }, [rerender])

  const filterAllowance = (name) => {
    return allowances.find((allowance) => allowance.name === name);
  };

  //** Add allowance field  */
  const addAllowance = () => {
    const field = {
      name: "",
      permanent: "",
      amount: "",
    };

    setFields([...fields, field]);
    console.log("[UPDATED FIELDS]:: ", fields);
  };

  const deleteField = (value) => {
    setFields(fields.filter((s) => s !== value));
  };

  //** Calculate Affordability */
  const onCalculateClicked = () => {
    // const values = salaryForm.getFieldsValue(true);
    const affordabilityPayload = {
      loanId: loanToEdit.id,
      basicSalary: salaryForm.getFieldValue("basicSalary"),
      tax: salaryForm.getFieldValue("tax"),
      ssf: salaryForm.getFieldValue("socialSecurity"),
      thirdParty: salaryForm.getFieldValue("thirdPartyCont"),
      reservedAfford: salaryForm.getFieldValue("reservedAffordability"),
      allowances: [...fields],
    };

    dispatch(calculateAllowance(affordabilityPayload)).then((afford) => {
      salaryForm.setFieldsValue({ grossSalary: afford.grossSalary });
      salaryForm.setFieldsValue({ netSalary: afford.netSalary });
      salaryForm.setFieldsValue({ allowableMonthDeduction: afford.amd });
    });
    console.log("[SALARY FORM PAYLOAD]:: ", affordabilityPayload);
  };

  const loadBasicFormValues = () => {

    salaryForm.setFieldsValue({ amountrequested: loanToEdit.principal });
    salaryForm.setFieldsValue({ basicSalary: payrollLoansDetails.basicSalary });
    salaryForm.setFieldsValue({ socialSecurity: payrollLoansDetails.socialSecuritySsf });

    salaryForm.setFieldsValue({ tax: payrollLoansDetails.tax });
    salaryForm.setFieldsValue({ thirdPartyCont: payrollLoansDetails.thirdPartyDeductions });
    salaryForm.setFieldsValue({ reservedAffordability: payrollLoansDetails.loanDeductionsNotOnPayslip });

    salaryForm.setFieldsValue({ grossSalary: payrollLoansDetails.grossSalary });
    salaryForm.setFieldsValue({ netSalary: payrollLoansDetails.netSalary });
    salaryForm.setFieldsValue({ allowableMonthDeduction: payrollLoansDetails.affordability });

  }


  const renderBasicLoanInfo = () => {
    return (
      <>
        <Card
          bordered={false}
          className="card-project"
        >
          <div className="col-info mb-24">
            <Title level={5}>Basic Data </Title>

            <Descriptions>
              <Descriptions.Item label="Client" span={3}>
                {loanToEdit.clientName} - ({loanToEdit.clientId})
              </Descriptions.Item>
              <Descriptions.Item label="Loan ID" span={3}>
                {loanToEdit.id}
              </Descriptions.Item>
              <Descriptions.Item label="Amount Requested" span={3}>
                {loanToEdit.currency.code} {loanToEdit.principal}
              </Descriptions.Item>
              <Descriptions.Item label="Submited On" span={3}>
                {loanToEdit.timeline.submittedOnDate.reverse().join("-")}
              </Descriptions.Item>
              <Descriptions.Item label="Loan Tenure" span={3}>
                {loanToEdit.numberOfRepayments} {loanToEdit.repaymentFrequencyType.value}
              </Descriptions.Item>
              <Descriptions.Item label="Interest Rate" span={3}>
                {loanToEdit.interestRatePerPeriod}
              </Descriptions.Item>
            </Descriptions>

          </div>
        </Card>
      </>
    );
  };

  const renderLoanEditables = () => {
    return (
      <Col >
        <Form
          onFinish={onSalaryInfoFinish}
          className="row-col"
          layout="vertical"
          form={salaryForm}
        >
          <Row>
            <Form.Item
              className="addLoan__card--form__input"
              label="Amount requested (PF inclusive)"
              name="amountrequested"
              rules={[
                { required: true, message: "Please provide loan amount!" },
              ]}
            >
              <Form.Item name="amountrequested">
                <Input defaultValue={loanToEdit.principal} />
              </Form.Item>
            </Form.Item>

            <Form.Item
              label="Basic Salary"
              name="basicSalary"
              className="addLoan__card--form__input"
              rules={[
                {
                  required: true,
                  message: "Please provide the basic salary amount",
                },
              ]}
            >
              <Form.Item name="basicSalary">
                <Input type="number" />
              </Form.Item>
            </Form.Item>

            <Form.Item
              label="Social Security"
              name="socialSecurity"
              className="addLoan__card--form__input"
              rules={[
                {
                  required: true,
                  message: "Please provide the social security number",
                },
              ]}
            >
              <Form.Item name="socialSecurity">
                <Input type="number" />
              </Form.Item>
            </Form.Item>
          </Row>

          <Row>
            <Form.Item
              label="Tax"
              name="tax"
              className="addLoan__card--form__input"
              rules={[
                {
                  required: true,
                  message: "Please provide the taxable amount",
                },
              ]}
            >
              <Form.Item name="tax">
                <Input type="number" />
              </Form.Item>
            </Form.Item>

            <Form.Item
              label="Third Party Contribution"
              name="thirdPartyCont"
              className="addLoan__card--form__input"
              rules={[
                {
                  required: true,
                  message:
                    "Please provide the third party contribution amount",
                },
              ]}
            >
              <Form.Item name="thirdPartyCont">
                <Input type="number" />
              </Form.Item>
            </Form.Item>

            <Form.Item
              label="Reserved Afford. "
              name="reservedAffordability"
              className="addLoan__card--form__input"
              rules={[
                {
                  required: true,
                  message:
                    "Please provide the reserved affordability amount",
                },
              ]}
            >
              <Form.Item name="reservedAffordability">
                <Input type="number" />
              </Form.Item>
            </Form.Item>
          </Row>

          <Row>
            <Form.Item
              label="Gross Salary"
              name="grossSalary"
              className="addLoan__card--form__input"
              rules={[
                {
                  required: true,
                  message: "Please provide the basic salary amount",
                },
              ]}
            >
              <Form.Item name="grossSalary">
                <Input disabled type="number" />
              </Form.Item>
            </Form.Item>

            <Form.Item
              label="Net Salary"
              name="netSalary"
              className="addLoan__card--form__input"
              rules={[
                {
                  required: true,
                  message: "Please provide the basic salary amount",
                },
              ]}
            >
              <Form.Item name="netSalary">
                <Input disabled type="number" />
              </Form.Item>
            </Form.Item>

            <Form.Item
              label="Allowable Monthly Deduction"
              name="allowableMonthDeduction"
              className="addLoan__card--form__input"
              rules={[
                {
                  required: true,
                  message: "Please provide the basic salary amount",
                },
              ]}
            >
              <Form.Item name="allowableMonthDeduction">
                <Input disabled type="number" />
              </Form.Item>
            </Form.Item>
          </Row>

          {/* TODO:: MIGHT NEED TO ADD THE ALLOWANCE INFORMATION HERE  */}
          {/* Horizontal LINE */}
          <div className="addLoan__card--form__allowanceRow">
            <h3 className="addLoan__card--form__allowanceRow--title">
              Allowances
            </h3>

            <div className="addLoan__card--form__allowanceRow--btn">
              <Button
                onClick={() => addAllowance()}
                icon={<PlusOutlined />}
              >
                Add Allowance
              </Button>
              {calculating ? (
                <Button
                  disabled
                  onClick={() => onCalculateClicked()}
                  icon={<CalculatorOutlined />}
                >
                  Calculating...
                </Button>
              ) : (
                <Button
                  onClick={() => onCalculateClicked()}
                  icon={<CalculatorOutlined />}
                >
                  Calculate
                </Button>
              )}
            </div>
          </div>
          <div className="addLoan__card--form__line"></div>

          <Row>
            {fields.map((field, i) => (
              <Col
                className="addLoan__card--form__allowances"
                key={`allowances-${i}`}
              >
                {loadingAllowances ? (
                  <Skeleton paragraph={false} rows={1} />
                ) : (
                  <select
                    onChange={(e) => changeAllowance(e.target.value, i)}
                    className="addLoan__card--form__custom_select"
                    // defaultValue={field.name} 
                    value={field.name}
                  >
                    <option selected value="-1">Select Allowance</option>
                    {allowances.map((allowance, j) => (
                      <option key={j} value={allowance.name}>
                        {allowance.name}
                      </option>
                    ))}
                  </select>
                )}

                <Input
                  // defaultValue={field.amount}
                  value={field.amount}
                  // onChange={(evt) => (field.amount = evt.target.value)}changeAllowanceAmount
                  onChange={(evt) => changeAllowanceAmount(evt.target.value, i)}
                  type="number"
                />

                <Tooltip title="Delete allowance field">
                  <Button
                    icon={<DeleteOutlined />}
                    size="middle"
                    onClick={() => deleteField(field)}
                    // type="danger"
                    danger
                    shape="round"
                    className="addLoan__card--form__allowances--del"
                  ></Button>
                </Tooltip>
              </Col>
            ))}
          </Row>

          <div className="addLoan__card--form__btnRow">
            <Button
              onClick={() => {
                routeToLoan();
              }}
              type="cancel"
            >
              Cancel
            </Button>

            <Button htmlType="submit" type="primary">
              Update Loan
            </Button>
          </div>
        </Form>
      </Col>
    );
  };

  return (

    <div className="tabled addLoan">
      <Row gutter={[24, 0]}>
        <div className="approveLoan__grid--section">
          {/* <h1 className="approveLoan__grid--section__title">
            Client Information
          </h1> */}
          {/* {renderBasicLoanInfo()} */}
        </div>

        <Col xs="24" xl={24}>
          <Card
            bordered={false}
            className="circlebox tablespace mb-24 addLoan__card"
            title={title}
          >
            {renderLoanEditables()}
          </Card>
        </Col>

        {updateLoanSuccess &&
          openNotifcation(
            "success",
            `Loan-${loanToEdit.id} has been successfully updated.`
          )}

        {updateLoanError &&
          openNotifcation(
            "error",
            `Error Updating loan ${loanToEdit.id}`
          )}
      </Row>
    </div>

  );
});

export default EditLoan;
