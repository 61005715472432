import React, { useEffect, useState } from "react";
import { Card, Tabs, Button, Col, Typography, Descriptions, Table } from "antd";
import {
  LeftOutlined,
  CheckCircleOutlined,
  CloseCircleOutlined, EditOutlined
} from "@ant-design/icons";
import { withRouter, useLocation } from "react-router-dom";
import "./Loan.scss";
import { useDispatch, useSelector } from "react-redux";
import { getLoan, calculateLoanSchedule, rejectLoan } from "../actions";
import moment from "moment";
import { openNotifcation } from "../components/Notification";
import { DATE_FORMAT, DATE_ONLY_FORMAT } from "../constants";

const { TabPane } = Tabs;
const { Title } = Typography;

const Loan = withRouter(({ history, match }) => {
  const location = useLocation();
  const loading = useSelector(({ loans }) => loans.loading_loan);
  const error = useSelector(({ loans }) => loans.loan_error);
  const loan = useSelector(({ loans }) => loans.loan);
  const loanSchedule = useSelector(({ loans }) => loans.schedule);
  const loadingSchedule = useSelector(({ loans }) => loans.calculatingSchedule);
  const scheduleError = useSelector(({ loans }) => loans.scheduleError);
  const scheduleErrorMsg = useSelector(({ loans }) => loans.scheduleErrorMsg);
  const [isPendingApproval, setIsPendingApproval] = useState(false);
  const dispatch = useDispatch();

  const user = useSelector(({ auth }) => auth.user);
  const { userRole } = user;

  useEffect(() => {
    // const { match } = props;
    console.log(match);
    dispatch(getLoan(match.params.loanId));
  }, [location]);

  useEffect(() => {
    // if (loan !== null) {
    //   const schedulePayload = {
    //     clientId: loan.clientId,
    //     productId: loan.loanProductId,
    //     principal: loan.principal,
    //     loanTermFrequency: loan.termFrequency,
    //     numberOfRepayments: loan.numberOfRepayments,
    //     repaymentEvery: loan.repaymentEvery,
    //     interestRatePerPeriod: loan.interestRatePerPeriod,
    //     amortizationType: loan.amortizationType.id,
    //     interestType: loan.interestType.id,
    //     expectedDisbursementDate: moment(
    //       loan.timeline.expectedDisbursementDate.reverse().join("-")
    //     ).format(DATE_FORMAT),
    //     submittedOnDate: moment(
    //       loan.timeline.submittedOnDate.reverse().join("-")
    //     ).format(DATE_FORMAT),
    //   };

    //   // dispatch(calculateLoanSchedule(schedulePayload));
    // }

    // console.log("----LOAN::", loan);
    // setIsPendingApproval(loan.status.pendingApproval);
  }, [loan]);

  const goBack = () => {
    // create loan page also navigates here after loan creation to show loan details 
    // and provide chance for approval
    // check's here to ensure user doesn't return to create loan
    // if (history.location.state.from === undefined || history.location.state.from == 'createLoan' ) {
    history.push("/loans");
    // } else {
    //   history.goBack();
    // }
  };

  const routeToLoanApproval = (id) => {
    history.push({
      pathname: `/approve-loan/${id}`,
      state: { loan: loan },
    });
  };

  const onRejectLoanSuccess = (id) => {
    history.push("/loans");
    openNotifcation(
      "success", `Loan ${id} has been rejected`
    )
  };

  const onRejectLoanFail = () => {
    history.push("/loans");
    openNotifcation("error", "Loan rejection failed!")
  };

  const routeToLoans = () => {
    history.push("/loans");
  }

  const setPendingApproval = () => {
    setIsPendingApproval(loan.status.pendingApproval);
  }

  const onRejectLoanClicked = (id) => {
    const payload = {
      rejectedOnDate: moment(new Date()).format(DATE_ONLY_FORMAT),
      action: "reject",
      note: "Loan Rejected"
    };

    console.log("[REJECT LOAN PAYLOAD]:: ", payload);

    dispatch(rejectLoan(loan.id, payload, onRejectLoanSuccess, onRejectLoanFail));
  };

  // const routeToEditLoan = (id) => {
  //   history.push({
  //     pathname: `/edit-loan/${id}`,
  //     state: { loan: loan },
  //   });
  // };

  const routeToEditLoan = (id) => {
    // history.push("/edit-loan", { clientID: loan.clientId });
    history.push(`/edit-loan/${id}`, { loanToEdit: loan });
  };

  const renderOverview = () => {
    return (
      <>
        <Col xs={24} md={24}>
          <Card className="card-billing-info" bordered="false">
            <div className="col-info mb-24">
              <Title level={5}>Frontend Data </Title>
              {loan && (
                <Descriptions>
                  <Descriptions.Item label="Client" span={3}>
                    {loan.clientName} - ({loan.clientId})
                  </Descriptions.Item>
                  <Descriptions.Item label="Loan ID" span={3}>
                    {loan.id}
                  </Descriptions.Item>
                  <Descriptions.Item label="Amount Requested" span={3}>
                    {loan.currency.code} {loan.principal}
                  </Descriptions.Item>
                  <Descriptions.Item label="Date" span={3}>
                    {loan.timeline.submittedOnDate.reverse().join("-")}
                  </Descriptions.Item>
                  <Descriptions.Item label="Loan Product Name" span={3}>
                    {loan.loanProductName}
                  </Descriptions.Item>
                  <Descriptions.Item label="Annual Interest Rate" span={3}>
                    {loan.annualInterestRate}
                  </Descriptions.Item>
                  {/* <Descriptions.Item label="Interest Waived" span={3}>
                    {loan.summary.interestWaived && loan.summary.interestWaived}
                  </Descriptions.Item>
                  <Descriptions.Item label="Outstanding Interest" span={3}>
                    {loan.summary.interestOutstanding &&
                      loan.summary.interestOutstanding}
                  </Descriptions.Item> */}
                  <Descriptions.Item label="Proposed Principal" span={3}>
                    {loan.currency.code} {loan.proposedPrincipal}
                  </Descriptions.Item>
                  <Descriptions.Item label="Status" span={3}>
                    {loan.status.value}
                  </Descriptions.Item>
                  <Descriptions.Item label="Amount Approved" span={3}>
                    {loan.currency.code} {loan.approvedPrincipal}
                  </Descriptions.Item>
                  <Descriptions.Item label="Processing Fee" span={3}>
                    {loan.currency.code} {loan.feeChargesAtDisbursementCharged}
                  </Descriptions.Item>
                  <Descriptions.Item label="Date Approved" span={3}>
                    {loan.timeline.approvedOnDate &&
                      loan.timeline.approvedOnDate.reverse().join("-")}
                  </Descriptions.Item>
                  {/* <Descriptions.Item label="Total Overdue" span={3}>
                    {loan.currency.code} {loan.summary.totalOverdue}
                  </Descriptions.Item>
                  <Descriptions.Item label="Total Cost of Loan" span={3}>
                    {loan.currency.code} {loan.summary.totalCostOfLoan}
                  </Descriptions.Item> */}
                  {/* <Descriptions.Item label="Expected Total Interest" span={3}>
                    Babs A - (00010753)
                  </Descriptions.Item>
                  <Descriptions.Item label="Processing Fee" span={3}>
                    Babs A - (00010753)
                  </Descriptions.Item>
                  <Descriptions.Item label="Authority Note No." span={3}>
                    Babs A - (00010753)
                  </Descriptions.Item>
                  <Descriptions.Item label="Loan Advance No." span={3}>
                    Babs A - (00010753)
                  </Descriptions.Item>
                  <Descriptions.Item label="Loan Purpose" span={3}>
                    Babs A - (00010753)
                  </Descriptions.Item>
                  <Descriptions.Item label="Loan Detail Purpose" span={3}>
                    Babs A - (00010753)
                  </Descriptions.Item>
                  <Descriptions.Item label="Guarantor" span={3}>
                    Babs A - (00010753)
                  </Descriptions.Item>
                  <Descriptions.Item label="Credit Officer Notes" span={3}>
                    Babs A - (00010753)
                  </Descriptions.Item> */}
                </Descriptions>
              )}
            </div>

            {loan && (
              <div className="col-info">
                <Title level={5}>Mifos Data </Title>
                <Descriptions>
                  <Descriptions.Item label="Status" span={3}>
                    {loan.status.value}
                  </Descriptions.Item>
                  <Descriptions.Item label="Loan Amount" span={3}>
                    {loan.currency.code} {loan.principal}
                  </Descriptions.Item>
                  {/* <Descriptions.Item label="Amount Disbursed" span={3}>
                    {loan.currency.code} {loan.summary.principalDisbursed}
                  </Descriptions.Item> */}
                  {/* <Descriptions.Item label="Disbursement Date" span={3}>
                    {loan.timeline.actualDisbursementDate.reverse().join("-")}
                  </Descriptions.Item> */}
                  <Descriptions.Item label="Principal Overdue" span={3}>
                    {loan.currency.code} {loan.principalOverdue}
                  </Descriptions.Item>
                  {/* <Descriptions.Item label="Interest Paid" span={3}>
                    {loan.currency.code} {loan.summary.interestPaid}
                  </Descriptions.Item>
                  <Descriptions.Item label="Penalties Charged" span={3}>
                    {loan.currency.code} {loan.summary.penaltyChargesCharged}
                  </Descriptions.Item> */}
                </Descriptions>
              </div>
            )}
          </Card>
        </Col>
      </>
    );
  };

  const renderSchedule = () => {
    const scheduleColumns = [
      {
        title: "Due Date",
        dataIndex: "dueDate",
        key: "dueDate",
      },
      {
        title: "Principal Due",
        dataIndex: "principalDue",
        key: "principleDue",
      },
      {
        title: "Principal Paid",
        dataIndex: "principalPaid",
        key: "principalPaid",
      },
      {
        title: "Interest Due",
        dataIndex: "interestDue",
        key: "interestDue",
      },
      {
        title: "Interest Paid",
        dataIndex: "interestPaid",
        key: "interestPaid",
      },
      {
        title: "Total Due",
        dataIndex: "totalDue",
        key: "totalDue",
      },
      {
        title: "Total Paid",
        dataIndex: "totalPaid",
        key: "totalPaid",
      },
      {
        title: "State",
        dataIndex: "state",
        key: "state",
      },
    ];

    const scheduleData = [];

    return (
      <>
        <div className="table-responsive">
          <Table
            className="ant-border-space"
            columns={scheduleColumns}
            dataSource={scheduleData}
            pagination={true}
            size={10}
            loading={loadingSchedule}
          />
        </div>
      </>
    );
  };

  const renderTransactions = () => {
    const transactionColumns = [
      { title: "Entry Date", dataIndex: "entryDate", key: "entryDate" },
      { title: "ID", dataIndex: "transactionID", key: "transactionID" },
      { title: "Type", dataIndex: "transactionType", key: "transactionType" },
      { title: "Amount", dataIndex: "amount", key: "amount" },
      {
        title: "Principal Balance",
        dataIndex: "principalBalance",
        key: "principalBalance",
      },
      {
        title: "Arrears Position",
        dataIndex: "arrearsPosition",
        key: "arrearsPosition",
      },
      { title: "Total Paid", dataIndex: "totalPaid", key: "totalPaid" },
      { title: "Balance", dataIndex: "balance", key: "balance" },
    ];

    const transactionsData = [];

    return (
      <>
        <div className="table-responsive">
          <Table
            className="ant-border-space"
            columns={transactionColumns}
            dataSource={transactionsData}
            pagination={true}
            size={10}
          />
        </div>
      </>
    );
  };

  return (
    <div className="tabled loan">
      <div className="loan__row">
        <div className="loan__row--btns">
          <Button onClick={() => goBack()} icon={<LeftOutlined />}>
            Back
          </Button>

          {loan && loan.status.pendingApproval && (
            <>
              <Button
                onClick={() => routeToEditLoan(match.params.loanId)}
                icon={<EditOutlined />}
              >
                Edit Loan
              </Button>

              <Button
                hidden={userRole === "Admin Officer"}
                onClick={() => routeToLoanApproval(match.params.loanId)}
                icon={<CheckCircleOutlined />}
              >
                Approve Loan
              </Button>

              <Button type="danger" icon={<CloseCircleOutlined />}
                hidden={userRole === "Admin Officer"}
                onClick={() => onRejectLoanClicked(match.params.loanId)}>
                Reject Loan
              </Button>
            </>
          )}
        </div>
      </div>
      <Col xs="24" xl={24}>
        <Card loading={loading} className="mb-24">
          <Tabs defaultActiveKey="1">
            <TabPane key="1" tab="Overview">
              {renderOverview()}
            </TabPane>
            {/* <TabPane key="2" tab="Schedule">
              {renderSchedule()}
            </TabPane>
            <TabPane key="4" tab="Transactions">
              {renderTransactions()}
            </TabPane> */}
          </Tabs>
        </Card>
      </Col>
      {scheduleError &&
        openNotifcation(
          "error",
          "Error Calculating Loan Schedule",
          scheduleErrorMsg
        )}
      \
    </div>
  );
});

export default Loan;
