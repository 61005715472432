import {
  GET_ALL_ALLOWANCES,
  GET_ALL_ALLOWANCES_ERROR,
  GET_ALL_ALLOWANCES_SUCCESS,
} from "../actions";

const INITIAL_STATE = {
  loading: false,
  error: false,
  allowances: [],
};

const AllowanceReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case GET_ALL_ALLOWANCES:
      return {
        ...state,
        loading: true,
        error: false,
      };

    case GET_ALL_ALLOWANCES_SUCCESS:
      return {
        ...state,
        loading: false,
        error: false,
        allowances: action.payload,
      };

    case GET_ALL_ALLOWANCES_ERROR:
      return {
        ...state,
        loading: false,
        error: true,
      };

    default:
      return state;
  }
};

export default AllowanceReducer;
