export const DATE_FORMAT = "YYYY-MM-DD HH:mm:ss";
export const BANKS = [
  "Bank",
  "Fidelity SME Bank",
  "Prudential Payroll Bank",
  "Fidelity Payroll Bank",
  "GT Bank (Operating Account)",
  "Zenith Bank",
  "Mobile Money Account - Fidelity Bank",
  "Ghana Commercial Bank",
  "Prudential SME Bank",
  "Bank Transaction Account - Payroll Loans",
  "Bank Transaction Account - SME Loans",
  "Stanbic Bank",
];
export const DATE_ONLY_FORMAT = "YYYY-MM-DD";
// export const BASE_URL = "http://localhost:8080/payroll/api/v1";
export const BASE_URL = "https://payrolltest.linkexchange.com.gh/payroll/api/v1"; // staging/test environment
//export const BASE_URL = "https://payroll.peswa.finance/payroll/api/v1";
