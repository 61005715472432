import { Result } from "antd";
import React from "react";
import "./404.scss";

const NotFoundComponent = () => {
  return (
    <Result
      status="404"
      title="Oops"
      subtitle="Sorry, you do not have access to this component"
    />
  );
};

export default NotFoundComponent;
