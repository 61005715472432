import React, { useEffect, useState } from "react";
import { Card, Button, Input, Typography, Descriptions, Select } from "antd";

import { withRouter } from "react-router-dom";
import "./PayCommission.scss";
import { useDispatch, useSelector } from "react-redux";
import { getPaymentTypes, payCommission } from "../actions";
import { nanoid } from "nanoid";
import { openNotifcation } from "../components/Notification";

const { Title } = Typography;

const PayCommission = withRouter(({ history }) => {
  const [comm, setCommission] = useState(history.location.state.commission);
  const [paid, setPaid] = useState(history.location.state.commission.paid);
  const [paymentTypeId, setPaymentTypeId] = useState();
  const [paidOn, setPaidOn] = useState();
  const paymentTypes = useSelector(
    ({ commissions }) => commissions.paymentTypes
  );
  const loadingPaymentTypes = useSelector(
    ({ commissions }) => commissions.loadingPaymentTypes
  );

  const dispatch = useDispatch();

  const onSubmitPayment = () => {
    
    const payload = {
      id:comm.id,
      paidOn:paidOn,
      paymentTypeId:paymentTypeId
    };

    console.log("[PAY COMMISSION PAYLOAD]:: ", payload);

    dispatch(payCommission(payload, { onSuccess: onPaymentSuccess, onError: onError }));
  };

  const onPaymentSuccess = () => {
    history.replace("/commissions");
    openNotifcation("success", "Commission Paid");
    console.log("[COMMISSION PAYMENT SUCCESSFUL!!!]");
  };

  const onError = () => {
    openNotifcation("error", "Commission Payment Failed");
  };

  useEffect(() => {
    dispatch(getPaymentTypes());
  }, []);

  const renderCommissionDetails = () => {
    switch (paid) {
      case false:
        return (
          <React.Fragment>
            <Descriptions.Item label="Total Commission">
              {comm.totalCommission}
            </Descriptions.Item>
            <Descriptions.Item label="Agent Commission Due">
              {comm.agentCommissionAmt}
            </Descriptions.Item>
            <Descriptions.Item label="Approved">
              {JSON.stringify(comm.approved)}
            </Descriptions.Item>
          </React.Fragment>
        );

      default:
        return (
          <React.Fragment>
            <Descriptions.Item label="Loan Value">
              {comm.disbursedLoanAmt}
            </Descriptions.Item>

            <Descriptions.Item label="Total Commission Amount">
              {comm.totalCommission}
            </Descriptions.Item>

            <Descriptions.Item label="Agent Commission Amount">
              {comm.agentCommissionAmt}
            </Descriptions.Item>

            <Descriptions.Item label="Withholding Tax Amount">
              {comm.withholdingTaxAmt}
            </Descriptions.Item>

            <Descriptions.Item label="Approved On">
              {comm.approvedOn}
            </Descriptions.Item>

            <Descriptions.Item label="Approved By">
            {comm.approvedBy}
            </Descriptions.Item>

            <Descriptions.Item label="Paid On">{comm.paidOn}</Descriptions.Item>

            <Descriptions.Item label="Paid By">{comm.paidBy}</Descriptions.Item>

            <Descriptions.Item label="Channel">{comm.paymentChannel}</Descriptions.Item>
          </React.Fragment>
        );
    }
  };

  return (
    <React.Fragment>
      <div className="tabled pay-com">
        <Card
          bordered={false}
          className="circlebox tablesplace mb-24"
          title="Commission Information"
        >
          <Descriptions>
          <Descriptions.Item label="Agent Name">
              {comm.agentName}
            </Descriptions.Item>
            <Descriptions.Item label="Client Name">
              {comm.clientName}
            </Descriptions.Item>
            <Descriptions.Item label="Loan #">{comm.loanId}</Descriptions.Item>
            {renderCommissionDetails()}
          </Descriptions>

          {!paid && (
            <div className="pay-comm__col">
              <div className="pay-com__row">
                <div className="pay-com__select">
                  <Title level={5}>Pay from (Bank/Transaction Source)</Title>
                  <Select
                    onChange={(evt) => setPaymentTypeId(evt)}
                    loading={loadingPaymentTypes}
                  >
                    {paymentTypes.map((type, i) => (
                      <Select.Option
                        key={`bank-option-${nanoid()}`}
                        value={type.id}
                      >
                        {type.name}
                      </Select.Option>
                    ))}
                  </Select>
                </div>

                <div className="pay-com__select">
                  <Title level={5}>Paid On</Title>
                  <Input
                    onChange={(evt) => setPaidOn(evt.target.value)}
                    type="date"
                  />
                </div>
              </div>

              <Button onClick={()=>onSubmitPayment()}>Pay</Button>
            </div>
          )}
        </Card>
      </div>
    </React.Fragment>
  );
});

export default PayCommission;
