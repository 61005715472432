import { Row, Col, Card, Table, Button, Typography, Input } from "antd";

import React, { useEffect, useState } from "react";

import "./Loans.scss";
import { withRouter } from "react-router-dom";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { getLoans } from "../actions";
import { openNotifcation } from "../components/Notification";
import { SearchOutlined } from "@ant-design/icons";
import { useLocation } from "react-router-dom";
import { nanoid } from "nanoid";

const { Title } = Typography;
const { Search, Group } = Input;

const columns = [
  {
    title: "Client",
    dataIndex: "clientName",
    key: "clientName",
  },
  {
    title: "Loan ID",
    key: "id",
    dataIndex: "id",
  },
  {
    title: "Amount Requested",
    key: "proposedPrincipal",
    dataIndex: "proposedPrincipal",
  },
  {
    title: "Amount Approved",
    key: "approvedPrincipal",
    dataIndex: "approvedPrincipal",
  },
  {
    title: "Date",
    key: "submittedOnDate",
    dataIndex: "submittedOnDate",
  },
  {
    title: "Interest Rate",
    key: "interestRate",
    dataIndex: "interestRate",
  },
  {
    title: "Interest Charged",
    key: "interestCharged",
    dataIndex: "interestCharged",
  },
  {
    title: "Interest Paid",
    key: "interestPaid",
    dataIndex: "interestPaid",
  },
  {
    title: "Expected Repayment",
    key: "totalExpectedRepayment",
    dataIndex: "totalExpectedRepayment",
  },
  {
    title: "Status",
    key: "status",
    dataIndex: "status",
  },
  {
    title: "",
    dataIndex: "view",
    key: "view",
  },
];

const Loans = withRouter(({ history }) => {
  const loans = useSelector(({ loans }) => loans.data);
  const loading = useSelector(({ loans }) => loans.loading);
  const error = useSelector(({ loans }) => loans.error);
  const searchResults = useSelector(({ loans }) => loans.search_results);
  const location = useLocation();
  const dispatch = useDispatch();
  const [results, setResults] = useState([]);
  const [showResults, toggleResults] = useState(false);
  const totalLoans = useSelector(({ loans }) => loans.totalLoans);

  useEffect(() => {
    if (searchResults && searchResults.pageItems) {
      let _searchResults = [];

      searchResults.pageItems.map((loan) => {
        loan.submittedOnDate = loan.timeline.submittedOnDate
          .reverse()
          .join("/");
        loan.interestRate = loan.interestRatePerPeriod;

        const _loan = {
          key: `loan-${nanoid()}`,

          clientName: (
            <>
              <div className="avatar-info">
                <Title level={5}>{loan.clientName}</Title>
              </div>
            </>
          ),

          id: (
            <>
              <div className="author-info">
                <Title level={5}>{loan.id}</Title>
              </div>
            </>
          ),
          proposedPrincipal: (
            <>
              <div className="author-info">
                <Title level={5}>{loan.proposedPrincipal}</Title>
              </div>
            </>
          ),
          approvedPrincipal: (
            <>
              <div className="author-info">
                <Title level={5}>{loan.approvedPrincipal}</Title>
              </div>
            </>
          ),
          submittedOnDate: (
            <>
              <div className="author-info">
                <Title level={5}>{loan.submittedOnDate}</Title>
              </div>
            </>
          ),
          interestRate: (
            <>
              <div className="author-info">
                <Title level={5}>{loan.interestRate}</Title>
              </div>
            </>
          ),
          interestCharged: (
            <>
              <div className="author-info">
                <Title level={5}>{loan.interestCharged}</Title>
              </div>
            </>
          ),
          interestPaid: (
            <>
              <div className="author-info">
                <Title level={5}>{loan.interestPaid}</Title>
              </div>
            </>
          ),
          totalExpectedRepayment: (
            <>
              <div className="author-info">
                <Title level={5}>{loan.totalExpectedRepayment}</Title>
              </div>
            </>
          ),
          status: (
            <>
              <div className="author-info">
                <p className={renderStatusColor(loan.status)} level={5}>
                  {loan.status.value}
                </p>
              </div>
            </>
          ),
          view: (
            <>
              <Button
                onClick={() => routeToLoan(loan.id)}
                type="primary"
                className="tag-primary"
              >
                View
              </Button>
            </>
          ),
        };

        //** UPDATE SEARCH RESULTS ARRAY */
        _searchResults.push(_loan);
      });

      setResults(_searchResults);
      toggleResults(true);
    }
  }, [searchResults]);

  const fetchLoans = (params) => {
    dispatch(getLoans(params));
  };

  useEffect(() => {
    fetchLoans({ size: 200, page: 0 });

    console.log("[LOCATION LOANS]:: ", location);
  }, []);

  const routeToLoan = (id) => {
    history.push(`/loan/${id}`, {from: location.pathname});
  };
  //** STATUS COLORS */
  const renderStatusColor = (status) => {
    switch (status.value) {
      case "Arrears":
        return "arrears";

      case "Approved":
        return "approved";

      case "Closed (obligations met)":
        return "closed";

      case "Active":
        return "_active";

      default:
        return "pending";
    }
  };

  //** MOCK DATA */
  const _loans = [];

  loans.map((loan, i) => {
    loan.submittedOnDate = loan.timeline.submittedOnDate.reverse().join("/");
    loan.interestRate = loan.interestRatePerPeriod;

    const _loan = {
      key: `loan-${i}`,

      clientName: (
        <>
          <div className="avatar-info">
            <Title level={5}>{loan.clientName}</Title>
          </div>
        </>
      ),

      id: (
        <>
          <div className="author-info">
            <Title level={5}>{loan.id}</Title>
          </div>
        </>
      ),
      proposedPrincipal: (
        <>
          <div className="author-info">
            <Title level={5}>{loan.proposedPrincipal}</Title>
          </div>
        </>
      ),
      approvedPrincipal: (
        <>
          <div className="author-info">
            <Title level={5}>{loan.approvedPrincipal}</Title>
          </div>
        </>
      ),
      submittedOnDate: (
        <>
          <div className="author-info">
            <Title level={5}>{loan.submittedOnDate}</Title>
          </div>
        </>
      ),
      interestRate: (
        <>
          <div className="author-info">
            <Title level={5}>{loan.interestRate}</Title>
          </div>
        </>
      ),
      interestCharged: (
        <>
          <div className="author-info">
            <Title level={5}>{loan.interestCharged}</Title>
          </div>
        </>
      ),
      interestPaid: (
        <>
          <div className="author-info">
            <Title level={5}>{loan.interestPaid}</Title>
          </div>
        </>
      ),
      totalExpectedRepayment: (
        <>
          <div className="author-info">
            <Title level={5}>{loan.totalExpectedRepayment}</Title>
          </div>
        </>
      ),
      status: (
        <>
          <div className="author-info">
            <p className={renderStatusColor(loan.status)} level={5}>
              {loan.status.value}
            </p>
          </div>
        </>
      ),
      view: (
        <>
          <Button
            onClick={() => routeToLoan(loan.id)}
            type="primary"
            className="tag-primary"
          >
            View
          </Button>
        </>
      ),
    };

    _loans.push(_loan);
  });

  return (
    <>
      <div className="tabled">
        <Row gutter={[24, 0]}>
          <Col xs="24" xl={24}>
            <Card
              bordered={false}
              className="criclebox tablespace mb-24"
              title="Loans"
              extra={
                <>
                  {showResults && (
                    <Button onClick={() => toggleResults(false)}>
                      Clear Search Results
                    </Button>
                  )}
                </>
              }
            >
              <div className="table-responsive">
                <Table
                  loading={loading}
                  columns={columns}
                  dataSource={showResults ? results : _loans}
                  pagination={{ total: totalLoans }}
                />
              </div>
            </Card>
          </Col>
        </Row>
        {error &&
          openNotifcation("error", "Loans Error", "Oops, error getting loans")}
      </div>
    </>
  );
});

export default Loans;
