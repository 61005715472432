/*!
  =========================================================
  * Muse Ant Design Dashboard - v1.0.0
  =========================================================
  * Product Page: https://www.creative-tim.com/product/muse-ant-design-dashboard
  * Copyright 2021 Creative Tim (https://www.creative-tim.com)
  * Licensed under MIT (https://github.com/creativetimofficial/muse-ant-design-dashboard/blob/main/LICENSE.md)
  * Coded by Creative Tim
  =========================================================
  * The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// import { useState } from "react";
import { Menu, Button } from "antd";
import { NavLink, useLocation } from "react-router-dom";
import logo from "../../assets/images/logo.png";
import { useSelector } from "react-redux";

function Sidenav({ color }) {
  const { pathname } = useLocation();
  const page = pathname.replace("/", "");

  const user = useSelector(({ auth }) => auth.user);

  const dashboard = [
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      xmlns="http://www.w3.org/2000/svg"
      key={0}
    >
      <path
        d="M3 4C3 3.44772 3.44772 3 4 3H16C16.5523 3 17 3.44772 17 4V6C17 6.55228 16.5523 7 16 7H4C3.44772 7 3 6.55228 3 6V4Z"
        fill={color}
      ></path>
      <path
        d="M3 10C3 9.44771 3.44772 9 4 9H10C10.5523 9 11 9.44771 11 10V16C11 16.5523 10.5523 17 10 17H4C3.44772 17 3 16.5523 3 16V10Z"
        fill={color}
      ></path>
      <path
        d="M14 9C13.4477 9 13 9.44771 13 10V16C13 16.5523 13.4477 17 14 17H16C16.5523 17 17 16.5523 17 16V10C17 9.44771 16.5523 9 16 9H14Z"
        fill={color}
      ></path>
    </svg>,
  ];

  const tables = [
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      key={0}
    >
      <path
        d="M9 2C8.44772 2 8 2.44772 8 3C8 3.55228 8.44772 4 9 4H11C11.5523 4 12 3.55228 12 3C12 2.44772 11.5523 2 11 2H9Z"
        fill={color}
      ></path>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M4 5C4 3.89543 4.89543 3 6 3C6 4.65685 7.34315 6 9 6H11C12.6569 6 14 4.65685 14 3C15.1046 3 16 3.89543 16 5V16C16 17.1046 15.1046 18 14 18H6C4.89543 18 4 17.1046 4 16V5ZM7 9C6.44772 9 6 9.44772 6 10C6 10.5523 6.44772 11 7 11H7.01C7.56228 11 8.01 10.5523 8.01 10C8.01 9.44772 7.56228 9 7.01 9H7ZM10 9C9.44772 9 9 9.44772 9 10C9 10.5523 9.44772 11 10 11H13C13.5523 11 14 10.5523 14 10C14 9.44772 13.5523 9 13 9H10ZM7 13C6.44772 13 6 13.4477 6 14C6 14.5523 6.44772 15 7 15H7.01C7.56228 15 8.01 14.5523 8.01 14C8.01 13.4477 7.56228 13 7.01 13H7ZM10 13C9.44772 13 9 13.4477 9 14C9 14.5523 9.44772 15 10 15H13C13.5523 15 14 14.5523 14 14C14 13.4477 13.5523 13 13 13H10Z"
        fill={color}
      ></path>
    </svg>,
  ];

  const billing = [
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      key={0}
    >
      <path
        d="M4 4C2.89543 4 2 4.89543 2 6V7H18V6C18 4.89543 17.1046 4 16 4H4Z"
        fill={color}
      ></path>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M18 9H2V14C2 15.1046 2.89543 16 4 16H16C17.1046 16 18 15.1046 18 14V9ZM4 13C4 12.4477 4.44772 12 5 12H6C6.55228 12 7 12.4477 7 13C7 13.5523 6.55228 14 6 14H5C4.44772 14 4 13.5523 4 13ZM9 12C8.44772 12 8 12.4477 8 13C8 13.5523 8.44772 14 9 14H10C10.5523 14 11 13.5523 11 13C11 12.4477 10.5523 12 10 12H9Z"
        fill={color}
      ></path>
    </svg>,
  ];

  const rtl = [
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      key={0}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M3 6C3 4.34315 4.34315 3 6 3H16C16.3788 3 16.725 3.214 16.8944 3.55279C17.0638 3.89157 17.0273 4.29698 16.8 4.6L14.25 8L16.8 11.4C17.0273 11.703 17.0638 12.1084 16.8944 12.4472C16.725 12.786 16.3788 13 16 13H6C5.44772 13 5 13.4477 5 14V17C5 17.5523 4.55228 18 4 18C3.44772 18 3 17.5523 3 17V6Z"
        fill={color}
      ></path>
    </svg>,
  ];

  const profile = [
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      key={0}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M18 10C18 14.4183 14.4183 18 10 18C5.58172 18 2 14.4183 2 10C2 5.58172 5.58172 2 10 2C14.4183 2 18 5.58172 18 10ZM12 7C12 8.10457 11.1046 9 10 9C8.89543 9 8 8.10457 8 7C8 5.89543 8.89543 5 10 5C11.1046 5 12 5.89543 12 7ZM9.99993 11C7.98239 11 6.24394 12.195 5.45374 13.9157C6.55403 15.192 8.18265 16 9.99998 16C11.8173 16 13.4459 15.1921 14.5462 13.9158C13.756 12.195 12.0175 11 9.99993 11Z"
        fill={color}
      ></path>
    </svg>,
  ];

  const users = [
    <svg
      width="22"
      height="22"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      key={0}
    >
      <path
        d="M9 6C9 7.65685 7.65685 9 6 9C4.34315 9 3 7.65685 3 6C3 4.34315 4.34315 3 6 3C7.65685 3 9 4.34315 9 6Z"
        fill="#fff"
      ></path>
      <path
        d="M17 6C17 7.65685 15.6569 9 14 9C12.3431 9 11 7.65685 11 6C11 4.34315 12.3431 3 14 3C15.6569 3 17 4.34315 17 6Z"
        fill="#fff"
      ></path>
      <path
        d="M12.9291 17C12.9758 16.6734 13 16.3395 13 16C13 14.3648 12.4393 12.8606 11.4998 11.6691C12.2352 11.2435 13.0892 11 14 11C16.7614 11 19 13.2386 19 16V17H12.9291Z"
        fill="#fff"
      ></path>
      <path
        d="M6 11C8.76142 11 11 13.2386 11 16V17H1V16C1 13.2386 3.23858 11 6 11Z"
        fill="#fff"
      ></path>
    </svg>,
  ];

  const signin = [
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      key={0}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M6 2C5.44772 2 5 2.44772 5 3V4H4C2.89543 4 2 4.89543 2 6V16C2 17.1046 2.89543 18 4 18H16C17.1046 18 18 17.1046 18 16V6C18 4.89543 17.1046 4 16 4H15V3C15 2.44772 14.5523 2 14 2C13.4477 2 13 2.44772 13 3V4H7V3C7 2.44772 6.55228 2 6 2ZM6 7C5.44772 7 5 7.44772 5 8C5 8.55228 5.44772 9 6 9H14C14.5523 9 15 8.55228 15 8C15 7.44772 14.5523 7 14 7H6Z"
        fill={color}
      ></path>
    </svg>,
  ];

  const clients = [
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="14"
      height="14"
      viewBox="0 0 14 14"
      key={0}
    >
      <path
        d="M0,2A2,2,0,0,1,2,0H8a2,2,0,0,1,2,2V8a2,2,0,0,1-2,2H2A2,2,0,0,1,0,8Z"
        transform="translate(4 4)"
        fill={color}
      />
      <path
        d="M2,0A2,2,0,0,0,0,2V8a2,2,0,0,0,2,2V4A2,2,0,0,1,4,2h6A2,2,0,0,0,8,0Z"
        fill={color}
      />
    </svg>,
  ];

  const dollar = [
    <svg
      width="22"
      height="22"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      key={0}
    >
      <path
        d="M8.43338 7.41784C8.58818 7.31464 8.77939 7.2224 9 7.15101L9.00001 8.84899C8.77939 8.7776 8.58818 8.68536 8.43338 8.58216C8.06927 8.33942 8 8.1139 8 8C8 7.8861 8.06927 7.66058 8.43338 7.41784Z"
        fill="#fff"
      ></path>
      <path
        d="M11 12.849L11 11.151C11.2206 11.2224 11.4118 11.3146 11.5666 11.4178C11.9308 11.6606 12 11.8861 12 12C12 12.1139 11.9308 12.3394 11.5666 12.5822C11.4118 12.6854 11.2206 12.7776 11 12.849Z"
        fill="#fff"
      ></path>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M10 18C14.4183 18 18 14.4183 18 10C18 5.58172 14.4183 2 10 2C5.58172 2 2 5.58172 2 10C2 14.4183 5.58172 18 10 18ZM11 5C11 4.44772 10.5523 4 10 4C9.44772 4 9 4.44772 9 5V5.09199C8.3784 5.20873 7.80348 5.43407 7.32398 5.75374C6.6023 6.23485 6 7.00933 6 8C6 8.99067 6.6023 9.76515 7.32398 10.2463C7.80348 10.5659 8.37841 10.7913 9.00001 10.908L9.00002 12.8492C8.60902 12.7223 8.31917 12.5319 8.15667 12.3446C7.79471 11.9275 7.16313 11.8827 6.74599 12.2447C6.32885 12.6067 6.28411 13.2382 6.64607 13.6554C7.20855 14.3036 8.05956 14.7308 9 14.9076L9 15C8.99999 15.5523 9.44769 16 9.99998 16C10.5523 16 11 15.5523 11 15L11 14.908C11.6216 14.7913 12.1965 14.5659 12.676 14.2463C13.3977 13.7651 14 12.9907 14 12C14 11.0093 13.3977 10.2348 12.676 9.75373C12.1965 9.43407 11.6216 9.20873 11 9.09199L11 7.15075C11.391 7.27771 11.6808 7.4681 11.8434 7.65538C12.2053 8.07252 12.8369 8.11726 13.254 7.7553C13.6712 7.39335 13.7159 6.76176 13.354 6.34462C12.7915 5.69637 11.9405 5.26915 11 5.09236V5Z"
        fill="#fff"
      ></path>
    </svg>,
  ];

  const menuItems = [
    // {
    //   page: "dashboard",
    //   label: "Dashboard",
    //   icon: dashboard,
    // },
    {
      page: "clients",
      label: "Clients",
      icon: clients,
    },
    {
      page: "loans",
      label: "Loans",
      icon: billing,
    },
    {
      page: "commissions",
      label: "Commissions",
      icon: dollar,
    },
    {
      page: "repayments",
      label: "Repayments",
      icon: tables,
    },
    {
      page: "loan-calculator",
      label: "Loan Calculator",
      icon: rtl,
    },
    {
      page: "affordability-calculator",
      label: "Affordability Calculator",
      icon: billing,
    },
    {
      page: "users",
      label: "Users & Roles",
      icon: users,
    },

    // {
    //   page: "tables",
    //   label: "Tables",
    //   icon: tables,
    // },
    // {
    //   page: "billing",
    //   label: "Billing",
    //   icon: billing,
    // },
    // {
    //   page: "profile",
    //   label: "Profile",
    //   icon: profile,
    // },
  ];

  const adminOfficerMenuItems = [

    {
      page: "clients",
      label: "Clients",
      icon: clients,
    },
    {
      page: "loans",
      label: "Loans",
      icon: billing,
    },
    {
      page: "commissions",
      label: "Commissions",
      icon: dollar,
    },
    // {
    //   page: "repayments",
    //   label: "Repayments",
    //   icon: tables,
    // },
    {
      page: "reports",
      label: "Reports",
      icon: billing,
    },
    {
      page: "loan-calculator",
      label: "Loan Calculator",
      icon: rtl,
    },
    {
      page: "affordability-calculator",
      label: "Affordability Calculator",
      icon: billing,
    },
    // {
    //   page: "users",
    //   label: "Users & Roles",
    //   icon: users,
    // }
  ];

  const adminManagerMenuItems = [

    {
      page: "clients",
      label: "Clients",
      icon: clients,
    },
    {
      page: "loans",
      label: "Loans",
      icon: billing,
    },
    {
      page: "commissions",
      label: "Commissions",
      icon: dollar,
    },
    {
      page: "repayments",
      label: "Repayments",
      icon: tables,
    },
    {
      page: "loan-calculator",
      label: "Loan Calculator",
      icon: rtl,
    },
    {
      page: "affordability-calculator",
      label: "Affordability Calculator",
      icon: billing,
    },
    // {
    //   page: "users",
    //   label: "Users & Roles",
    //   icon: users,
    // },

  ];

  const loanManagerMenuItems = [
    {
      page: "clients",
      label: "Clients",
      icon: clients,
    },
    {
      page: "loans",
      label: "Loans",
      icon: billing,
    },
    {
      page: "loan-calculator",
      label: "Loan Calculator",
      icon: rtl,
    },
    {
      page: "affordability-calculator",
      label: "Affordability Calculator",
      icon: billing,
    }

  ];

  const defaultMenuItems = [
    {
      page: "loans",
      label: "Loans",
      icon: billing,
    },
    {
      page: "loan-calculator",
      label: "Loan Calculator",
      icon: rtl,
    },
    {
      page: "affordability-calculator",
      label: "Affordability Calculator",
      icon: billing,
    }

  ];

  const renderMenu = () => {

    if (user) {
      const { userRole } = user;

      switch (userRole) {
        case "Admin":
          return (
            <Menu theme="light" mode="inline">
              {/* Loop over menuItems to create side nav */}
              {menuItems.map((menuItem, i) => (
                <Menu.Item key={`menu-${i}`}>
                  <NavLink to={`/${menuItem.page}`}>
                    <span
                      style={{
                        background: page === menuItem.page ? color : "",
                      }}
                      className="icon"
                    >
                      {menuItem.icon}
                    </span>
                    <span className="label">{menuItem.label}</span>
                  </NavLink>
                </Menu.Item>
              ))}
            </Menu>
          );

        case "Admin Officer":
          return (
            <Menu theme="light" mode="inline">
              {/* Loop over menuItems to create side nav */}
              {adminOfficerMenuItems.map((menuItem, i) => (
                <Menu.Item key={`menu-${i}`}>
                  <NavLink to={`/${menuItem.page}`}>
                    <span
                      style={{
                        background: page === menuItem.page ? color : "",
                      }}
                      className="icon"
                    >
                      {menuItem.icon}
                    </span>
                    <span className="label">{menuItem.label}</span>
                  </NavLink>
                </Menu.Item>
              ))}
            </Menu>
          );


        case "Admin Manager":
          return (
            <Menu theme="light" mode="inline">
              {/* Loop over menuItems to create side nav */}
              {adminManagerMenuItems.map((menuItem, i) => (
                <Menu.Item key={`menu-${i}`}>
                  <NavLink to={`/${menuItem.page}`}>
                    <span
                      style={{
                        background: page === menuItem.page ? color : "",
                      }}
                      className="icon"
                    >
                      {menuItem.icon}
                    </span>
                    <span className="label">{menuItem.label}</span>
                  </NavLink>
                </Menu.Item>
              ))}
            </Menu>
          );

          case "Loan Manager":
          return (
            <Menu theme="light" mode="inline">
              {/* Loop over menuItems to create side nav */}
              {loanManagerMenuItems.map((menuItem, i) => (
                <Menu.Item key={`menu-${i}`}>
                  <NavLink to={`/${menuItem.page}`}>
                    <span
                      style={{
                        background: page === menuItem.page ? color : "",
                      }}
                      className="icon"
                    >
                      {menuItem.icon}
                    </span>
                    <span className="label">{menuItem.label}</span>
                  </NavLink>
                </Menu.Item>
              ))}
            </Menu>
          );

        default:
          return (
            <Menu theme="light" mode="inline">
              {/* Loop over menuItems to create side nav */}
              {defaultMenuItems.map((menuItem, i) => (
                <Menu.Item key={`menu-${i}`}>
                  <NavLink to={`/${menuItem.page}`}>
                    <span
                      style={{
                        background: page === menuItem.page ? color : "",
                      }}
                      className="icon"
                    >
                      {menuItem.icon}
                    </span>
                    <span className="label">{menuItem.label}</span>
                  </NavLink>
                </Menu.Item>
              ))}
            </Menu>
          );
      }
    }

  }

  return (
    <>
      <div className="brand">
        <img src={logo} alt="" />
        <span>Link Exchange Payroll</span>
      </div>
      <hr />

      {renderMenu()}

      {/* <Menu theme="light" mode="inline"> */}
        {/* Loop over menuItems to create side nav */}
        {/* {menuItems.map((menuItem, i) => (
          <Menu.Item key={`menu-${i}`}>
            <NavLink to={`/${menuItem.page}`}>
              <span
                style={{
                  background: page === menuItem.page ? color : "",
                }}
                className="icon"
              >
                {menuItem.icon}
              </span>
              <span className="label">{menuItem.label}</span>
            </NavLink>
          </Menu.Item>
        ))} */}

        {/* <Menu.Item key="1">
          <NavLink to="/dashboard">
            <span
              className="icon"
              style={{
                background: page === "dashboard" ? color : "",
              }}
            >
              {dashboard}
            </span>
            <span className="label">Dashboard</span>
          </NavLink>
        </Menu.Item>
        <Menu.Item key="2">
          <NavLink to="/tables">
            <span
              className="icon"
              style={{
                background: page === "tables" ? color : "",
              }}
            >
              {tables}
            </span>
            <span className="label">Tables</span>
          </NavLink>
        </Menu.Item>
        <Menu.Item key="3">
          <NavLink to="/billing">
            <span
              className="icon"
              style={{
                background: page === "billing" ? color : "",
              }}
            >
              {billing}
            </span>
            <span className="label">Billing</span>
          </NavLink>
        </Menu.Item>
        <Menu.Item key="4">
          <NavLink to="/rtl">
            <span
              className="icon"
              style={{
                background: page === "rtl" ? color : "",
              }}
            >
              {rtl}
            </span>
            <span className="label">RTL</span>
          </NavLink>
        </Menu.Item>
        <Menu.Item className="menu-item-header" key="5">
          Account Pages
        </Menu.Item>
        <Menu.Item key="6">
          <NavLink to="/profile">
            <span
              className="icon"
              style={{
                background: page === "profile" ? color : "",
              }}
            >
              {profile}
            </span>
            <span className="label">Profile</span>
          </NavLink>
        </Menu.Item> */}
        {/* <Menu.Item key="7">
          <NavLink to="/sign-in">
            <span className="icon">{signin}</span>
            <span className="label">Sign In</span>
          </NavLink>
        </Menu.Item>
        <Menu.Item key="8">
          <NavLink to="/sign-up">
            <span className="icon">{signup}</span>
            <span className="label">Sign Up</span>
          </NavLink>
        </Menu.Item> */}
      {/* </Menu> */}
      {/* <div className="aside-footer">
        <div
          className="footer-box"
          style={{
            background: color,
          }}
        >
          <span className="icon" style={{ color }}>
            {dashboard}
          </span>
          <h6>Need Help?</h6>
          <p>Please check our docs</p>
          <Button type="primary" className="ant-btn-sm ant-btn-block">
            DOCUMENTATION
          </Button>
        </div>
      </div> */}
    </>
  );
}

export default Sidenav;
