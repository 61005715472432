import React, { useState, useEffect } from "react";
import { withRouter } from "react-router-dom";
import {
  Row,
  Col,
  Card,
  Progress,
  Button,
  Typography,
  Form,
  Input,
  Select,
  notification,
  Tooltip,
  Skeleton,
  Avatar,
  Descriptions
} from "antd";
import "./EditLoan.scss";
import {
  PlusOutlined,
  CalculatorOutlined,
  CloseCircleOutlined,
  DeleteOutlined,
  UserOutlined
} from "@ant-design/icons";


import { useDispatch, useSelector } from "react-redux";
import {
  getAllLoanProducts,
  processLoanEnquiry
} from "../actions";

const { Title } = Typography;

const LoanCalculator = withRouter(({ history }) => {

  const [title, setTitle] = useState("Loan Calculator");
  const calculatedLoanDetails = useSelector(({ loans }) => loans.calculatedLoanDetails);
  const calculateLoanSuccess = useSelector(({ loans }) => loans.calculateLoanSuccess);
  const calculateLoanError = useSelector(({ loans }) => loans.calculateLoanError);

  const loanProducts = useSelector(
    ({ loanProducts }) => loanProducts.loanProducts
  );
  const loadingLoanProducts = useSelector(
    ({ loanProducts }) => loanProducts.loading
  );
  const loanProductsError = useSelector(
    ({ loanProducts }) => loanProducts.error
  );

  const dispatch = useDispatch();
  const [calculatorForm] = Form.useForm();

  const openNotifcation = (type, message, description) => {
    notification[type]({
      message,
      description,
    });
  };

  useEffect(() => {
    dispatch(getAllLoanProducts());
  }, []);

  useEffect(() => {
    populateFormValues();
  }, [calculatedLoanDetails])

  const onCalculateInfoFinish = (values) => {
    const form = {
      ...values,
    };

    const payload = {
      principal: form.principal,
      tenure: form.tenure,
      // processingFee: form.processingFee
    };

    console.log("[COMPLETE PAYLOAD]:: ", payload);

    dispatch(processLoanEnquiry(payload));

  };

  const populateFormValues = () => {
    // fill form values with response data
    if (calculateLoanSuccess) {

      calculatorForm.setFieldsValue({ processingFee: calculatedLoanDetails.processingFee });
      calculatorForm.setFieldsValue({ netLoanValue: calculatedLoanDetails.netLoanValue });
      calculatorForm.setFieldsValue({ interest: calculatedLoanDetails.interestAmount });
      calculatorForm.setFieldsValue({ totalDeduction: calculatedLoanDetails.totalDeduction });
      calculatorForm.setFieldsValue({ roundedMonthlyDeduc: calculatedLoanDetails.roundedMonthlyDeduc });
      calculatorForm.setFieldsValue({ initialAmount: calculatedLoanDetails.initialAmount });
       
    }

  }

  const renderLoanCalcForm = () => {
    return (
      <Col >
        <Form
          onFinish={onCalculateInfoFinish}
          className="row-col"
          layout="vertical"
          form={calculatorForm}
        >
          <Row>
            <Form.Item
              className="addLoan__card--form__input"
              label="Principal"
              name="principal"
              rules={[
                { required: true, message: "Please provide loan amount!" },
              ]}
            >
              <Form.Item name="principal">
                <Input type="number" />
              </Form.Item>
            </Form.Item>

            <Form.Item
              className="addLoan__card--form__input "
              label="Tenure"
              name="tenure"
            >
              <Form.Item name="tenure">
                <Select
                  className="addLoan__card--form__select"
                  loading={loadingLoanProducts}
                >
                  {loanProducts.map((product, i) => {
                    return (
                      <Select.Option key={i} value={product.tenure}>
                        {product.name}
                      </Select.Option>
                    );
                  })}
                </Select>
              </Form.Item>
            </Form.Item>

            <Form.Item
              label="Processing Fee"
              name="processingFee"
              className="addLoan__card--form__input"
            >
              <Form.Item name="processingFee">
                <Input disabled type="number" />
              </Form.Item>
            </Form.Item>
          </Row>

          <Row>
            <Form.Item
              label="Net Loan Value"
              name="netLoanValue"
              className="addLoan__card--form__input"
            >
              <Form.Item name="netLoanValue">
                <Input disabled type="number" />
              </Form.Item>
            </Form.Item>

            <Form.Item
              label="Interest"
              name="interest"
              className="addLoan__card--form__input"
            >
              <Form.Item name="interest">
                <Input disabled type="number" />
              </Form.Item>
            </Form.Item>

            <Form.Item
              label="Total Deduction "
              name="totalDeduction"
              className="addLoan__card--form__input"
            >
              <Form.Item name="totalDeduction">
                <Input disabled type="number" />
              </Form.Item>
            </Form.Item>
          </Row>

          <Row>
            <Form.Item
              label="Rounded Monthly Deduction (RMD)"
              name="roundedMonthlyDeduc"
              className="addLoan__card--form__input"
            >
              <Form.Item name="roundedMonthlyDeduc">
                <Input disabled type="number" />
              </Form.Item>
            </Form.Item>

            <Form.Item
              label="Initial Amount"
              name="initialAmount"
              className="addLoan__card--form__input"
            >
              <Form.Item name="initialAmount">
                <Input disabled type="number" />
              </Form.Item>
            </Form.Item>

          </Row>

          <div className="addLoan__card--form__line"></div>

          <div className="addLoan__card--form__btnRow">
            <Button
            >
              
            </Button>

            <Button htmlType="submit" type="primary">
              Calculate
            </Button>
            
          </div>
        </Form>
      </Col>
    );
  };

  return (

    <div className="tabled addLoan">
      <Row gutter={[24, 0]}>

        <Col xs="24" xl={24}>
          <Card
            bordered={false}
            className="circlebox tablespace mb-24 addLoan__card"
            title={title}
          >
            {renderLoanCalcForm()}
          </Card>
        </Col>

        {loanProductsError &&
          openNotifcation(
            "error",
            "Error Fetching Loan Tenures"
          )}

        {calculateLoanError &&
          openNotifcation(
            "error",
            "Error Performing Calculation"
          )}
      </Row>
    </div>

  );
});

export default LoanCalculator;
