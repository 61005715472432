import {
  GET_ALL_LOAN_PRODUCTS,
  GET_ALL_LOAN_PRODUCTS_ERROR,
  GET_ALL_LOAN_PRODUCTS_SUCCESS,
} from "../actions";

const INITIAL_STATE = {
  loading: false,
  error: false,
  loanProducts: [],
  errorMsg:""
};

const LoanProductsReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case GET_ALL_LOAN_PRODUCTS:
      return {
        ...state,
        loading: true,
        error: false,
        errorMsg: ""
      };

    case GET_ALL_LOAN_PRODUCTS_SUCCESS:
      return {
        ...state,
        loading: false,
        error: false,
        loanProducts: action.payload,
        errorMsg: ""
      };

    case GET_ALL_LOAN_PRODUCTS_ERROR:
      return {
        ...state,
        loading: false,
        error: true,
        errorMsg: action.payload
      };

    default:
      return state;
  }
};

export default LoanProductsReducer;
