import {
  EMAIL_CHANGED,
  PASSWORD_CHANGED,
  LOGGING_IN,
  LOGIN_ERROR,
  LOGIN_SUCCESS,
  LOG_OUT,
} from "../actions/types";

const INITIAL_STATE = {
  email: "",
  password: "",
  user: JSON.parse(localStorage.getItem("user")),
  loggingIn: false,
  error: false,
  errorMsg: "",
};

const AuthReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case EMAIL_CHANGED:
      return {
        ...state,
        email: action.payload,
        error: false,
      };

    case PASSWORD_CHANGED:
      return {
        ...state,
        password: action.payload,
        error: false,
      };

    case LOGGING_IN:
      return {
        ...state,
        loggingIn: true,
        error: false,
      };

    case LOGIN_SUCCESS:
      return {
        ...state,
        loggingIn: false,
        error: false,
        user: action.payload,
      };

    case LOGIN_ERROR:
      return {
        ...state,
        loggingIn: false,
        error: true,
        errorMsg: action.payload.error,
      };

    case LOG_OUT:
      return {
        ...state,
        user: null,
      };

    default:
      return state;
  }
};

export default AuthReducer;
