/*!
=========================================================
* Muse Ant Design Dashboard - v1.0.0
=========================================================
* Product Page: https://www.creative-tim.com/product/muse-ant-design-dashboard
* Copyright 2021 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/muse-ant-design-dashboard/blob/main/LICENSE.md)
* Coded by Creative Tim
=========================================================
* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/
import { EditFilled, LeftOutlined } from "@ant-design/icons";
import {
  Avatar,
  Button,
  Card,
  Col,
  Descriptions,
  Form,
  Input,
  message,
  Modal,
  Row,
  Select,
  Tabs,
  Typography
} from "antd";
import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { withRouter } from "react-router-dom";
import { updateUserRole } from "../actions";
import face5 from "../assets/images/face-5.jpeg";
import "./Client.scss";

const { TabPane } = Tabs;
const { Title } = Typography;

const UserDetails = withRouter(({ history }) => {
  const [user, setUser] = useState(history.location.state.user);
  const [roles, setRoles] = useState(history.location.state.roles);
  const dispatch = useDispatch();

  //** ADDED USER */
  const [userForm] = Form.useForm();
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [editingUser, setEditingUser] = useState(false);

  const goBack = () => {
    history.goBack();
  };

  const showEditUserModal = () => {
    setIsModalVisible(true);
  };

  const updateUser = () => {
    userForm.validateFields().then((formData) => {
      console.log("Form SUCCESS", formData);
      setEditingUser(true);
      dispatch(updateUserRole(user, formData.userRole))
        .then((res) => {
          console.log("edit user res", res);
          setEditingUser(false);
          setUser(res);
          message.success("The user role has been updated successfully");
          setIsModalVisible(false);
        })
        .catch((err) => {
          setEditingUser(false);
          message.error("Something went wrong");
        });
    });
  };

  const handleCancel = () => {
    setIsModalVisible(false);
  };

  return (
    <div className="tabled client">
      <Row gutter={[24, 0]}>
        <div className="client__row">
          <div className="client__row--btns">
            <Button onClick={() => goBack()} icon={<LeftOutlined />}>
              Back
            </Button>

            <Button
              onClick={() => showEditUserModal()}
              icon={<EditFilled />}
              type="primary"
            >
              Edit User Details
            </Button>
          </div>
        </div>

        <Col xs={24} md={16}>
          <Card className="mb-24">
            {/* <Title level={4}>Tab Section</Title> */}
            <Tabs defaultActiveKey="1">
              <TabPane key="1" tab="Personal">
                <Col span={24}>
                  <Card className="card-billing-info" bordered="false">
                    <div className="col-info">
                      <Descriptions>
                        <Descriptions.Item label="Client ID" span={3}>
                          {user.id}
                        </Descriptions.Item>
                        <Descriptions.Item label="Full Name" span={3}>
                          {user.fullname}
                        </Descriptions.Item>
                        <Descriptions.Item label="Email" span={3}>
                          {user.email}
                        </Descriptions.Item>
                        <Descriptions.Item label="Role" span={3}>
                          {user.userRole}
                        </Descriptions.Item>
                        <Descriptions.Item label="Sign In Count" span={3}>
                          {user.signInCount}
                        </Descriptions.Item>
                      </Descriptions>
                    </div>
                  </Card>
                </Col>
              </TabPane>

              {/* <TabPane key="2" tab="Roles">
                <Col span={24}>
                  <Card className="card-billing-info" bordered="false">
                    <div className="col-info">
                     
                    </div>
                  </Card>
                </Col>
              </TabPane> */}
            </Tabs>
          </Card>
        </Col>

        <Col span={24} md={8} className="mb-24">
          <Card bordered={false} className="client__imgCard">
            {/* <Title level={4}>Image Section</Title> */}

            <Avatar src={face5} size={200} />
          </Card>
        </Col>
      </Row>

      <Modal
        title={"Edit User " + user.fullname}
        visible={isModalVisible}
        confirmLoading={editingUser}
        okText="Save"
        onOk={updateUser}
        onCancel={handleCancel}
      >
        <Col>
          <Form
            form={userForm}
            layout="vertical"
            requiredMark={true}
            className="row-col space-below"
          >
            <Form.Item
              label="Full Name"
              name="fullname"
              initialValue={user.fullname}
            >
              <Input type="text" readOnly />
            </Form.Item>

            <Form.Item label="Email" name="email" initialValue={user.email}>
              <Input readOnly />
            </Form.Item>

            <Form.Item label="Password" name="password" initialValue="********">
              <Input type="password" readOnly />
            </Form.Item>

            <Form.Item
              label="Role"
              name="userRole"
              initialValue={user.userRole}
              rules={[{ required: true }]}
            >
              <Select>
                {(roles ?? []).map((e) => (
                  <Select.Option key={e.id} value={e.name}>
                    {e.name}
                  </Select.Option>
                ))}
              </Select>
            </Form.Item>
          </Form>
        </Col>
      </Modal>

      {/* {imageError &&
        openNotifcation("error", "Error Fetching Client Image", imageErrorMsg)} */}
    </div>
  );
});

export default UserDetails;
