const dataHubs = [
  {
    id: 1,
    name: "Kokomlemle",
    active: 1,
    created_at: "2018-09-03 12:45:08",
    updated_at: "2021-02-08 21:27:36",
  },
  {
    id: 2,
    name: "Hohoe",
    active: 1,
    created_at: "2018-09-03 12:45:08",
    updated_at: "2018-09-03 12:45:08",
  },
  {
    id: 3,
    name: "Kumasi",
    active: 1,
    created_at: "2018-09-03 12:45:08",
    updated_at: "2018-09-03 12:45:08",
  },
  {
    id: 4,
    name: "Tamale",
    active: 1,
    created_at: "2018-09-03 12:45:08",
    updated_at: "2018-09-03 12:45:08",
  },
];

exports.dataHubs = dataHubs;
