const loanPurposes = [
  {
    id: 1,
    name: "Wedding",
    accepted: 1,
    created_at: "2018-09-04 12:29:17",
    updated_at: "2018-09-04 12:29:17",
  },
  {
    id: 2,
    name: "Funeral",
    accepted: 1,
    created_at: "2018-09-04 12:29:31",
    updated_at: "2018-09-04 12:29:31",
  },
  {
    id: 3,
    name: "Education/School Fees\t",
    accepted: 1,
    created_at: "2018-09-04 12:30:15",
    updated_at: "2018-09-04 12:31:52",
  },
  {
    id: 4,
    name: "Housing - Rent Advance",
    accepted: 1,
    created_at: "2018-09-04 12:31:20",
    updated_at: "2018-09-04 12:32:57",
  },
  {
    id: 5,
    name: "Housing - Land Purchase",
    accepted: 1,
    created_at: "2018-09-04 12:32:41",
    updated_at: "2018-09-04 12:32:41",
  },
  {
    id: 6,
    name: "Healthcare",
    accepted: 1,
    created_at: "2018-09-04 12:33:19",
    updated_at: "2018-09-04 12:33:19",
  },
  {
    id: 7,
    name: "Business",
    accepted: 1,
    created_at: "2018-09-04 12:33:35",
    updated_at: "2018-09-04 12:33:35",
  },
  {
    id: 8,
    name: "Debt Settlement",
    accepted: 1,
    created_at: "2018-09-04 12:33:56",
    updated_at: "2018-09-04 12:33:56",
  },
];

exports.loanPurposes = loanPurposes;
