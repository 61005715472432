import {
  LOADING_COMMISSION,
  LOADING_COMMISSION_SUCCESS,
  LOADING_COMMISSION_ERROR,
} from ".";
import payrollApi from "../api/payrollApi";

import { store } from "../store";
import {
  APPROVE_COMMISSION,
  APPROVE_COMMISSION_ERROR,
  APPROVE_COMMISSION_SUCCESS,
  CALC_COMMISSION,
  CALC_COMMISSION_ERROR,
  CALC_COMMISSION_SUCCESS,
  LOADING_PAYMENT_TYPES,
  PAYMENT_TYPES_ERROR,
  PAYMENT_TYPES_SUCCESS,
  PAY_COMMISSION,
  PAY_COMMISSION_ERROR,
  PAY_COMMISSION_SUCCESS,
} from "./types";

store.subscribe(listener);

let token;
function select(state) {
  if (state.auth.user) {
    return state.auth.user.token;
  }
}

function listener() {
  token = select(store.getState());
  if (token) {
    payrollApi.defaults.headers.common["Authorization"] = `Bearer ${token}`;
  }
}

const getComissionSuccess = (dispatch, commissions) => {
  dispatch({ type: LOADING_COMMISSION_SUCCESS, payload: commissions });
};

const getCommissionError = (dispatch, error) => {
  dispatch({ type: LOADING_COMMISSION_ERROR, payload: error });
};

export const getCommissions = (params) => {
  return (dispatch) => {
    dispatch({ type: LOADING_COMMISSION });

    return new Promise((resolve, reject) => {
      payrollApi
        .get(`agents/commissions?page=${params.page}&pageSize=${params.size}`)
        .then((commRes) => {
          const { data } = commRes;

          console.log("[COMMISSION RESPONSE]:: ", data);

          resolve(data.pageItems);
          getComissionSuccess(dispatch, data.pageItems);
        })
        .catch((err) => {
          reject(err);
          getCommissionError(dispatch, err);
        });
    });
  };
};

const handlePaymentTypeSuccess = (dispatch, paymentTypes) => {
  dispatch({ type: PAYMENT_TYPES_SUCCESS, payload: paymentTypes });
};

const handllePaymentTypesError = (dispatch, err) => {
  dispatch({ type: PAYMENT_TYPES_ERROR, payload: err });
};

export const getPaymentTypes = () => {
  return (dispatch) => {
    dispatch({ type: LOADING_PAYMENT_TYPES });

    payrollApi
      .get(`/payment-types`)
      .then((res) => {
        const { data } = res;
        console.log("[PAYMENT TYPES RES]:: ", data);
        handlePaymentTypeSuccess(dispatch, data);
      })
      .catch((err) => {
        handllePaymentTypesError(dispatch, err);
        console.log("[PAYMENT TYPE ERROR]:: ", err);
      });
  };
};

const handlePayCommissionSuccess = (dispatch, payload) => {
  dispatch({ type: PAY_COMMISSION_SUCCESS, payload });
};

const handlePayCommissionError = (dispatch, err) => {
  dispatch({ type: PAY_COMMISSION_ERROR, payload: err });
};

export const payCommission = (payload, { onSuccess, onError }) => {
  return (dispatch) => {
    dispatch({ type: PAY_COMMISSION });

    payrollApi
      .post(`/agents/commissions/payment`, payload)
      .then((res) => {
        const { data } = res;
        console.log("[MAKE PAYMENT RES]:: ", data);
        handlePayCommissionSuccess(dispatch, data);
        onSuccess();
      })
      .catch((err) => {
        onError();
        handlePayCommissionError(dispatch, err);
      });
  };
};

const handleApproveCommissionSuccess = (dispatch, payload) => {
  dispatch({ type: APPROVE_COMMISSION_SUCCESS, payload });
};

const handleApproveCommissionError = (dispatch, err) => {
  dispatch({ type: APPROVE_COMMISSION_ERROR, payload: err });
};

export const approveCommission = (payload, { onSuccess, onError }) => {
  return (dispatch) => {
    dispatch({ type: APPROVE_COMMISSION });

    payrollApi
      .post(`/agents/approve-commission`, payload)
      .then((res) => {
        const { data } = res;
        console.log("[APPROVE COMMISSION RES]:: ", data);
        onSuccess();
        handleApproveCommissionSuccess(dispatch, data.pageItems);
      })
      .catch((err) => {
        onError();
        console.log("[APPROVE COMMISSION ERROR]:: ", err);
        handleApproveCommissionError(dispatch, err);
      });
  };
};

const handleCalcCommissionSuccess = (dispatch, payload) => {
  dispatch({ type: CALC_COMMISSION_SUCCESS, payload });
};

const handleCalcComissionError = (dispatch, error) => {
  dispatch({ type: CALC_COMMISSION_ERROR, payload: error });
};

export const calcCommission = (payload, { onSuccess, onError }) => {
  return (dispatch) => {
    dispatch({
      type: CALC_COMMISSION,
    });

    payrollApi
      .post(`/agents/calc-commission`, payload)
      .then((res) => {
        const { data } = res;
        handleCalcCommissionSuccess(dispatch, data);
        onSuccess();
      })
      .catch((err) => {
        onError();
        handleCalcComissionError(dispatch, err);
      });
  };
};
