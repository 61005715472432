import { EditFilled, UserOutlined } from "@ant-design/icons";
import {
  Avatar,
  Button,
  Card,
  Col,
  Form,
  Input,
  message,
  Modal,
  Row,
  Select,
  Table,
  Typography,
} from "antd";
import moment from "moment";
import { default as React, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { withRouter } from "react-router-dom";
import { getAllRoles } from "../actions/RoleActions";
import { addUser, getAllUsers } from "../actions/UserActions";
import face2 from "../assets/images/face-2.jpg";

const { Title } = Typography;

// table code start
const columns = [
  {
    title: "Full Name",
    key: "fullname",
    dataIndex: "fullname",
  },
  {
    title: "Role",
    key: "userRole",
    dataIndex: "userRole",
  },
  {
    title: "Sign In Count",
    key: "signInCount",
    dataIndex: "signInCount",
    // width: "32%",
  },
  {
    title: "Date",
    key: "createdAt",
    dataIndex: "createdAt",
  },
  {
    title: "",
    dataIndex: "view",
    key: "view",
  },
];

// ! TODO fetch roles

const UsersList = withRouter(({ history }) => {
  const dispatch = useDispatch();

  const users = useSelector(({ users }) => users?.data ?? []);
  const error = useSelector(({ users }) => users?.error_getting_users);
  const userLoading = useSelector(({ users }) => users.loading);
  const roles = useSelector(({ roles }) => roles?.data ?? []);
  const roleError = useSelector(({ roles }) => roles?.error_getting_roles);
  const roleLoading = useSelector(({ roles }) => roles?.loading_roles);

  //** ADDED USER */
  const [addingUser, setAddingUser] = useState(false);
  const [newUserForm] = Form.useForm();

  const fetchUsers = () => {
    dispatch(getAllUsers({ size: 100, page: 0 }));
  };

  const fetchRoles = () => {
    dispatch(getAllRoles({ size: 100, page: 0 }));
  };

  useEffect(() => {
    fetchUsers();
    fetchRoles();
  }, []);

  const routeToUser = (user) => {
    history.push({
      pathname: `/users/view/${user.id}`,
      state: { user, roles },
    });
  };

  const [isModalVisible, setIsModalVisible] = useState(false);

  const showAddUserModal = () => {
    setIsModalVisible(true);
  };

  const saveNewUser = () => {
    newUserForm.validateFields().then((formData) => {
      setAddingUser(true);

      dispatch(addUser(formData))
        .then((res) => {
          setAddingUser(false);
          message.success("The user has been added successfully");
          fetchUsers();
          newUserForm.resetFields();
          setIsModalVisible(false);
        })
        .catch((err) => {
          setAddingUser(false);
          message.error("Something went wrong");
        });
    });
  };

  const handleCancel = () => {
    newUserForm.resetFields();
    setIsModalVisible(false);
  };

  let _data = [];
  users.map((user, i) => {
    const _user = {
      key: `user-${i}`,
      fullname: (
        <>
          <Avatar.Group>
            <Avatar
              className="shape-avatar"
              shape="circle"
              size={40}
              icon={<UserOutlined />}
            ></Avatar>
            <div className="avatar-info">
              <Title level={5}>{user.fullname}</Title>
              <p>{user.email}</p>
            </div>
          </Avatar.Group>{" "}
        </>
      ),
      userRole: (
        <>
          <div className="avatar-info">{user.userRole}</div>
        </>
      ),
      signInCount: (
        <>
          <div className="avatar-info">{user.signInCount}</div>
        </>
      ),
      createdAt: (
        <>
          <div className="avatar-info">
            {moment(user.createdAt).format("DD-MM-YYYY")}
          </div>
        </>
      ),
      view: (
        <>
          <Button
            onClick={() => routeToUser(user)}
            type="primary"
            className="tag-primary"
          >
            View
          </Button>
        </>
      ),
    };
    //** Update _data array */
    _data.push(_user);
  });

  return (
    <>
      <div className="tabled">
        <Row gutter={[24, 0]}>
          <Col xs="24" xl={24}>
            <Card
              bordered={false}
              className="criclebox tablespace mb-24"
              title="Users List"
              extra={
                <>
                  <Button
                    onClick={() => showAddUserModal()}
                    icon={<EditFilled />}
                    type="primary"
                  >
                    Add New User
                  </Button>
                </>
              }
            >
              <div className="table-responsive">
                <Table
                  loading={userLoading}
                  columns={columns}
                  dataSource={_data}
                  pagination={false}
                  className="ant-border-space"
                />
              </div>
            </Card>
          </Col>
        </Row>
      </div>

      <Modal
        title="Add New User"
        visible={isModalVisible}
        confirmLoading={addingUser}
        okText="Save"
        onOk={saveNewUser}
        onCancel={handleCancel}
      >
        <Col loading={userLoading || roleLoading}>
          <Form
            form={newUserForm}
            layout="vertical"
            requiredMark={true}
            className="row-col space-below"
          >
            <Form.Item
              label="Full Name"
              name="fullname"
              rules={[{ required: true }]}
            >
              <Input type="text" placeholder="John Doe" />
            </Form.Item>

            <Form.Item
              label="Email"
              name="email"
              rules={[{ required: true }, { type: "email" }]}
            >
              <Input placeholder="user@test.com" />
            </Form.Item>

            <Form.Item
              label="Password"
              name="password"
              rules={[{ required: true }, { min: 6 }]}
            >
              <Input type="password" />
            </Form.Item>

            <Form.Item
              label="Role"
              name="userRole"
              rules={[{ required: true }]}
            >
              <Select>
                {(roles ?? []).map((e) => (
                  <Select.Option key={e.id} value={e.name}>
                    {e.name}
                  </Select.Option>
                ))}
              </Select>
            </Form.Item>
          </Form>
        </Col>
      </Modal>
    </>
  );
});

export default UsersList;
