import {
  GET_ALL_AGENTS,
  GET_ALL_AGENTS_ERROR,
  GET_ALL_AGENTS_SUCCESS,
  GET_AGENT_LOANS,
  GET_AGENT_LOANS_SUCCESS,
  GET_AGENT_LOANS_ERROR,
} from "../actions/types";

const INITIAL_STATE = {
  loading: false,
  agents: [],
  loans: [],
  error: false,
  loadingLoans: false,
  loansError: false,
};

const AgentsReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case GET_ALL_AGENTS:
      return {
        ...state,
        loading: true,
        error: false,
      };

    case GET_ALL_AGENTS_SUCCESS:
      return {
        ...state,
        loading: false,
        error: false,
        agents: action.payload,
      };

    case GET_ALL_AGENTS_ERROR:
      return {
        ...state,
        loading: false,
        error: true,
      };

    case GET_AGENT_LOANS:
      return {
        ...state,
        loadingLoans: true,
        loansError: false,
      };

    case GET_AGENT_LOANS_SUCCESS:
      return {
        ...state,
        loadingLoans: false,
        loans: action.payload,
      };

    case GET_AGENT_LOANS_ERROR:
      return {
        ...state,
        loadingLoans: false,
        loansError: true,
      };

    default:
      return state;
  }
};

export default AgentsReducer;
