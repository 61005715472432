/*!
=========================================================
* Muse Ant Design Dashboard - v1.0.0
=========================================================
* Product Page: https://www.creative-tim.com/product/muse-ant-design-dashboard
* Copyright 2021 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/muse-ant-design-dashboard/blob/main/LICENSE.md)
* Coded by Creative Tim
=========================================================
* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/
import {
  Row,
  Col,
  Card,
  Table,
  Upload,
  message,
  Button,
  Typography,
  Input,
  Progress,
  Pagination,
  DatePicker
} from "antd";

import { ToTopOutlined, DownloadOutlined } from "@ant-design/icons";

import { withRouter } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { getRepayments, processRepayments, getPreRepaymentReportData } from "../actions";
import { useEffect, useState } from "react";
import moment from "moment";
import "./Loans.scss";
import "./Repayments.scss";

import { openNotifcation } from "../components/Notification";
import { BASE_URL, BASE_IP_URL } from "../constants";
import * as FileSaver from 'file-saver';
import * as XLSX from 'xlsx';

const { Title } = Typography;

// project table start
const tableColumns = [
  {
    title: "ID",
    dataIndex: "id",
    key: "id",
  },
  {
    title: "DOCUMENT TYPE",
    dataIndex: "documentType",
    key: "documentType",
  },
  {
    title: "FILE NAME",
    dataIndex: "fileName",
    key: "fileName",
  },
  {
    title: "MONTH",
    dataIndex: "month",
    key: "month",
  },
  {
    title: "YEAR",
    dataIndex: "year",
    key: "year",
  },
  {
    title: "PROCESSED",
    dataIndex: "processed",
    key: "processed",
  },
  {
    title: "",
    dataIndex: "process",
    key: "process",
  },
  {
    title: "",
    dataIndex: "download",
    key: "download",
  },
];

const Repayments = withRouter(({ history, ...props }) => {

  const uploadedRepayments = useSelector(({ repayments }) => repayments.uploadedRepayments);
  const prerepayments_reports = useSelector(({ repayments }) => repayments.prerepayments_reports);

  const onRepaymentError = () => {
    openNotifcation("error", "Repayments Error", errorMsg);
  };


  // use this
  const onFormatRepayment_refactored = (repaymentUploadList) => {
    let formattedRepayments = [];

    if (repaymentUploadList !== undefined) {
      repaymentUploadList.map((repayment, i) => {
        const _repayment = {
          key: `repayment-${i}`,
          id: (
            <>
              <div className="author-info">
                <Title level={5}>{repayment.id} </Title>
              </div>
            </>
          ),
          fileName: (
            <>
              <div className="author-info">
                <Title level={5}>{repayment.fileName} </Title>
              </div>
            </>
          ),
          month: (
            <>
              <div className="author-info">
                <Title level={5}>
                  {repayment.month.replace("  ", "").replace(/['"]+/g, "")}{" "}
                </Title>
              </div>
            </>
          ),
          year: (
            <>
              <div className="author-info">
                <Title level={5}>
                  {repayment.year.replace("  ", "").replace(/['"]+/g, "")}{" "}
                </Title>
              </div>
            </>
          ),
          documentType: (
            <>
              <div className="author-info">
                <Title level={5}>
                  {repayment.documentType.replace("_", " ")}
                </Title>
              </div>
            </>
          ),
          processed: (
            <>
              <div className="author-info">
                <p
                  className={renderProcessColor(repayment.processed)}
                  level={5}
                >
                  {repayment.processed.toString()}
                </p>
              </div>
            </>
          ),
          process: (
            <>
              {repayment.processed === false &&
                // (processingRepayment || startProcessingRepayment) ? (
                (startProcessingRepayment) ? (
                <Button disabled type="primary">
                  Processing...
                </Button>
              ) : (
                <Button
                  // disabled={processingRepayment}
                  // disabled={processingRepayment || repayment.processed || successProcessingRepayment}
                  disabled={repayment.processed || successProcessingRepayment}
                  onClick={(event) => handleProcessClicked(repayment.id, event)}
                  // onClick={() => props.onProcessClicked(repayment.id)}
                  type="primary"
                  className="tag-primary"
                >
                  Process
                </Button>
              )}
            </>
          ),
          download: (
            <>
              <div className="author-info">
                <p
                  className={renderProcessColor(repayment.processed)}
                  level={5}
                >
                  {/* {repayment.processed.toString()} */}
                  <Button primary onClick={(event) => handlePreReportDownload(repayment.month, repayment.year, event)}><DownloadOutlined /></Button>
                </p>
              </div>
            </>
          ),

        };

        formattedRepayments.push(_repayment);
      });

      setRepaymentData(formattedRepayments);
    }
  };


  const fileType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
  const fileExtension = '.xlsx';

  const [downloadMonth, setDownloadMonth] = useState();
  const [downloadYear, setDownloadYear] = useState();

  const onReportsError = () => {
    setDate(null);
    openNotifcation("error", "No Reports Found!");
  };

  const fetchPreRepaymentReports = (month, year, guidValue) => {

    setDownloadMonth(moment().month(month - 1).format("MMM"));
    setDownloadYear(year);

    const payload = {
      year: year,
      month: month,
      guid: guidValue
    };

    console.log("---[PRE_REPAYMENT_PAYLOADs::", payload);

    dispatch(
      getPreRepaymentReportData(payload, {
        onError: onReportsError,
      })
    );

  }

  const exportToCSV = (csvData, fileName) => {
    const ws = XLSX.utils.json_to_sheet(csvData);
    const wb = { Sheets: { 'data': ws }, SheetNames: ['data'] };
    const excelBuffer = XLSX.write(wb, { bookType: 'xlsx', type: 'array' });
    const data = new Blob([excelBuffer], { type: fileType });
    FileSaver.saveAs(data, fileName + fileExtension);
  }

  useEffect(() => {

    if (prerepayments_reports.length !== 0 && downloadMonth != undefined) {
      console.log("---- DOWNLOADING PRE-REPORT---");
      const filename = downloadMonth + "_" + downloadYear + "-Pre_Repayment_Report";
      exportToCSV(prerepayments_reports, filename);
    }

  }, [prerepayments_reports]);

  const [processCompleted, setProcessCompleted] = useState(false);
  const handlePreReportDownload = (month, year, event) => {
    event.preventDefault();

    let url = `${BASE_URL}/reports/progress`;
    const eventSource = new EventSource(url);
    let guidValue = null;

    eventSource.addEventListener("GUI_ID", (event) => {
      guidValue = JSON.parse(event.data);
      console.log(`Guid from server: ${guidValue}`);

      eventSource.addEventListener(guidValue, (event) => {
        const result = JSON.parse(event.data);
        if (processingPercentage !== result) {
          setProcessingPercentage(result);
        }
        if (result === "100") {
          eventSource.close();
          setRerender(!rerender);
        }
      });

      fetchPreRepaymentReports(month, year, guidValue);
    });

    eventSource.onerror = (event) => {
      if (event.target.readyState === EventSource.CLOSED) {
        console.log("SSE closed (" + event.target.readyState + ")");
      }
      setProcessingPercentage(0);
      eventSource.close();
    };

    eventSource.onopen = () => {
      console.log("connection opened");
    };
  };

  const [date, setDate] = useState();
  const repayments = useSelector(({ repayments }) => repayments);
  const [repaymentData, setRepaymentData] = useState([]);
  const {
    loading,
    error,
    errorMsg,
    data,
    processingRepayment,
    processingProgress,
    // startProcessingRepayment,
  } = repayments;
  const user = JSON.parse(localStorage.getItem("user"));
  const dispatch = useDispatch();
  const { MonthPicker } = DatePicker;

  const [processingPercentage, setProcessingPercentage] = useState(0);
  const startProcessingRepayment = useSelector(({ repayments }) => repayments.startProcessingRepayment);
  const successProcessingRepayment = useSelector(({ repayments }) => repayments.successProcessingRepayment);
  const errorProcessingRepayment = useSelector(({ repayments }) => repayments.errorProcessingRepayment);

  const formProps = {
    name: "file",
    data: {
      month: moment(date).format("M"),
      year: moment(date).format("YYYY"),
    },
    action:
      // "https://payrolltest.linkexchange.com.gh/payroll/api/v1/repayments/uploads",
      `${BASE_URL}/repayments/uploads`,
    headers: {
      Authorization: `Bearer ${user.token}`,
    },
    onChange(info) {
      if (info.file.status !== "uploading") {
        console.log(info.file, info.fileList);
      }
      if (info.file.status === "done") {
        message.success(`${info.file.name} file uploaded successfully`);
        //** GET UPDATED REPAYMENTS */
        dispatch(
          getRepayments({
            // onSuccess: onFormatRepayment,
            onError: onRepaymentError,
          })
        );
      } else if (info.file.status === "error") {
        message.error(`${info.file.name} file upload failed.`);
      }
    },
  };

  const renderProcessColor = (status) => {
    switch (status) {
      case false:
        return "arrears";

      default:
        return "approved";
    }
  };

  const [rerender, setRerender] = useState(false);
  useEffect(() => {
    //re-render page - used to mitigate useState's async nature
  }, [rerender]);

  useEffect(() => {
    setRerender(!rerender); //re-render page to update useEffect state
  }, [successProcessingRepayment, startProcessingRepayment]);

  const handleProcessSuccess = () => {
    // props.onProcessClicked(id);
    setRerender(!rerender); //re-render page to update useEffect state
  };

  const handleProcessError = () => {
    openNotifcation("error", "Error processing repayment file");
  };

  //configure event
  const handleProcessClicked = (id, event) => {
    event.preventDefault();

    let url = `${BASE_URL}/repayments/progress`;
    const eventSource = new EventSource(url);
    let guidValue = null;

    eventSource.addEventListener("GUI_ID", (event) => {
      guidValue = JSON.parse(event.data);
      console.log(`Guid from server: ${guidValue}`);

      const requestData = {
        guid: guidValue,
        id: id
      }

      eventSource.addEventListener(guidValue, (event) => {
        const result = JSON.parse(event.data);
        if (processingPercentage !== result) {
          setProcessingPercentage(result);
        }
        if (result === "100") {
          eventSource.close();
        }
      });

      process(requestData);
    });

    eventSource.onerror = (event) => {
      if (event.target.readyState === EventSource.CLOSED) {
        console.log("SSE closed (" + event.target.readyState + ")");
      }
      setProcessingPercentage(0);
      eventSource.close();
    };

    eventSource.onopen = () => {
      console.log("connection opened");
    };
  };

  const process = (requestData) => {
    dispatch(
      processRepayments(requestData, {
        onSuccess: () => handleProcessSuccess(),
        onError: handleProcessError,
      })
    );
    setRerender(!rerender);
  };

  //** FORMATTED DATA */

  useEffect(() => {
    dispatch(
      getRepayments({ onError: onRepaymentError })
    );
  }, []);

  useEffect(() => {
    onFormatRepayment_refactored(uploadedRepayments);
  }, [uploadedRepayments]);

  useEffect(() => {
    // update the repaymentData as it changes - used to mitigate useState's async nature
  }, [repaymentData]);

  const routeToReports = () => {
    history.push("/reports");
  };

  function disabledDate(current) {
    // Can not select days after current month
    return current > moment().endOf('day');
  }

  function onChange(value) {
    setDate(value);
  }

  return (
    <>
      <div className="tabled">
        <Row gutter={[24, 0]}>
          <Col xs="24" xl={24}>
            <Card
              bordered={false}
              className="criclebox tablespace mb-24"
              title="Uploaded Repayments"
              extra={
                <div className="repayments__row">
                  <div className="repayments__row--bt">
                    <MonthPicker onChange={onChange}
                      value={date}
                      disabledDate={disabledDate}
                      placeholder="Select month for repayment uploaded" className="repayments__row--btn" />

                    <Upload {...formProps}>
                      <Button
                        disabled={!date}
                        type="dashed"
                        className="ant-full-box"
                        icon={<ToTopOutlined />}
                      >
                        Click to Upload
                      </Button>
                    </Upload>
                  </div>

                  <Button
                    onClick={() => routeToReports()}
                    className="repayments__row--btn"
                  >
                    View Reports
                  </Button>
                </div>
              }
            >
              <div className="repayments__progress">
                {/* {processingRepayment && ( */}
                <Progress
                  strokeColor={{
                    "0%": "#108ee9",
                    "100%": "#87d068",
                  }}
                  percent={(processingPercentage / 100) * 100}
                />
                {/* )} */}
              </div>
              <div className="table-responsive">
                <Table
                  columns={tableColumns}
                  loading={loading}
                  dataSource={repaymentData}
                  // pagination={{
                  //   defaultCurrent: data.pageNumber + 1,
                  //   total: data.totalPages,
                  // }}
                  className="ant-border-space"
                />
              </div>

            </Card>
          </Col>
        </Row>
      </div>
    </>
  );
});

export default Repayments;
