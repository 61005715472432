import {
  CLIENTS_ERROR,
  CLIENTS_SUCCESS,
  CLIENT_ERROR,
  CLIENT_SUCCESS,
  LOADING_CLIENT,
  LOADING_CLIENTS,
  LOADING_IMAGE,
  IMAGE_ERROR,
  IMAGE_SUCCESS,
  GET_CLIENT_CUSTOM_FIELD_SUCCESS,
  GET_CLIENT_CUSTOM_FIELD_ERROR,
  GET_CLIENT_CUSTOM_FIELD,
  SEARCH_CLIENTS,
  SEARCH_CLIENTS_ERROR,
  SEARCH_CLIENTS_SUCCESS,
  LOADING_CLIENT_IDS,
  CLIENT_ID_LOADED_SUCCESS,
  CLIENT_ID_LOADED_ERROR
} from ".";
import payrollApi from "../api/payrollApi";

import { store } from "../store";

store.subscribe(listener);

let token;
function select(state) {
  if (state.auth.user) {
    return state.auth.user.token;
  }
}

function listener() {
  token = select(store.getState());
  if (token) {
    payrollApi.defaults.headers.common["Authorization"] = `Bearer ${token}`;
  }
}

const getAllClientsSuccess = (dispatch, clients) => {
  dispatch({ type: CLIENTS_SUCCESS, payload: clients });
};

const getAllClientsError = (dispatch, error) => {
  dispatch({ type: CLIENTS_ERROR, payload: error });
};

const getClientSuccess = (dispatch, client) => {
  dispatch({ type: CLIENT_SUCCESS, payload: client });
};

const getClientError = (dispatch, error) => {
  dispatch({ type: CLIENT_ERROR, payload: error });
};

export const getAllClients = (params, { onError }) => {
  return (dispatch) => {
    dispatch({ type: LOADING_CLIENTS });

    return new Promise((resolve, reject) => {
      payrollApi
        .get(`/clients?page=${params.page}&pageSize=${params.size}`)
        .then((clients_res) => {
          const { data } = clients_res;

          console.log("[CLIENTS RES]:: ", data);
          getAllClientsSuccess(dispatch, data);

          // TODO:: Include the getAllClientsSuccess in this area
        })
        .catch((err) => {
          //** THROW SYSTEM ERROR */
          reject(err);
          onError();
          getAllClientsError(dispatch, err);
          console.log("[GET ALL CLIENTS ERROR]:: ", err);
        });
    });
  };
};

export const getClient = (id) => {
  return (dispatch) => {
    dispatch({ type: LOADING_CLIENT });

    return new Promise((resolve, reject) => {
      payrollApi
        .get(`/clients/${id}`)
        .then((client_res) => {
          const { data } = client_res;
          console.log("[CLIENT RES]:: ", data);
          // TODO:: Include the getClientsSuccess in this area
        })
        .catch((err) => {
          //** THROW SYSTEM ERROR */
          reject(err);
          console.log("[GET CLIENT ERROR]:: ", err);
        });
    });
  };
};

const clientImageSuccess = (dispatch, image) => {
  dispatch({ type: IMAGE_SUCCESS, payload: image });
};

const clientImageError = (dispatch, error) => {
  dispatch({ type: IMAGE_ERROR, payload: error });
};

const handleImageResponse = (dispatch, res) => {
  switch (res.httpStatusCode) {
    case 500:
      return clientImageError(dispatch, res);

    case 404:
      res.error = res.defaultUserMessage;
      return clientImageError(dispatch, res);

    default:
      return clientImageSuccess(dispatch, res);
  }
};

export const fetchClientImage = (id, { onError }) => {
  return (dispatch) => {
    dispatch({ type: LOADING_IMAGE });

    return new Promise((resolve, reject) => {
      payrollApi
        .get(`clients/${id}/images`)
        .then((imageRes) => {
          const { data } = imageRes;// || {};
          console.log("[IMAGE RES]:: ", data ? "Image found" : "");
          handleImageResponse(dispatch, data);
          resolve(data);
        })
        .catch((err) => {
          console.log("[WHAT BROKE]:: ", err);
          reject(err);
          clientImageError(dispatch, err);
          onError();
        });
    });
  };
};

const handleGetClientCustomFieldSuccess = (dispatch, customFields) => {
  dispatch({ type: GET_CLIENT_CUSTOM_FIELD_SUCCESS, payload: customFields });
};

const handleGetClientCustomFieldError = (dispatch, error) => {
  dispatch({ type: GET_CLIENT_CUSTOM_FIELD_ERROR, payload: error });
};

export const getClientCustomField = (id, { onError }) => {
  return (dispatch) => {
    dispatch({
      type: GET_CLIENT_CUSTOM_FIELD,
    });

    payrollApi
      .get(`clients/${id}/custom_fields`)
      .then((res) => {
        const { data } = res;
        console.log("[CUSTOM FIELDS DATA]:: ", data);
        handleGetClientCustomFieldSuccess(dispatch, data);
      })
      .catch((err) => {
        handleGetClientCustomFieldError(dispatch, err);
        onError();
      });
  };
};

const handleSearchSuccess = (dispatch, payload) => {
  dispatch({ type: SEARCH_CLIENTS_SUCCESS, payload });
};

const handleSearchError = (dispatch, error) => {
  dispatch({ type: SEARCH_CLIENTS_ERROR, payload: error });
};

export const searchClients = (name, { onSuccess, onError }) => {
  return (dispatch) => {
    dispatch({ type: SEARCH_CLIENTS });

    payrollApi
      .get(`clients?name=${name}`)
      .then((res) => {
        const { data } = res;
        console.log("[CLIENTS SEARCH]:: ", data);
        handleSearchSuccess(dispatch, data);
        onSuccess();
      })
      .catch((err) => {
        handleSearchError(dispatch, err);
        onError();
      });
  };
};

const handleGetClientIdSuccess = (dispatch, ids) => {
  dispatch({ type: CLIENT_ID_LOADED_SUCCESS, payload: ids });
};

const handleGetClientIdError = (dispatch, error) => {
  dispatch({ type: CLIENT_ID_LOADED_ERROR, payload: error });
};

export const getClientDocuments = (id) => {
  return (dispatch) => {
    dispatch({
      type: LOADING_CLIENT_IDS,
    });

    payrollApi
      .get(`clients/${id}/identifiers`)
      .then((res) => {
        const { data } = res;
        // console.log("[CLIENT_IDS]:: ", data);
        handleGetClientIdSuccess(dispatch, data);
      })
      .catch((err) => {
        handleGetClientIdError(dispatch, err);
      });
  };
};
