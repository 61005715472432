const pendingCommissions = [
  {
    agent: "Accra LEL",
    loanNumber: "PN14322",
    client: "Stephen Bright  Baayor",
    loanValue: 1547,
    commissionDue: "GHc 135.00",
    date: "26/07/2019",
    details: "more",
  },
];

exports.pendingCommissions = pendingCommissions;
