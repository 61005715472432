const modeOfEntry = [
  {
    id: 1,
    name: "Walk-In Only",
    active: 1,
    created_at: "2018-09-04 12:27:59",
    updated_at: "2018-09-04 12:27:59",
  },
  {
    id: 2,
    name: "Walk-In With Agent",
    active: 1,
    created_at: "2018-09-04 12:28:46",
    updated_at: "2018-09-04 12:28:46",
  },
  {
    id: 3,
    name: "Non Walk-In",
    active: 1,
    created_at: "2018-09-04 12:28:59",
    updated_at: "2018-09-04 12:28:59",
  },
];

exports.modeOfEntry = modeOfEntry;
