import {
  GET_ROLES,
  GET_ROLES_ERROR,
  GET_ROLES_SUCCESS
} from "../actions";

const INITIAL_STATE = {
  loading: false,
  error: false,
  data: [],
};

const RolesReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case GET_ROLES:
      return {
        ...state,
        loading: true,
        error: false,
      };

    case GET_ROLES_SUCCESS:
      return {
        ...state,
        loading: false,
        error: false,
        data: action.payload,
      };

    case GET_ROLES_ERROR:
      return {
        ...state,
        loading: false,
        error: true,
      };

    default:
      return state;
  }
};

export default RolesReducer;
