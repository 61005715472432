import payrollApi from "../api/payrollApi";
import { store } from "../store";
import {
  ADDING_USER,
  ADDING_USER_ERROR,
  ADDING_USER_SUCCESS,
  EDITING_USER,
  GET_CLIENT_CUSTOM_FIELD,
  GET_CLIENT_CUSTOM_FIELD_ERROR,
  GET_CLIENT_CUSTOM_FIELD_SUCCESS,
  GET_SINGLE_USER_ERROR,
  GET_SINGLE_USER_SUCCESS,
  GET_USERS,
  GET_USERS_ERROR,
  GET_USERS_SUCCESS,
  LOADING_SINGLE_USER,
  UPDATE_USER_ROLE_ERROR,
  UPDATE_USER_ROLE_SUCCESS,
  UPDATING_USER_ROLE,
} from "./types";

store.subscribe(listener);

let token;
function select(state) {
  if (state.auth.user) {
    return state.auth.user.token;
  }
}

function listener() {
  token = select(store.getState());
  if (token) {
    payrollApi.defaults.headers.common["Authorization"] = `Bearer ${token}`;
  }
}

const getAllUsersSuccess = (dispatch, users) => {
  dispatch({
    type: GET_USERS_SUCCESS,
    payload: users,
  });
};

const getAllUsersError = (dispatch, error) => {
  dispatch({
    type: GET_USERS_ERROR,
    payload: error,
  });
};

const getUserSuccess = (dispatch, user) => {
  dispatch({
    type: GET_SINGLE_USER_SUCCESS,
    payload: user,
  });
};

const getUserError = (dispatch, error) => {
  dispatch({
    type: GET_SINGLE_USER_ERROR,
    payload: error,
  });
};

export const getAllUsers = (params) => {
  return (dispatch) => {
    dispatch({
      type: GET_USERS,
    });

    return new Promise((resolve, reject) => {
      payrollApi
        .get(`/users?page=${params.page}&pageSize=${params.size}`)
        .then((users_res) => {
          const { data } = users_res;

          console.log("[USERS RES]:: ", data.pageItems);
          getAllUsersSuccess(dispatch, data.pageItems);
        })
        .catch((err) => {
          //** THROW SYSTEM ERROR */
          reject(err);
          getAllUsersError(dispatch, err);
          console.log("[GET ALL USERS ERROR]:: ", err);
        });
    });
  };
};

export const getUser = (id) => {
  return (dispatch) => {
    dispatch({
      type: LOADING_SINGLE_USER,
    });

    return new Promise((resolve, reject) => {
      payrollApi
        .get(`/users/${id}`)
        .then((user_res) => {
          const { data } = user_res;
          console.log("[USER RES]:: ", data);
          getUserSuccess(dispatch, data);
        })
        .catch((err) => {
          //** THROW SYSTEM ERROR */
          reject(err);
          getUserError(dispatch, err);
          console.log("[GET USER ERROR]:: ", err);
        });
    });
  };
};

const addUserSuccess = (dispatch, user) => {
  dispatch({
    type: ADDING_USER_SUCCESS,
    payload: user,
  });
};

const addUserError = (dispatch, error) => {
  dispatch({
    type: ADDING_USER_ERROR,
    payload: error,
  });
};

const updateUserRoleSuccess = (dispatch, user) => {
  dispatch({
    type: UPDATE_USER_ROLE_SUCCESS,
    payload: user,
  });
};

const updateUserRoleError = (dispatch, error) => {
  dispatch({
    type: UPDATE_USER_ROLE_ERROR,
    payload: error,
  });
};

export const addUser = (user) => {
  return (dispatch) => {
    dispatch({
      type: ADDING_USER,
    });

    return new Promise((resolve, reject) => {
      payrollApi
        .post(`/users`, user)
        .then((userRes) => {
          const { data } = userRes;
          console.log("[ADDED USER]:: ", data);

          addUserSuccess(dispatch, data);
          resolve(data);
        })
        .catch((err) => {
          reject(err);
          addUserError(dispatch, err);
        });
    });
  };
};

export const editUser = (user) => {
  return (dispatch) => {
    dispatch({
      type: EDITING_USER,
    });

    return new Promise((resolve, reject) => {
      payrollApi
        .post(`/users`, user)
        .then((userRes) => {
          const { data } = userRes;
          console.log("[EDITED USER]:: ", data);

          addUserSuccess(dispatch, data);
          resolve(data);
        })
        .catch((err) => {
          reject(err);
          addUserError(dispatch, err);
        });
    });
  };
};

export const updateUserRole = (user, role) => {
  return (dispatch) => {
    dispatch({
      type: UPDATING_USER_ROLE,
    });

    return new Promise((resolve, reject) => {
      payrollApi
        .put(`/users/${user.id}?roleName=${role}`)
        .then((res) => {
          const { data } = res;
          console.log("[UPDATING USER ROLE]:: ", data);

          updateUserRoleSuccess(dispatch, data);
          resolve(data);
        })
        .catch((err) => {
          reject(err);
          updateUserRoleError(dispatch, err);
        });
    });
  };
};
