import { Row, Col, Form, Input, Select, Card, Button } from "antd";
import { nanoid } from "nanoid";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { withRouter } from "react-router-dom";
import {
  approveCommission,
  calcCommission,
  getAgentLoans,
  getAllAgents,
} from "../actions";
import { openNotifcation } from "../components/Notification";
import "./AddNewCommission.scss";

const AddNewCommission = withRouter(({ history }) => {
  const [commForm] = Form.useForm();
  const agents = useSelector(({ agents }) => agents.agents);
  const loadingAgents = useSelector(({ agents }) => agents.loading);
  const loadingLoans = useSelector(({ agents }) => agents.loadingLoans);
  const loansError = useSelector(({ agents }) => agents.loansError);
  const loans = useSelector(({ agents }) => agents.loans);
  const approvingCommission = useSelector(
    ({ commissions }) => commissions.approvingCommission
  );
  const [agentLoan, setLoan] = useState();
  const [approvedOnDate, setDate] = useState();
  const [disableBtn, setDisableBtn] = useState(true);
  const calculating = useSelector(
    ({ commissions }) => commissions.calculatingCommission
  );
  const commissionData = useSelector(
    ({ commissions }) => commissions.calcCommission
  );

  const dispatch = useDispatch();

  const agentsError = useSelector(({ agents }) => agents.error);
  const onCalculateSuccess = () => {
    openNotifcation("success", "Agent commission calculated");
  };

  const onCalcError = () => {
    openNotifcation("error", "Error calculating agent commission");
  };

  const onCalculateClicked = () => {
    const payload = {
      agentId: commForm.getFieldValue("agent"),
      loanId: agentLoan,
    };
    dispatch(
      calcCommission(payload, {
        onSuccess: onCalculateSuccess,
        onError: onCalcError,
      })
    );
  };

  useEffect(() => {
    dispatch(getAllAgents());
  }, []);

  const setAgentLoan = (agentID) => {
    // setDisableDropdown(false);

    dispatch(getAgentLoans(agentID));
  };

  useEffect(() => {
    if (commissionData !== null) {
      commForm.setFieldsValue({ loanValue: commissionData.disbursedLoanAmt });
      commForm.setFieldsValue({
        witholdingTax: commissionData.withholdingTaxAmt,
      });
      commForm.setFieldsValue({
        amountToPay: commissionData.agentCommissionAmt,
      });
      commForm.setFieldsValue({ netDisbursalAmount: commissionData.netDisbursalAmount });
    }
  }, [commissionData]);

  useEffect(() => {
    if (
      commForm.getFieldValue("agentLoan") &&
      commForm.getFieldValue("agent") &&
      approvedOnDate
    ) {
      setDisableBtn(false);
    }
  }, [approvedOnDate, agentLoan, commForm.getFieldValue("agent")]);

  const onCreateCommissionSuccess = () => {
    openNotifcation("success", "Agent commission was created");

    setTimeout(() => {
      history.goBack();
    }, 2500);
  };

  const onCreateCommissionFail = () => {
    openNotifcation("error", "Error creating agent commission");
  };

  const onCreateCommissionClicked = () => {
    const payload = {
      agentId: commForm.getFieldValue("agent"),
      loanId: agentLoan,
      approvedOn: approvedOnDate,
    };
    dispatch(
      approveCommission(payload, {
        onSuccess: onCreateCommissionSuccess,
        onError: onCreateCommissionFail,
      })
    );
  };

  return (
    <div className="tabled addNewCommission">
      <Col xs="24" xl={24}>
        <Card
          bordered={false}
          className="circlebox tablespace mb-24 addLoan__card"
          title="Add New Commission"
        >
          <Row gutter={[24, 0]} className="addNewCommission__row">
            <Col>
              <Form layout="vertical" className="row-col" form={commForm}>
                <div className="addNewCommission__row">
                  <Form.Item
                    className="addNewCommission__input"
                    label="Agent"
                    name="agent"
                    rules={[
                      { required: true, message: "Please select an agent" },
                    ]}
                  >
                    <Form.Item name="agent">
                      <Select
                        onChange={(evt) => setAgentLoan(evt)}
                        loading={loadingAgents}
                        showSearch
                        allowClear
                        filterOption={(input, option) => option.children.join('').toLowerCase().includes(input.toLowerCase())}
                      >
                        {agents.map((agent) => (
                          <Select.Option
                            value={agent.id}
                            key={`agent-${nanoid()}`}
                          >
                            {agent.firstname} {agent.lastname}
                          </Select.Option>
                        ))}
                      </Select>
                    </Form.Item>
                  </Form.Item>

                  <Form.Item
                    label="Agent Loan"
                    name="agentLoan"
                    className="addNewCommission__input"
                    rules={[
                      {
                        required: true,
                        message: "Please select the agents affliated loan",
                      },
                    ]}
                  >
                    <Form.Item name="agentLoan">
                      <Select
                        onSelect={(evt) => setLoan(evt)}
                        loading={loadingLoans}
                      >
                        {loans.map((loan) => (
                          <Select.Option
                            key={`agent-loan-${nanoid()}`}
                            value={loan.loanId}
                          >
                            {loan.clientName}-{loan.loanId}
                          </Select.Option>
                        ))}
                      </Select>
                    </Form.Item>
                  </Form.Item>

                  <Form.Item
                    className="addNewCommission__input"
                    label="Approved On"
                    name="approvedOn"
                  >
                    <Form.Item name="approvedOn">
                      <Input
                        type="date"
                        onChange={(evt) => setDate(evt.target.value)}
                      />
                    </Form.Item>
                  </Form.Item>

                  {calculating ? (
                    <Button
                      disabled
                      type="default"
                      className="addNewCommission__row--btn"
                    >
                      Calculating Agent Commission...
                    </Button>
                  ) : (
                    <Button
                      onClick={() => onCalculateClicked()}
                      disabled={disableBtn}
                      type="default"
                      className="addNewCommission__row--btn"
                    >
                      Calculate Agent Commission
                    </Button>
                  )}
                </div>

                {commissionData && (
                  <div className="addNewCommission__row fadeIn">
                    <Form.Item
                      className="addNewCommission__input"
                      name="loanValue"
                      label="Loan Value"
                    >
                      <Form.Item name="loanValue">
                        <Input type="number" disabled />
                      </Form.Item>
                    </Form.Item>

                    <Form.Item
                      className="addNewCommission__input"
                      name="netDisbursalAmount"
                      label="Net Disbursed Amount"
                    >
                      <Form.Item name="netDisbursalAmount">
                        <Input type="number" disabled />
                      </Form.Item>
                    </Form.Item>

                    <Form.Item
                      label="Witholding Tax"
                      name="witholdingTax"
                      className="addNewCommission__input"
                    >
                      <Form.Item name="witholdingTax">
                        <Input type="number" disabled />
                      </Form.Item>
                    </Form.Item>

                    <Form.Item
                      label="Agent Commission"
                      name="amountToPay"
                      className="addNewCommission__input"
                    >
                      <Form.Item name="amountToPay">
                        <Input type="number" disabled />
                      </Form.Item>
                    </Form.Item>

                    {approvingCommission ? (
                      <Button
                        onClick={() => onCreateCommissionClicked()}
                        type="primary"
                        disabled
                        className="addNewCommission__row--btn"
                      >
                        Creating Commission...
                      </Button>
                    ) : (
                      <Button
                        onClick={() => onCreateCommissionClicked()}
                        type="primary"
                        className="addNewCommission__row--btn"
                      >
                        Create Commission
                      </Button>
                    )}
                  </div>
                )}
              </Form>
            </Col>
          </Row>
        </Card>
      </Col>
    </div>
  );
});

export default AddNewCommission;
