import React, { useEffect } from "react";
import { Link, withRouter } from "react-router-dom";
import { Layout, Button, Row, Col, Typography, Form, Input } from "antd";

import "./SignIn.scss";
import { useSelector, useDispatch } from "react-redux";
import { emailChanged, passwordChanged, login, logOut } from "../actions";
import { openNotifcation } from "../components/Notification";
import payrollApi from "../api/payrollApi";

const { Title } = Typography;
const { Header, Content } = Layout;

const SignIn = withRouter(({ history }) => {
  const loading = useSelector(({ auth }) => auth.loggingIn);
  const errorMsg = useSelector(({ auth }) => auth.errorMsg);
  const [form] = Form.useForm();

  const dispatch = useDispatch();

  useEffect(() => {
    if (localStorage.getItem("user")) {
      localStorage.clear();
      delete payrollApi.defaults.headers.common["Authorization"];
    }
  }, []);

  const onLoginSucess = () => {
    history.replace("/clients");

    console.log("[I WAS REACHED!!!]");
  };

  const onError = () => {
    openNotifcation("error", "Login Error", errorMsg);
  };

  const onFinish = (values) => {
    dispatch(login(values, { onSuccess: onLoginSucess, onError: onError }));
  };

  const onFinishFailed = (errorInfo) => {
    console.log("Failed:", errorInfo);
  };

  const onEmailChanged = (value) => {
    dispatch(emailChanged(value));
  };

  const onPasswordChanged = (value) => {
    dispatch(passwordChanged(value));
  };
  return (
    <>
      <Layout className="layout-default layout-signin signin">
        <Header>
          <div className="header-col header-brand">
            <h5>Link Exchange Payroll</h5>
          </div>
        </Header>
        <Content className="signin">
          <Row gutter={[24, 0]} justify="space-around">
            <Col
              xs={{ span: 24, offset: 0 }}
              lg={{ span: 6, offset: 2 }}
              md={{ span: 12 }}
            >
              <Title className="mb-15">Sign In</Title>

              <Form
                // onSubmit={onSubmit}
                onFinish={onFinish}
                onFinishFailed={onFinishFailed}
                layout="vertical"
                className="row-col"
                form={form}
              >
                <Form.Item
                  className="username"
                  label="Email"
                  name="email"
                  rules={[
                    {
                      required: true,
                      message: "Please input your email!",
                    },
                  ]}
                >
                  <Form.Item name="email">
                    <Input
                      onChange={(evt) => onEmailChanged(evt.target.value)}
                      placeholder="Email"
                    />
                  </Form.Item>
                </Form.Item>

                <Form.Item
                  className="username"
                  label="Password"
                  name="password"
                  rules={[
                    {
                      required: true,
                      message: "Please input your password!",
                    },
                  ]}
                >
                  <Form.Item name="password">
                    <Input
                      type="password"
                      onChange={(evt) => onPasswordChanged(evt.target.value)}
                      placeholder="Password"
                    />
                  </Form.Item>
                </Form.Item>

                {loading ? (
                  <Form.Item shouldUpdate>
                    {() => (
                      <Button
                        type="primary"
                        htmlType="submit"
                        style={{ width: "100%" }}
                        role="button"
                        disabled
                      >
                        Logging in...
                      </Button>
                    )}
                  </Form.Item>
                ) : (
                  <Form.Item shouldUpdate>
                    {() => (
                      <Button
                        type="primary"
                        htmlType="submit"
                        style={{ width: "100%" }}
                        role="button"
                        // disabled
                        disabled={
                          !form.isFieldsTouched(true) ||
                          form
                            .getFieldsError()
                            .filter(({ errors }) => errors.length).length > 0
                        }
                      >
                        Login
                      </Button>
                    )}
                  </Form.Item>
                )}

                <p className="font-semibold text-muted">
                  Don't have an account?{" "}
                  <Link to="/sign-up" className="text-dark font-bold">
                    Sign Up
                  </Link>
                </p>
              </Form>
            </Col>
            <Col
              className="sign-img"
              style={{ padding: 12 }}
              xs={{ span: 24 }}
              lg={{ span: 12 }}
              md={{ span: 12 }}
            >
              <div className="signin__bg"></div>
            </Col>
          </Row>
        </Content>
      </Layout>
    </>
  );
});

export default SignIn;
