import React, { useState, useEffect } from "react";
import { withRouter } from "react-router-dom";
import {
  Row,
  Col,
  Card,
  Progress,
  Button,
  Typography,
  Form,
  Input,
  Select,
  notification,
  Tooltip,
  Skeleton,
} from "antd";
import "./AddLoan.scss";
import {
  PlusOutlined,
  CalculatorOutlined,
  CloseCircleOutlined,
  DeleteOutlined,
} from "@ant-design/icons";

import { dataHubs } from "../data/payroll_data_hubs";
import { loanPurposes } from "../data/payroll_loan_purpose";
import { modeOfEntry } from "../data/payroll_mode_of_entry";
import { useDispatch, useSelector } from "react-redux";
import {
  calculateAllowance,
  getAllAgents,
  getAllLoanProducts,
  getAllowances,
  addLoan,
} from "../actions";
import moment from "moment";
import { DATE_FORMAT } from "../constants";

const { Title } = Typography;

const AddLoan = withRouter(({ history }) => {
  const [generalInfo, setGeneralInfo] = useState(null);
  const [salaryInfo, setSalaryInfo] = useState(null);
  const [kinInfo, setKinInfo] = useState(null);
  const [otherInfo, setOtherInfo] = useState(null);
  const [activeIndex, setActiveIndex] = useState(0); // -> set back to 0 when complete
  const [title, setTitle] = useState("General Information"); // -> -> set back to General Information when complete
  const [fields, setFields] = useState([]);
  const [salaryForm] = Form.useForm();
  const [basicForm] = Form.useForm();

  const [clientId, setClientID] = useState(history.location.state.clientID);

  const loanProducts = useSelector(
    ({ loanProducts }) => loanProducts.loanProducts
  );
  const loadingLoanProducts = useSelector(
    ({ loanProducts }) => loanProducts.loading
  );
  const loanProductsError = useSelector(
    ({ loanProducts }) => loanProducts.error
  );

  const loanProductsErrorMsg = useSelector(
    ({ loanProducts }) => loanProducts.errorMsg
  );

  const agents = useSelector(({ agents }) => agents.agents);
  const loadingAgents = useSelector(({ agents }) => agents.loading);
  const agentsError = useSelector(({ agents }) => agents.error);

  const allowances = useSelector(({ allowances }) => allowances.allowances);
  const loadingAllowances = useSelector(({ allowances }) => allowances.loading);
  const allowancesError = useSelector(({ allowances }) => allowances.error);

  const calculating = useSelector(
    ({ loans }) => loans.calculating_affordability
  );
  const affordability_error = useSelector(
    ({ loans }) => loans.error_calculating_affordability
  );
  const affordability = useSelector(({ loans }) => loans.affordability);

  //** ADDED LOAN */
  const addingLoan = useSelector(({ loans }) => loans.adding_loan);
  const addedLoan = useSelector(({ loans }) => loans.added_loan);
  const addLoanError = useSelector(({ loans }) => loans.error_adding_loan);

  const dispatch = useDispatch();

  const fetchAllDropDowns = () => {
    dispatch(getAllowances());
    dispatch(getAllAgents());
    dispatch(getAllLoanProducts(clientId));
  };

  useEffect(() => {
    fetchAllDropDowns();
    basicForm.setFieldsValue({ clientID: clientId });
  }, []);

  const idTypes = [
    "GES",
    "Driver's License",
    "National ID",
    "NHIS",
    "Passport",
    "Voter's ID",
    "SSNIT",
  ];

  // useEffect(() => {
  //   const field = {
  //     allowance: "",
  //     amount: "",
  //     permanent: "",
  //   };

  //   let fields = [];
  //   fields.push(field);
  //   setFields(fields);
  // }, []);

  const nextForm = () => {
    setActiveIndex(activeIndex + 1);
  };

  const prevForm = () => {
    setActiveIndex(activeIndex - 1);
  };

  const onGeneralInfoFinish = (values) => {
    const loanProduct = loanProducts.filter(
      (loanProduct) => loanProduct.id === values.loanProduct
    )[0];

    values["loanTermFrequency"] = loanProduct.tenure;
    values["interestRatePerPeriod"] = loanProduct.interest;

    setGeneralInfo(values);
    setTitle("Salary Information");
    nextForm();

    console.log("[GENERAL VALUES]:: ", generalInfo);
  };

  const onGeneralInfoError = (err) => {
    console.log("[GENERAL INFO ERROR]:: ", err);
  };

  const onSalaryInfoFinish = (values) => {
    setSalaryInfo(values);
    setTitle("Next of Kin");
    nextForm();
  };

  const onNextOfKinFinish = (values) => {
    setKinInfo(values);
    setTitle("Other Information");
    nextForm();
  };

  const openNotifcation = (type, message, description) => {
    notification[type]({
      message,
      description,
    });
  };

  const onLoanProductSelected = (e) => {
    let selectedProd = loanProducts.find(prod => prod.id == e);
    setProdInterestRate(selectedProd.interest);
  };

  const [prodInterestRate, setProdInterestRate] = useState();

  const onAllFormsComplete = (values) => {
    const form = {
      ...values,
      ...generalInfo,
      ...salaryInfo,
      ...kinInfo,
    };

    console.log("[ADD LOAN VALUES]:: ", form);

    let allowancesName = [];

    fields.map((field) => {
      allowancesName.push(field.name);
    });

    // Loan term frequency = Loan product tenure
    // Number of repayments = Loan product tenure
    // Interest Rate = Loan Product interest rate

    // Loan Deductions not on payslip = Reserved Affordability
    // affordability = amd
    // total deducations =
    // authorityNoteNumber =
    // Total Deductions = allowableMoth * loan product tenure
    const loanInfo = {
      clientId: form.clientID,
      principal: form.amountrequested,
      loanTermFrequency: form.loanTermFrequency,
      numberOfRepayments: form.loanTermFrequency,
      interestRatePerPeriod: form.interestRatePerPeriod,
      expectedDisbursementDate: moment(form.loanDate).format(DATE_FORMAT),
      submittedOnDate: moment(form.loanDate).format(DATE_FORMAT),
      datatables: [
        {
          basicSalary: form.basicSalary,
          grossSalary: form.grossSalary,
          netSalary: form.netSalary,
          thirdPartyDeductions: form.thirdPartyCont,
          socialSecuritySSF: form.socialSecurity,
          purposeOfLoan: form.loanPurpose,
          dataHubPayrollLoans: form.dataHub,
          tax: form.tax,
          creditOfficersNotes: form.officerNotes,
          authorityNoteNumber: form.authorityNote,
          allowances: allowancesName.join(),
          loanDeductionsNotOnPayslip: form.reservedAffordability,
          affordability: form.allowableMonthDeduction,
          totalDeductions:
            form.allowableMonthDeduction * form.loanTermFrequency,
          allowanceDetails: [...fields],

        },
      ],
      agentId: form.agent,
      //processingFee: form.processingFee
    };

    console.log("[COMPLETE PAYLOAD]:: ", loanInfo);

    // dispatch(addLoan(loanInfo, routeToLoans, viewLoanDetails));
   dispatch(addLoan(loanInfo, routeToLoans));

    // openNotifcation(
    //   "success",
    //   "New Loan Added",
    //   "Loan-4312 has been successfully added to the client"
    // );
  };

  const routeToLoans = () => {
    history.push("/loans");
  };

  const viewLoanDetails = (id) => {
    history.push(`/loan/${id}`, { from: 'createLoan' });
  };

  //** Change Allowance */
  const changeAllowance = (value, i) => {
    const allowance = filterAllowance(value);
    fields[i]["name"] = allowance.name;
    fields[i]["permanent"] = allowance.permanent;
    console.log("[_ADDED FIELD]:: ", fields);
  };

  const filterAllowance = (name) => {
    return allowances.find((allowance) => allowance.name === name);
  };

  //** Add allowance field  */
  const addAllowance = () => {
    const field = {
      name: "",
      permanent: "",
      amount: "",
    };

    setFields([...fields, field]);
    console.log("[UPDATED FIELDS]:: ", fields);
  };

  const deleteField = (i) => {
    fields.splice(i, 1);

    setFields([...fields]);
  };

  //** Calculate Affordability */
  const onCalculateClicked = () => {
    // const values = salaryForm.getFieldsValue(true);
    const affordabilityPayload = {

      basicSalary: salaryForm.getFieldValue("basicSalary"),
      tax: salaryForm.getFieldValue("tax"),
      ssf: salaryForm.getFieldValue("socialSecurity"),
      thirdParty: salaryForm.getFieldValue("thirdPartyCont"),
      reservedAfford: salaryForm.getFieldValue("reservedAffordability"),
      allowances: [...fields],
    };

    dispatch(calculateAllowance(affordabilityPayload)).then((afford) => {
      salaryForm.setFieldsValue({ grossSalary: afford.grossSalary });
      salaryForm.setFieldsValue({ netSalary: afford.netSalary });
      salaryForm.setFieldsValue({ allowableMonthDeduction: afford.amd });
    });
    console.log("[SALARY FORM PAYLOAD]:: ", affordabilityPayload);
  };

  //** Switch case to return fields to enter based on status */
  const returnFields = (index) => {
    switch (index) {
      case 0:
        return (
          <Row gutter={[24, 0]} justify="space-between">
            <Col>
              <Form
                onFinish={onGeneralInfoFinish}
                onFinishFailed={onGeneralInfoError}
                layout="vertical"
                className="row-col"
                form={basicForm}
              >
                <Row>
                  <Form.Item
                    label="Client ID"
                    name="clientID"
                    className="addLoan__card--form__input"
                    rules={[
                      { required: true, message: "Please provide client ID!" },
                    ]}
                  >
                    <Form.Item name="clientID">
                      <Input disabled value={clientId} placeholder="00234234" />
                    </Form.Item>
                  </Form.Item>

                  <Form.Item
                    className="addLoan__card--form__input"
                    label="Amount requested (PF inclusive)"
                    name="amountrequested"
                    rules={[
                      { required: true, message: "Please provide loan amount!" },
                    ]}
                  >
                    <Form.Item name="amountrequested">
                      <Input type="number" />
                    </Form.Item>
                  </Form.Item>

                  {/* <Form.Item
                    className="addLoan__card--form__input"
                    label="Processing Fee"
                    name="processingFee"
                    rules={[
                      { required: true, message: "Please provide processing fee!" },
                    ]}
                  >
                    <Form.Item name="processingFee">
                      <Input type="number" />
                    </Form.Item>
                  </Form.Item> */}

                  <Form.Item
                    className="addLoan__card--form__input"
                    label="Loan Date"
                    name="loanDate"
                  >
                    <Form.Item name="loanDate">
                      <Input type="date" />
                    </Form.Item>
                  </Form.Item>
                </Row>

                <Row>
                  <Form.Item
                    className="addLoan__card--form__input "
                    label="Loan Product"
                    name="loanProduct"
                    rules={[
                      {
                        required: true,
                        message:
                          "Please provide loan product",
                      },
                    ]}
                  >
                    <Form.Item name="loanProduct">
                      <Select
                        className="addLoan__card--form__select"
                        loading={loadingLoanProducts}
                        onSelect={(evt) => onLoanProductSelected(evt)}
                      >
                        {loanProducts.map((product, i) => {
                          return (
                            <Select.Option key={i} value={product.id}>
                              {product.name}
                            </Select.Option>
                          );
                        })}
                      </Select>
                    </Form.Item>
                  </Form.Item>

                  <Form.Item
                    label="Interest Rate"
                    name="interestRate"
                    className="addLoan__card--form__input"
                  >
                    <Form.Item name="interestRate">
                      <Input placeholder={prodInterestRate} disabled />
                    </Form.Item>
                  </Form.Item>

                  <Form.Item
                    className="addLoan__card--form__input "
                    label="Mode of Entry"
                    name="modeOfEntry"
                    rules={[
                      {
                        required: true,
                        message: "Please provide mode of entry",
                      },
                    ]}
                  >
                    <Form.Item name="modeOfEntry">
                      <Select className="addLoan__card--form__select">
                        {modeOfEntry.map((moe) => (
                          <Select.Option key={moe.id} value={`${moe.name}`}>
                            {moe.name}
                          </Select.Option>
                        ))}
                      </Select>
                    </Form.Item>
                  </Form.Item>

                  <Form.Item
                    className="addLoan__card--form__input "
                    label="Select Agent"
                    name="agent"
                  >
                    <Form.Item name="agent">
                      <Select
                        className="addLoan__card--form__select"
                        loading={loadingAgents}
                        showSearch
                        allowClear
                        filterOption={(input, option) => option.children.join('').toLowerCase().includes(input.toLowerCase())}
                      >
                        {agents.map((agent, i) => (
                            <Select.Option key={i} value={`${agent.id}`}>
                            {agent.firstname} {agent.lastname}
                          </Select.Option>
                        ))}
                      </Select>
                    </Form.Item>
                  </Form.Item>
                </Row>

                <Row>
                  <Form.Item
                    label="Authority Note No."
                    name="authorityNote"
                    className="addLoan__card--form__input"
                    rules={[
                      {
                        required: true,
                        message: "Please provide the authority note number",
                      },
                    ]}
                  >
                    <Form.Item name="authorityNote">
                      <Input />
                    </Form.Item>
                  </Form.Item>

                  <Form.Item
                    className="addLoan__card--form__input"
                    label="Loan Advance No."
                    name="loanAdvance"
                    rules={[
                      {
                        required: true,
                        message: "Please provide the loan advance number",
                      },
                    ]}
                  >
                    <Form.Item name="loanAdvance">
                      <Input />
                    </Form.Item>
                  </Form.Item>

                  <Form.Item
                    className="addLoan__card--form__input "
                    label="Loan Purpose"
                    name="loanPurpose"
                    rules={[
                      {
                        required: true,
                        message: "Please provide the loan purpose",
                      },
                    ]}
                  >
                    <Form.Item name="loanPurpose">
                      <Select className="addLoan__card--form__select">
                        {loanPurposes.map((purpose) => (
                          <Select.Option
                            key={purpose.id}
                            value={`${purpose.name}`}
                          >
                            {purpose.name}
                          </Select.Option>
                        ))}
                      </Select>
                    </Form.Item>
                  </Form.Item>

                  <Form.Item
                    className="addLoan__card--form__input"
                    label="Loan Detail Purpose"
                    name="loanDetailPurpose"
                    rules={[
                      {
                        required: true,
                        message: "Please provide the purpose of the loan",
                      },
                    ]}
                  >
                    <Form.Item name="loanDetailPurpose">
                      <Input />
                    </Form.Item>
                  </Form.Item>
                </Row>

                <Row>
                  <Form.Item
                    className="addLoan__card--form__input"
                    label="Relationship Manager"
                    name="relationshipManager"
                  >
                    <Form.Item name="relationshipManager">
                      <Input />
                    </Form.Item>
                  </Form.Item>

                  <Form.Item
                    className="addLoan__card--form__input "
                    label="Data Hub"
                    name="dataHub"
                  >
                    <Form.Item name="dataHub">
                      <Select className="addLoan__card--form__select">
                        {dataHubs.map((hub) => (
                          <Select.Option key={hub.id} value={`${hub.name}`}>
                            {hub.name}
                          </Select.Option>
                        ))}
                      </Select>
                    </Form.Item>
                  </Form.Item>
                </Row>

                <div className="addLoan__card--form__btnRow">
                  <Button style={{ display: "hidden" }}></Button>

                  <Button htmlType="submit" type="primary">
                    Next
                  </Button>
                </div>
              </Form>
            </Col>

            <Col></Col>
          </Row>
        );

      case 1:
        return (
          <Col>
            <Form
              onFinish={onSalaryInfoFinish}
              className="row-col"
              layout="vertical"
              form={salaryForm}
            >
              <Row>
                <Form.Item
                  label="Basic Salary"
                  name="basicSalary"
                  className="addLoan__card--form__input"
                  rules={[
                    {
                      required: true,
                      message: "Please provide the basic salary amount",
                    },
                  ]}
                >
                  <Form.Item name="basicSalary">
                    <Input type="number" />
                  </Form.Item>
                </Form.Item>

                <Form.Item
                  label="Gross Salary"
                  name="grossSalary"
                  className="addLoan__card--form__input"
                  rules={[
                    {
                      required: true,
                      message: "Please provide the basic salary amount",
                    },
                  ]}
                >
                  <Form.Item name="grossSalary">
                    <Input disabled type="number" />
                  </Form.Item>
                </Form.Item>

                <Form.Item
                  label="Net Salary"
                  name="netSalary"
                  className="addLoan__card--form__input"
                  rules={[
                    {
                      required: true,
                      message: "Please provide the basic salary amount",
                    },
                  ]}
                >
                  <Form.Item name="netSalary">
                    <Input disabled type="number" />
                  </Form.Item>
                </Form.Item>

                <Form.Item
                  label="Allowable Monthly Deduction"
                  name="allowableMonthDeduction"
                  className="addLoan__card--form__input"
                  rules={[
                    {
                      required: true,
                      message: "Please provide the basic salary amount",
                    },
                  ]}
                >
                  <Form.Item name="allowableMonthDeduction">
                    <Input disabled type="number" />
                  </Form.Item>
                </Form.Item>
              </Row>

              <Row>
                <Form.Item
                  label="Social Security"
                  name="socialSecurity"
                  className="addLoan__card--form__input"
                  rules={[
                    {
                      required: true,
                      message: "Please provide the social security number",
                    },
                  ]}
                >
                  <Form.Item name="socialSecurity">
                    <Input type="number" />
                  </Form.Item>
                </Form.Item>

                <Form.Item
                  label="Tax"
                  name="tax"
                  className="addLoan__card--form__input"
                  rules={[
                    {
                      required: true,
                      message: "Please provide the taxable amount",
                    },
                  ]}
                >
                  <Form.Item name="tax">
                    <Input type="number" />
                  </Form.Item>
                </Form.Item>

                <Form.Item
                  label="Third Party Contribution"
                  name="thirdPartyCont"
                  className="addLoan__card--form__input"
                  rules={[
                    {
                      required: true,
                      message:
                        "Please provide the third party contribution amount",
                    },
                  ]}
                >
                  <Form.Item name="thirdPartyCont">
                    <Input type="number" />
                  </Form.Item>
                </Form.Item>

                <Form.Item
                  label="Reserved Afford. "
                  name="reservedAffordability"
                  className="addLoan__card--form__input"
                  rules={[
                    {
                      required: true,
                      message:
                        "Please provide the reserved affordability amount",
                    },
                  ]}
                >
                  <Form.Item name="reservedAffordability">
                    <Input type="number" />
                  </Form.Item>
                </Form.Item>
              </Row>

              {/* TODO:: MIGHT NEED TO ADD THE ALLOWANCE INFORMATION HERE  */}
              {/* Horizontal LINE */}
              <div className="addLoan__card--form__allowanceRow">
                <h3 className="addLoan__card--form__allowanceRow--title">
                  Allowances
                </h3>

                <div className="addLoan__card--form__allowanceRow--btn">
                  <Button
                    onClick={() => addAllowance()}
                    icon={<PlusOutlined />}
                  >
                    Add Allowance
                  </Button>
                  {calculating ? (
                    <Button
                      disabled
                      onClick={() => onCalculateClicked()}
                      icon={<CalculatorOutlined />}
                    >
                      Calculating...
                    </Button>
                  ) : (
                    <Button
                      onClick={() => onCalculateClicked()}
                      icon={<CalculatorOutlined />}
                    >
                      Calculate
                    </Button>
                  )}
                </div>
              </div>
              <div className="addLoan__card--form__line"></div>

              <Row>
                {fields.map((field, i) => (
                  <Col
                    className="addLoan__card--form__allowances"
                    key={`allowances-${i}`}
                  >
                    {loadingAllowances ? (
                      <Skeleton paragraph={false} rows={1} />
                    ) : (
                      <select
                        onChange={(e) => changeAllowance(e.target.value, i)}
                        className="addLoan__card--form__custom_select"
                      >
                        {allowances.map((allowance, j) => (
                          <option key={j} value={allowance.name}>
                            {allowance.name}
                          </option>
                        ))}
                      </select>
                    )}

                    <Input
                      // value={field.allowanceAmount}
                      onChange={(evt) => (field.amount = evt.target.value)}
                      type="number"
                    />

                    <Tooltip title="Delete allowance field">
                      <Button
                        icon={<DeleteOutlined />}
                        size="middle"
                        onClick={() => deleteField(i)}
                        // type="danger"
                        danger
                        shape="round"
                        className="addLoan__card--form__allowances--del"
                      ></Button>
                    </Tooltip>
                  </Col>
                ))}
              </Row>

              <div className="addLoan__card--form__btnRow">
                <Button
                  onClick={() => {
                    prevForm();
                    setTitle("General Information");
                  }}
                  type="ghost"
                >
                  Previous
                </Button>

                <Button htmlType="submit" type="primary">
                  Next
                </Button>
              </div>
            </Form>
          </Col>
        );

      case 2:
        return (
          <Col>
            <Form
              onFinish={onNextOfKinFinish}
              className="row-col"
              layout="vertical"
            >
              <Row>
                <Form.Item
                  label="Surname"
                  name="surname"
                  className="addLoan__card--form__input"
                  rules={[
                    {
                      required: true,
                      message: "Please provide the surname of next of kin",
                    },
                  ]}
                >
                  <Form.Item name="surname">
                    <Input type="text" />
                  </Form.Item>
                </Form.Item>

                <Form.Item
                  label="Other Names"
                  name="otherNames"
                  className="addLoan__card--form__input"
                  rules={[
                    {
                      required: true,
                      message:
                        "Please provide any other name(s) of next of kin",
                    },
                  ]}
                >
                  <Form.Item name="otherNames">
                    <Input type="text" />
                  </Form.Item>
                </Form.Item>

                <Form.Item
                  label="Telephone"
                  name="phone"
                  className="addLoan__card--form__input"
                  rules={[
                    {
                      required: true,
                      message: "Please provide the phone number of next of kin",
                    },
                  ]}
                >
                  <Form.Item name="phone">
                    <Input type="tel" />
                  </Form.Item>
                </Form.Item>
              </Row>
              <Row>
                <Form.Item
                  label="Email"
                  name="email"
                  className="addLoan__card--form__input"
                >
                  <Form.Item name="email">
                    <Input type="email" />
                  </Form.Item>
                </Form.Item>

                <Form.Item
                  label="Address"
                  name="address"
                  className="addLoan__card--form__input"
                >
                  <Form.Item name="address">
                    <Input type="text" />
                  </Form.Item>
                </Form.Item>

                <Form.Item
                  label="City"
                  name="city"
                  className="addLoan__card--form__input"
                >
                  <Form.Item name="city">
                    <Input type="text" />
                  </Form.Item>
                </Form.Item>
              </Row>

              <Row>
                <Form.Item
                  label="Landmark"
                  name="landmark"
                  className="addLoan__card--form__input"
                >
                  <Form.Item name="landmark">
                    <Input type="text" />
                  </Form.Item>
                </Form.Item>

                <Form.Item
                  label="Primary ID Type"
                  name="idType"
                  className="addLoan__card--form__input"
                >
                  <Form.Item name="idType">
                    <Select className="addLoan__card--form__select">
                      {idTypes.map((type, i) => (
                        <Select.Option key={`idtype-${i}`} value={`${type}`}>
                          {type}
                        </Select.Option>
                      ))}
                    </Select>
                  </Form.Item>
                </Form.Item>

                <Form.Item
                  label="Primary ID Number"
                  name="idNumber"
                  className="addLoan__card--form__input"
                >
                  <Form.Item name="idNumber">
                    <Input />
                  </Form.Item>
                </Form.Item>
              </Row>

              <Row>
                <Form.Item
                  className="addLoan__card--form__input"
                  label="Birth Date"
                  name="birthDate"
                >
                  <Form.Item name="birthDate">
                    <Input type="date" />
                  </Form.Item>
                </Form.Item>

                <Form.Item
                  className="addLoan__card--form__input"
                  label="Photo"
                  name="photo"
                >
                  <Form.Item name="photo">
                    <Input type="file" />
                  </Form.Item>
                </Form.Item>
              </Row>

              <div className="addLoan__card--form__btnRow">
                <Button
                  onClick={() => {
                    prevForm();
                    setTitle("Salary Information");
                  }}
                  type="ghost"
                >
                  Previous
                </Button>

                <Button htmlType="submit" type="primary">
                  Next
                </Button>
              </div>
            </Form>
          </Col>
        );

      case 3:
        return (
          <Form
            onFinish={onAllFormsComplete}
            className="row-col"
            layout="vertical"
          >
            <Row>
              <Form.Item
                label="Credit Officer Notes"
                name="officerNotes"
                className="addLoan__card--form__input"
              >
                <Form.Item name="officerNotes">
                  <Input type="text" />
                </Form.Item>
              </Form.Item>

              <Form.Item
                label="Supporting Document"
                name="supportingDocument"
                className="addLoan__card--form__input"
              >
                <Form.Item name="supportingDocument">
                  <Input type="file" />
                </Form.Item>
              </Form.Item>

              <Form.Item
                label="Description of Document"
                name="documentDescription"
                className="addLoan__card--form__input"
              >
                <Form.Item name="documentDescription">
                  <Input type="text" />
                </Form.Item>
              </Form.Item>

              <div className="addLoan__card--form__btnRow">
                <Button
                  onClick={() => {
                    prevForm();
                    setTitle("Next of Kin");
                  }}
                  type="ghost"
                >
                  Previous
                </Button>

                {addingLoan ? (
                  <Button disabled htmlType="submit" type="primary">
                    Adding Loan...
                  </Button>
                ) : (
                  <Button htmlType="submit" type="primary">
                    Add Loan
                  </Button>
                )}
              </div>
            </Row>
          </Form>
        );
      default:
        break;
    }
  };

  return (
    <div className="tabled addLoan">
      <Row gutter={[24, 0]}>
        <Col xs="24" xl={24}>
          <Card
            bordered={false}
            className="circlebox tablespace mb-24 addLoan__card"
            title={title}
          >
            <Progress showInfo={false} percent={(activeIndex / 4) * 100} />

            {/* TODO:: CONVERT THIS INTO A FUNCTION THAT TAKES A PARAMETER AND A SWITCH STATEMENT TO RENDER THE CLIENT FORMS */}
            {/* LOOP THROUGH THE FIELDS TO SHOW  */}
            {returnFields(activeIndex)}
          </Card>
        </Col>

        {loanProductsError &&
          openNotifcation(
            "error",
            "Loan Products Error",
            `${loanProductsErrorMsg}`
          )}
        {agentsError &&
          openNotifcation(
            "error",
            "Agents Error",
            "Oops, error occurred fetching agents"
          )}
        {allowancesError &&
          openNotifcation(
            "error",
            "Allowances Error",
            "Oops, something seems to be wrong. Please refresh the page, or contact the system admnistrator"
          )}
        {affordability_error &&
          openNotifcation(
            "error",
            "Error calculating allowance",
            "Please check all fields provided and try again"
          )}

        {addedLoan &&
          openNotifcation(
            "success",
            "New Loan Added",
            `${addedLoan.loanId} has been successfully added to the client`
          )}

        {addLoanError &&
          openNotifcation(
            "error",
            "Error adding loan",
            "Please check all fields provided and try again"
          )}
      </Row>
    </div>
  );
});

export default AddLoan;
