/*!
=========================================================
* Muse Ant Design Dashboard - v1.0.0
=========================================================
* Product Page: https://www.creative-tim.com/product/muse-ant-design-dashboard
* Copyright 2021 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/muse-ant-design-dashboard/blob/main/LICENSE.md)
* Coded by Creative Tim
=========================================================
* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/
import {
  Row,
  Col,
  Card,
  Table,
  Upload,
  message,
  Button,
  Typography,
  Input,
  Spin,
  DatePicker
} from "antd";

import { withRouter } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { getReports, getDownloadReportData, LOADING_REPORTS } from "../actions";
import Rocket from "./../assets/images/rocket-white.png";
import { useEffect, useState } from "react";
import moment from "moment";
import "./Loans.scss";
import "./Repayments.scss";
import "./Reports.scss";

import * as FileSaver from 'file-saver';
import * as XLSX from 'xlsx';

import { openNotifcation } from "../components/Notification";
import { nanoid } from "nanoid";

const { Title } = Typography;

// project table start
const columns = [
  {
    title: "Employee Name",
    dataIndex: "employeeName",
    key: "employeeName",
  },
  {
    title: "Employee Number",
    dataIndex: "employeeNumber",
    key: "employeeNumber",
  },
  {
    title: "Phone Number",
    dataIndex: "phone",
    key: "phone",
  },
  {
    title: "Deductions",
    dataIndex: "deductions",
    key: "deductions",
  },
  {
    title: "Expected Amount",
    dataIndex: "expectedAmount",
    key: "expectedAmount",
  },
  {
    title: "Matching Loans",
    dataIndex: "matchingLoans",
    key: "matchingLoans",
  },
  {
    title: "Errors",
    dataIndex: "errors",
    key: "errors",
  },
  {
    title: "Remarks",
    dataIndex: "remarks",
    key: "remarks",
  },
];

const Reports = withRouter(({ history }) => {
  const [date, setDate] = useState();
  const repayments = useSelector(({ repayments }) => repayments);
  const reports = useSelector(({ repayments }) => repayments.reports);
  const reportDownloadData = useSelector(({ repayments }) => repayments.reportDownloadData);
  const { loadingReports, reportsError } = repayments;
  const [formattedReport, setFormattedReports] = useState([]);
  const dispatch = useDispatch();
  const { MonthPicker } = DatePicker;
  const totalReports = useSelector(({ repayments }) => repayments.totalReports);

  const onReportSuccess = (reportsData) => {
    console.log("[REPORTS]:: ", reportsData);
    let localReports = [];
    reportsData.map((report) => {
      const _report = {
        key: `report-${nanoid()}`,
        employeeName: (
          <>
            <div className="avatar-info">
              <Title level={5}>{report.employeeName}</Title>
            </div>
          </>
        ),
        employeeNumber: (
          <>
            <div className="author-info">
              <Title level={5}>{report.employeeNumber}</Title>
            </div>
          </>
        ),
        phone: (
          <>
            <div className="author-info">
              <Title level={5}>{report.phone}</Title>
            </div>
          </>
        ),
        deductions: (
          <>
            <div className="author-info">
              <Title level={5}>{report.deduction}</Title>
            </div>
          </>
        ),
        expectedAmount: (
          <>
            <div className="author-info">
              <Title level={5}>{report.expectedAmount}</Title>
            </div>
          </>
        ),
        matchingLoans: (
          <>
            <div className="author-info">
              <Title level={5}>{report.matchingLoans}</Title>
            </div>
          </>
        ),
        errors: (
          <>
            <div className="author-info">
              <Title level={5}>{report.errors}</Title>
            </div>
          </>
        ),
        remarks: (
          <>
            <div className="author-info">
              <Title level={5}>{report.remarks}</Title>
            </div>
          </>
        ),
      };

      localReports.push(_report);
    });

    setFormattedReports(localReports);
  };

  const onReportsError = () => {
    setDate(null);
    openNotifcation("error", "No Reports Found!");
  };

  useEffect(() => {
    console.log("---SELECTED REPORTS IN USE EFFECT::", reports)
    onReportSuccess(reports || []);
  }, [reports]);

  useEffect(() => {
    setFormattedReports([]); //reset formatted reports
  }, []);

  function disabledDate(current) {
    // Can not select days after current month
    return current > moment().endOf('day');
  }

  function onChange(value) {
    setDate(value);
  }

  const defaultPageSize = 100;
  const fetchReports = (page, pageSize) => {
    const payload = {
      year: moment(date).year(),
      month: moment(date).month() + 1,
      page: page,
      pageSize: pageSize
    };

    console.log("---[PAYLOADs::", payload);

    dispatch(
      getReports(payload, {
        onError: onReportsError,
      })
    );
  }

  const handleChange = (pagination) => {
    const currentPage = pagination.current - 1; // backend api page index starts at 0 // pagination.curren * pagination.pageSize - pagination.pageSize;
    const size = pagination.pageSize;

    fetchReports(currentPage, size);
  }

  const fileType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
  const fileExtension = '.xlsx';

  const exportToCSV = (csvData, fileName) => {
    const ws = XLSX.utils.json_to_sheet(csvData);
    const wb = { Sheets: { 'data': ws }, SheetNames: ['data'] };
    const excelBuffer = XLSX.write(wb, { bookType: 'xlsx', type: 'array' });
    const data = new Blob([excelBuffer], { type: fileType });
    FileSaver.saveAs(data, fileName + fileExtension);
  }

  const fetchDownloadReports = (page, pageSize) => {
    const payload = {
      year: moment(date).year(),
      month: moment(date).month() + 1,
      page: page,
      pageSize: pageSize
    };

    console.log("---[PAYLOADs::", payload);

    dispatch(
      getDownloadReportData(payload, {
        onError: onReportsError,
      })
    );

  }


  useEffect(() => {
    if (reportDownloadData.length != 0) {
      const filename = moment(date).format("MMM-YYYY") + "_Post_Repaymenet_Report";
      exportToCSV(reportDownloadData, filename);
    }
    onReportSuccess(reportDownloadData || []);

  }, [reportDownloadData]);

  return (
    <>
      <div className="tabled">
        <Row gutter={[24, 0]}>
          <Col xs="24" xl={24}>
            <Card
              bordered={false}
              className="criclebox tablespace mb-24 px-12"
              title="Repayment Reports"
              extra={
                <>

                  <MonthPicker onChange={onChange}
                    inputReadOnly={true}
                    value={date}
                    disabledDate={disabledDate}
                    placeholder="Select month to view reports" />

                  <Button
                    onClick={() => fetchReports(0, defaultPageSize)}
                    className="repayments__row--btn"
                  >
                    View Reports
                  </Button>

                  <Button disabled={!reports} primary onClick={() => fetchDownloadReports(0, totalReports)}>Download Post-Repayment Report</Button>
                </>

              }
            >

              {!date ? (
                <div className="table-responsive reports__empty">
                  <img src={Rocket} className="reports__empty--img" />

                  <Title level={4}>
                    Select a date to view repayment reports !
                  </Title>
                </div>
              ) : (
                <div className="table-responsive">
                  <Table
                    columns={columns}
                    loading={loadingReports}
                    dataSource={formattedReport}
                    onChange={handleChange}
                    pagination={{ total: totalReports }}
                    className="ant-border-space"
                  />
                </div>
              )}
            </Card>
          </Col>
        </Row>
      </div>
    </>
  );
});

export default Reports;
