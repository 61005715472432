/*!
=========================================================
* Muse Ant Design Dashboard - v1.0.0
=========================================================
* Product Page: https://www.creative-tim.com/product/muse-ant-design-dashboard
* Copyright 2021 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/muse-ant-design-dashboard/blob/main/LICENSE.md)
* Coded by Creative Tim
=========================================================
* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/
import React, { useEffect, useState } from "react";
import { Row, Col, Card, Table, message, Button, Typography, Pagination } from "antd";

import { withRouter } from "react-router-dom";

import { useDispatch, useSelector } from "react-redux";
import { getAllClients } from "../actions";
import { openNotifcation } from "../components/Notification";
import moment from "moment";
import { nanoid } from "nanoid";

const { Title } = Typography;

const _columns = [
  {
    title: "Account No.",
    dataIndex: "accountNo",
    key: "accountNo",
  },

  {
    title: "Full Name",
    dataIndex: "fullName",
    key: "fullName",
  },
  {
    title: "Date of Birth",
    dataIndex: "dob",
    key: "dob",
  },
  {
    title: "Phone No.",
    dataIndex: "phone",
    key: "phoneNumber",
  },
  {
    title: "Branch",
    dataIndex: "branch",
    key: "branch",
  },
  {
    title: "Activation Date",
    dataIndex: "activationDate",
    key: "activationDate",
  },
  {
    title: "",
    dataIndex: "view",
    key: "view",
  },
];

const Clients = withRouter(({ history }) => {
  const clients = useSelector(({ clients }) => clients.clients);
  const searchResults = useSelector(({ clients }) => clients.search_results);
  const error = useSelector(({ clients }) => clients.error_getting_clients);
  const loading = useSelector(({ clients }) => clients.loading_clients);
  const dispatch = useDispatch();
  const [results, setResults] = useState([]);
  const [showTable, toggleTable] = useState(false);
  const totalFilteredRecords = useSelector(({ clients }) => clients.totalFilteredRecords);

  useEffect(() => {
    if (searchResults && searchResults.pageItems) {
      let _searchResults = [];

      searchResults.pageItems.map((client) => {
        if (client.dateOfBirth) {
          const date = new Date(client.dateOfBirth.join(","));
          client["dob"] = moment(date).format("DD-MM-YYYY");
        } else {
          client["dob"] = "N/A";
        }

        const activeDate = new Date(client.activationDate.join(","));
        client["activeDate"] = moment(activeDate).format("DD-MM-YYYY");
        const _client = {
          key: `client-${nanoid()}`,
          accountNo: (
            <>
              <div className="avatar-info">
                <Title level={5}>{client.accountNo}</Title>
              </div>
            </>
          ),

          fullName: (
            <>
              <div className="author-info">
                <Title level={5}>{client.displayName}</Title>
              </div>
            </>
          ),
          dob: (
            <>
              <div className="author-info">
                <Title level={5}>{client.dob}</Title>
              </div>
            </>
          ),
          phone: (
            <>
              <div className="author-info">
                <Title level={5}>{client.mobileNo}</Title>
              </div>
            </>
          ),
          employer: (
            <>
              <div className="author-info">
                <Title level={5}>{client.employer}</Title>
              </div>
            </>
          ),
          branch: (
            <>
              <div className="author-info">
                <Title level={5}>{client.officeName}</Title>
              </div>
            </>
          ),
          activationDate: (
            <>
              <div className="author-info">
                <Title level={5}>{client.activeDate}</Title>
              </div>
            </>
          ),
          view: (
            <>
              <Button
                onClick={() => routeToClient(client)}
                type="primary"
                className="tag-primary"
              >
                View
              </Button>
            </>
          ),
        };

        //** UPDATE SEARCH RESULTS ARRAY */
        _searchResults.push(_client);
      });

      setResults(_searchResults);
      toggleTable(true);
    }
  }, [searchResults]);

  const handleClientError = () => {
    openNotifcation("error", "Clients Error", "Network error failed");
  };
  const fetchClients = (params) => {
    dispatch(getAllClients(params, { onError: handleClientError }));
  };
const defaultPageSize = 50;
  useEffect(() => {
    fetchClients({ size: defaultPageSize, page: 0 });
  }, []);

  const routeToClient = (client) => {
    // history.push(`/client/${id}`);

    history.push({
      pathname: `/client/${client.accountNo}`,
      state: { client },
    });
  };

  let _data = [];
  if (clients.length !== 0) {
    clients.map((client, i) => {
      if (client.dateOfBirth) {
        const date = new Date(client.dateOfBirth.join(","));
        client["dob"] = moment(date).format("DD-MM-YYYY");
      } else {
        client["dob"] = moment().format("DD-MM-YYYY");
      }

      const activeDate = new Date(client.activationDate.join(","));
      client["activeDate"] = moment(activeDate).format("DD-MM-YYYY");

      const _client = {
        key: `client-${i}`,
        accountNo: (
          <>
            <div className="avatar-info">
              <Title level={5}>{client.accountNo}</Title>
            </div>
          </>
        ),
        staffID: (
          <>
            <div className="author-info">
              <Title level={5}>{client.staffID}</Title>
            </div>
          </>
        ),
        fullName: (
          <>
            <div className="author-info">
              <Title level={5}>{client.displayName}</Title>
            </div>
          </>
        ),
        dob: (
          <>
            <div className="author-info">
              <Title level={5}>{client.dob}</Title>
            </div>
          </>
        ),
        phone: (
          <>
            <div className="author-info">
              <Title level={5}>{client.mobileNo}</Title>
            </div>
          </>
        ),
        employer: (
          <>
            <div className="author-info">
              <Title level={5}>{client.employer}</Title>
            </div>
          </>
        ),
        branch: (
          <>
            <div className="author-info">
              <Title level={5}>{client.officeName}</Title>
            </div>
          </>
        ),
        activationDate: (
          <>
            <div className="author-info">
              <Title level={5}>{client.activeDate}</Title>
            </div>
          </>
        ),
        view: (
          <>
            <Button
              onClick={() => routeToClient(client)}
              type="primary"
              className="tag-primary"
            >
              View
            </Button>
          </>
        ),
      };
      //** Update _data array */
      _data.push(_client);
    });
  }

  const handleChange = (pagination) => {
    const offset = pagination.current - 1; // backend api page index starts at 0 // pagination.curren * pagination.pageSize - pagination.pageSize;
    const limit = pagination.pageSize;

    fetchClients({ size: limit, page: offset });
  }

  return (
    <>
      <div className="tabled">
        <Row gutter={[24, 0]}>
          <Col xs="24" xl={24}>
            <Card
              bordered={false}
              className="criclebox tablespace mb-24"
              title="Clients"
              extra={
                <>
                  {showTable && (
                    <Button onClick={() => toggleTable(false)}>
                      Clear Search Results
                    </Button>
                  )}
                </>
              }
            >
              <div className="table-responsive">
                {/* <Table
                  columns={columns}
                  dataSource={data}
                  pagination={false}
                  className="ant-border-space"
                /> */}

                <Table
                  columns={_columns}
                  dataSource={showTable ? results : _data}
                  onChange={handleChange}
                  pagination={{ total: totalFilteredRecords }}
                  // size={defaultPageSize}
                  className="ant-border-space"
                  loading={loading}
                />
              </div>
            </Card>

            {/* <Card
              bordered={false}
              className="criclebox tablespace mb-24"
              title="Projects Table"
              extra={
                <>
                  <Radio.Group onChange={onChange} defaultValue="all">
                    <Radio.Button value="all">All</Radio.Button>
                    <Radio.Button value="online">ONLINE</Radio.Button>
                    <Radio.Button value="store">STORES</Radio.Button>
                  </Radio.Group>
                </>
              }
            >
              <div className="table-responsive">
                <Table
                  columns={project}
                  dataSource={dataproject}
                  pagination={false}
                  className="ant-border-space"
                />
              </div>
              <div className="uploadfile pb-15 shadow-none">
                <Upload {...formProps}>
                  <Button
                    type="dashed"
                    className="ant-full-box"
                    icon={<ToTopOutlined />}
                  >
                    Click to Upload
                  </Button>
                </Upload>
              </div>
            </Card> */}
          </Col>
        </Row>
      </div>
    </>
  );
});

export default Clients;
