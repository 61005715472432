import {
  ADDING_LOAN,
  ADDING_LOAN_ERROR,
  ADDING_LOAN_SUCCESS,
  CALCULATE_ALLOWANCE,
  CALCULATE_ALLOWANCE_ERROR,
  CALCULATE_ALLOWANCE_SUCCESS,
  GET_LOANS,
  GET_LOANS_ERROR,
  GET_LOANS_SUCCESS,
  GET_SINGLE_LOAN,
  GET_SINGLE_LOAN_ERROR,
  GET_SINGLE_LOAN_SUCCESS,
  APPROVING_LOAN,
  APPROVE_LOAN_ERROR,
  APPROVE_LOAN_SUCCESS,
  CALCULATE_LOAN_SCHEDULE,
  CALCULATE_LOAN_SCHEDULE_SUCCESS,
  CALCULATE_LOAN_SCHEDULE_ERROR,
  SEARCH_LOANS,
  SEARCH_LOANS_SUCCESS,
  SEARCH_LOANS_ERROR,
  REJECT_LOAN,
  REJECT_LOAN_SUCCESS,
  REJECT_LOAN_ERROR,
  PROCESS_APPROVAL_CHECKLIST,
  PROCESS_CHECKLIST_SUCCESS,
  PROCESS_CHECKLIST_ERROR,
  RESET_NOTIFICATION,
  GET_PAYROLL_LOANS_CUSTOM_FIELD,
  GET_PAYROLL_LOANS_CF_SUCCESS,
  GET_PAYROLL_LOANS_CF_ERROR,
  LOAN_UPDATE_SUCCESS,
  LOAN_UPDATE_ERROR,
  UPDATING_LOAN,
  CALCULATE_LOAN,
  CALCULATE_LOAN_SUCCESS,
  CALCULATE_LOAN_ERROR
} from ".";
import payrollApi from "../api/payrollApi";

import { store } from "../store";

store.subscribe(listener);

let token;
function select(state) {
  if (state.auth.user) {
    return state.auth.user.token;
  }
}

function listener() {
  token = select(store.getState());
  if (token) {
    payrollApi.defaults.headers.common["Authorization"] = `Bearer ${token}`;
  }
}

const addLoanSuccess = (dispatch, loan) => {
  dispatch({ type: ADDING_LOAN_SUCCESS, payload: loan });
  dispatch({ type: RESET_NOTIFICATION });
};

const addLoanError = (dispatch, error) => {
  dispatch({ type: ADDING_LOAN_ERROR, payload: error });
  dispatch({ type: RESET_NOTIFICATION });
};

export const addLoan = (loan, routeToLoans) => {
  return (dispatch) => {
    dispatch({ type: ADDING_LOAN });

    return new Promise((resolve, reject) => {
      payrollApi
        .post(`/loans`, loan)
        .then((loanRes) => {
          const { data } = loanRes;
          console.log("[ADDED LOAN]:: ", data);

          // TODO:: INCLUDE ADDLOANSUCCESS HERE
          addLoanSuccess(dispatch, data);
          // viewLoanDetails(data.loanId);
          routeToLoans();
          resolve(data);
        })
        .catch((err) => {
          reject(err);
          addLoanError(dispatch, err);
          routeToLoans();
        });
    });
  };
};

const getLoansSuccess = (dispatch, loans) => {
  dispatch({ type: GET_LOANS_SUCCESS, payload: loans });
};

const getLoansError = (dispatch, error) => {
  dispatch({ type: GET_LOANS_ERROR, payload: error });
};

export const getLoans = (params) => {
  return (dispatch) => {
    dispatch({ type: GET_LOANS });

    return new Promise((resolve, reject) => {
      payrollApi
        .get(`/loans?page=${params.page}&pageSize=${params.size}`)
        .then((loansRes) => {
          const { data } = loansRes;

          console.log("[DATA]:: ", data);

          data.pageItems.forEach((item) => {
            if (item.summary) {
              item.interestPaid = item.summary.interestPaid;
              item.interestCharged = item.summary.interestCharged;
              item.totalExpectedRepayment = item.summary.totalExpectedRepayment;
            } else {
              item.interestCharged = 0;
              item.totalExpectedRepayment = 0;
              item.interestPaid = 0;
            }
          });

          // TODO:: INCLUDE GETLOANSSUCCESS
          getLoansSuccess(dispatch, data);
          resolve(data);
          // **  */
        })
        .catch((err) => {
          reject(err);
          getLoansError(dispatch, err);
        });
    });
  };
};

const calculateAllowanceSuccess = (dispatch, allowance) => {
  dispatch({ type: CALCULATE_ALLOWANCE_SUCCESS, payload: allowance });
  dispatch({ type: RESET_NOTIFICATION });
};

const calculateAllowanceError = (dispatch, error) => {
  dispatch({ type: CALCULATE_ALLOWANCE_ERROR, payload: error });
  dispatch({ type: RESET_NOTIFICATION });
};

export const calculateAllowance = (allowance) => {
  return (dispatch) => {
    dispatch({ type: CALCULATE_ALLOWANCE });

    return new Promise((resolve, reject) => {
      payrollApi
        .post(`/loans/calc`, allowance)
        .then((allowanceRes) => {
          const { data } = allowanceRes;
          // TODO:: INCLUDE CALCULATEALLOWANCESUCCESS HERE
          calculateAllowanceSuccess(dispatch, data);
          resolve(data);
          console.log("[ALLOWANCE RES]:: ", data);
        })
        .catch((err) => {
          reject(err);
          calculateAllowanceError(dispatch, err);
          console.log("[ALLOWANCE ERROR]:: ", err);
        });
    });
  };
};

const processsChecklistSuccess = (dispatch, checklistData) => {
  dispatch({ type: PROCESS_CHECKLIST_SUCCESS, payload: checklistData });
  dispatch({ type: RESET_NOTIFICATION });
};

const processsChecklistFailure = (dispatch, error) => {
  dispatch({ type: PROCESS_CHECKLIST_ERROR, error });
  dispatch({ type: RESET_NOTIFICATION });
};

export const processApprovalChecklist = (payload) => {
  return (dispatch) => {
    dispatch({ type: PROCESS_APPROVAL_CHECKLIST });

    return new Promise((resolve, reject) => {
      payrollApi
        .post(`/loans/approval-details`, payload)
        .then((response) => {

          const { data } = response;

          processsChecklistSuccess(dispatch, data);
          resolve(data);
          console.log("[CHECKLIST RES]:: ", data);
        })
        .catch((error) => {
          if (error.response) {
            // The request was made and the server responded with a status code
            // that falls out of the range of 2xx
            console.log("error.response.data----", error.response.data);

          } else if (error.request) {
            // The request was made but no response was received
            // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
            // http.ClientRequest in node.js
            console.log(error.request);
          } else {
            // Something happened in setting up the request that triggered an Error
            console.log('error.message----', error.message);
          }
          processsChecklistFailure(dispatch, error);
          reject(error);

        });
    });
  };
};

const getLoanSuccess = (dispatch, loan) => {
  dispatch({ type: GET_SINGLE_LOAN_SUCCESS, payload: loan });
};

const getLoanError = (dispatch, error) => {
  dispatch({ type: GET_SINGLE_LOAN_ERROR, payload: error });
};

export const getLoan = (id) => {
  return (dispatch) => {
    dispatch({ type: GET_SINGLE_LOAN });

    return new Promise((resolve, reject) => {
      payrollApi
        .get(`/loans/${id}`)
        .then((loanRes) => {
          const { data } = loanRes;
          resolve(data);
          getLoanSuccess(dispatch, data);
        })
        .catch((err) => {
          reject(err);
          getLoanError(dispatch, err);
          console.log("[SINGLE LOAN ERROR]:: ", err);
        });
    });
  };
};

const approveLoanSuccess = (dispatch, approvedLoan) => {
  dispatch({ type: APPROVE_LOAN_SUCCESS, payload: approvedLoan });
  dispatch({ type: RESET_NOTIFICATION });
};

const approveLoanError = (dispatch, error) => {
  dispatch({ type: APPROVE_LOAN_ERROR, payload: error });
  dispatch({ type: RESET_NOTIFICATION });
};

const handleLoanApprovalResponse = (dispatch, res) => {
  switch (res.status) {
    case 500:
      return approveLoanError(dispatch, res);

    default:
      return approveLoanSuccess(dispatch, res);
  }
};

export const approveLoan = (loanId, payload, routeToLoans) => {
  return (dispatch) => {
    dispatch({ type: APPROVING_LOAN });

    return new Promise((resolve, reject) => {
      payrollApi
        .post(`/loans/${loanId}`, payload)
        .then((approvedLoanRes) => {
          const { data } = approvedLoanRes;
          console.log("[APPROVED LOAN RES]:: ", data);

          handleLoanApprovalResponse(dispatch, data);
          resolve(data);
          routeToLoans();
        })
        .catch((err) => {
          console.log("[WHAT BROKE]:: ", err);

          if (err.response) {
            // The request was made and the server responded with a status code
            // that falls out of the range of 2xx
            console.log("error.response.data----", err.response.data.message);

          } else if (err.request) {
            // The request was made but no response was received
            // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
            // http.ClientRequest in node.js
            console.log(err.request);
          } else {
            // Something happened in setting up the request that triggered an Error
            console.log('error.message----', err.message);
          }
          approveLoanError(dispatch, err.response.data.message);
          // routeToLoans();
          reject(err);
        });
    });
  };
};

const rejectLoanSuccess = (dispatch, rejectLoan) => {
  dispatch({ type: REJECT_LOAN_SUCCESS, payload: rejectLoan });
  dispatch({ type: RESET_NOTIFICATION });
};

const rejectLoanError = (dispatch, error) => {
  dispatch({ type: REJECT_LOAN_ERROR, payload: error });
  dispatch({ type: RESET_NOTIFICATION });
};

export const rejectLoan = (loanId, payload, onRejectLoanSuccess, onRejectLoanFail) => {
  return (dispatch) => {
    dispatch({ type: REJECT_LOAN });

    return new Promise((resolve, reject) => {
      payrollApi
        .post(`/loans/${loanId}`, payload)
        .then((rejectLoanRes) => {
          const { data } = rejectLoanRes;
          console.log("[REJECT LOAN RES full]:: ", rejectLoanRes);
          console.log("[REJECT LOAN RES]:: ", data);

          rejectLoanSuccess(dispatch, data);
          resolve(data);
          onRejectLoanSuccess(loanId);
        })
        .catch((err) => {
          console.log("[WHAT BROKE]:: ", err);
          rejectLoanError(dispatch, err);
          reject(err);
          onRejectLoanFail();
        });
    });
  };
};

const loanScheduleSuccess = (dispatch, loanSchedule) => {
  dispatch({ type: CALCULATE_LOAN_SCHEDULE_SUCCESS, payload: loanSchedule });
  dispatch({ type: RESET_NOTIFICATION });
};

const loanScheduleError = (dispatch, error) => {
  dispatch({ type: CALCULATE_LOAN_SCHEDULE_ERROR, payload: error });
  dispatch({ type: RESET_NOTIFICATION });
};

const handleLoanScheduleResponse = (dispatch, res) => {
  switch (res.status) {
    case 500:
      return loanScheduleError(dispatch, res);

    default:
      return loanScheduleSuccess(dispatch, res);
  }
};

export const calculateLoanSchedule = (payload) => {
  return (dispatch) => {
    dispatch({ type: CALCULATE_LOAN_SCHEDULE });

    return new Promise((resolve, reject) => {
      payrollApi
        .post(`/loans/schedule-calc`, payload)
        .then((schedule) => {
          const { data } = schedule;

          console.log("[LOAN SCHEDULE RES]:: ", data);

          handleLoanScheduleResponse(dispatch, data);
          resolve(data);
        })
        .catch((err) => {
          console.log("[WHAT BROKE]:: ", err);
          reject(err);
          loanScheduleError(dispatch, err);
        });
    });
  };
};

const handleSearchLoansSuccess = (dispatch, payload) => {
  dispatch({ type: SEARCH_LOANS_SUCCESS, payload });
  dispatch({ type: RESET_NOTIFICATION });
};

const handleSearchLoansError = (dispatch, error) => {
  dispatch({ type: SEARCH_LOANS_ERROR, payload: error });
  dispatch({ type: RESET_NOTIFICATION });
};

export const searchLoans = (name, { onSuccess, onError }) => {
  return (dispatch) => {
    dispatch({ type: SEARCH_LOANS });
    payrollApi
      .get(`loans?searchParam=${name}`)
      .then((res) => {
        const { data } = res;
        console.log("[LOANS SEARCH]:: ", data);
        handleSearchLoansSuccess(dispatch, data);
        onSuccess();
      })
      .catch((err) => {
        handleSearchLoansError(dispatch, err);
        onError();
      });
  };
};

const handleLoanCustomFieldSuccess = (dispatch, payload) => {
  dispatch({ type: GET_PAYROLL_LOANS_CF_SUCCESS, payload });
  // dispatch({ type: RESET_NOTIFICATION });
};

const handleLoanCustomFieldError = (dispatch, error) => {
  dispatch({ type: GET_PAYROLL_LOANS_CF_ERROR, payload: error });
  // dispatch({ type: RESET_NOTIFICATION });
};

export const getLoanCustomFieldByName = (id, name) => {
  return (dispatch) => {

    dispatch({ type: GET_PAYROLL_LOANS_CUSTOM_FIELD });
    return new Promise((resolve, reject) => {
      payrollApi
        .get(`loans/${id}/custom_fields?name=${name}`)
        .then((loanDetails) => {
          const { data } = loanDetails;

          console.log("[PAYROLL_LOAN_CUSTOM_FIELD_DATA]:: ", data[0]);

          handleLoanCustomFieldSuccess(dispatch, data[0]);
          resolve(data[0]);
        })
        .catch((err) => {
          console.log("[WHAT BROKE]:: ", err);
          reject(err);
          handleLoanCustomFieldError(dispatch, err);
        });
    });
  };
};

const handleUpdateLoanSuccess = (dispatch, payload) => {
  dispatch({ type: LOAN_UPDATE_SUCCESS, payload });
  dispatch({ type: RESET_NOTIFICATION });
};

const handleUpdateLoanError = (dispatch, error) => {
  dispatch({ type: LOAN_UPDATE_ERROR, payload: error });
  dispatch({ type: RESET_NOTIFICATION });
};

export const updateLoan = (id, payload, routeToLoans) => {
  return (dispatch) => {

    dispatch({ type: UPDATING_LOAN });
    console.log("[UPDATING LOAN]:: ");

    return new Promise((resolve, reject) => {
      payrollApi
        .put(`loans/${id}`, payload)
        .then((loanDetails) => {
          const { data } = loanDetails;

          console.log("[UPDATING LOAN_DATA]:: ", data);

          handleUpdateLoanSuccess(dispatch, data);
          resolve(data);
          routeToLoans();
        })
        .catch((err) => {
          console.log("[WHAT BROKE]:: ", err);
          handleUpdateLoanError(dispatch, err);
          reject(err);
        });
    });
  };
};

const processLoanEnquirySuccess = (dispatch, payload) => {
  dispatch({ type: CALCULATE_LOAN_SUCCESS, payload });
  dispatch({ type: RESET_NOTIFICATION });
};

const processLoanEnquiryError = (dispatch, error) => {
  dispatch({ type: CALCULATE_LOAN_ERROR, payload: error });
  dispatch({ type: RESET_NOTIFICATION });
};

export const processLoanEnquiry = (payload) => {
  return (dispatch) => {

    dispatch({ type: CALCULATE_LOAN });
    console.log("[CALCULATING LOAN ENQUIRY]:: ");

    return new Promise((resolve, reject) => {
      payrollApi
        .post("loans/loan-calculator", payload)
        .then((calcDetails) => {
          const { data } = calcDetails;

          console.log("[ENQUIRY CALC_DATA]:: ", data);

          processLoanEnquirySuccess(dispatch, data);
          resolve(data);
        })
        .catch((err) => {
          console.log("[WHAT BROKE]:: ", err);
          processLoanEnquiryError(dispatch, err);
          reject(err);
        });
    });
  };
};



