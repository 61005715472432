import {
  LOADING_COMMISSION,
  LOADING_COMMISSION_SUCCESS,
  LOADING_COMMISSION_ERROR,
  LOADING_PAYMENT_TYPES,
  PAYMENT_TYPES_SUCCESS,
  PAYMENT_TYPES_ERROR,
  APPROVE_COMMISSION,
  APPROVE_COMMISSION_SUCCESS,
  APPROVE_COMMISSION_ERROR,
  CALC_COMMISSION,
  CALC_COMMISSION_SUCCESS,
  CALC_COMMISSION_ERROR,
} from "../actions";

const INITIAL_STATE = {
  loading: false,
  data: [],
  error: false,
  paymentTypes: [],
  loadingPaymentTypes: false,
  paymentTypesError: false,
  approvingCommission: false,
  errorApprovingCommission: false,
  calculatingCommission: false,
  calcCommissionError: false,
  calcCommission: null,
};

const CommissionsReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case LOADING_COMMISSION:
      return {
        ...state,
        loading: true,
        error: false,
        calcCommission: null
      };

    case LOADING_COMMISSION_SUCCESS:
      return {
        ...state,
        loading: false,
        error: false,
        data: [...action.payload],
      };

    case LOADING_COMMISSION_ERROR:
      return {
        ...state,
        loading: false,
        error: true,
      };

    case LOADING_PAYMENT_TYPES:
      return {
        ...state,
        loadingPaymentTypes: true,
        paymentTypesError: false,
      };

    case PAYMENT_TYPES_SUCCESS:
      return {
        ...state,
        loadingPaymentTypes: false,
        paymentTypes: [...action.payload],
      };

    case PAYMENT_TYPES_ERROR:
      return {
        ...state,
        loadingPaymentTypes: false,
        paymentTypesError: true,
      };

    case APPROVE_COMMISSION:
      return {
        ...state,
        approvingCommission: true,
        errorApprovingCommission: false,
      };

    case APPROVE_COMMISSION_SUCCESS:
      return {
        ...state,
        approvingCommission: false,
        errorApprovingCommission: false,
      };

    case APPROVE_COMMISSION_ERROR:
      return {
        ...state,
        approvingCommission: false,
        errorApprovingCommission: true,
      };

    case CALC_COMMISSION:
      return {
        ...state,
        calculatingCommission: true,
        calcCommissionError: false,
      };

    case CALC_COMMISSION_SUCCESS:
      return {
        ...state,
        calculatingCommission: false,
        calcCommission: action.payload,
      };

    case CALC_COMMISSION_ERROR:
      return {
        ...state,
        calculatingCommission: false,
        calcCommissionError: true,
      };

    default:
      return state;
  }
};

export default CommissionsReducer;
