import {
  GET_ALL_ALLOWANCES,
  GET_ALL_ALLOWANCES_ERROR,
  GET_ALL_ALLOWANCES_SUCCESS,
} from ".";
import payrollApi from "../api/payrollApi";

import { store } from "../store";

store.subscribe(listener);

let token;
function select(state) {
  if (state.auth.user) {
    return state.auth.user.token;
  }
}

function listener() {
  token = select(store.getState());
  if (token) {
    payrollApi.defaults.headers.common["Authorization"] = `Bearer ${token}`;
  }
}

const getAllowanceSuccess = (dispatch, allowances) => {
  dispatch({ type: GET_ALL_ALLOWANCES_SUCCESS, payload: allowances });
};

const getAllowanceError = (dispatch, error) => {
  dispatch({ type: GET_ALL_ALLOWANCES_ERROR, payload: error });
};

export const getAllowances = () => {
  return (dispatch) => {
    dispatch({ type: GET_ALL_ALLOWANCES });

    return new Promise((resolve, reject) => {
      payrollApi
        .get(`/allowances`)
        .then((allowance_res) => {
          const { data } = allowance_res;
          console.log("[ALLOWANCES]:: ", data);
          getAllowanceSuccess(dispatch, data.pageItems);
        })
        .catch((err) => {
          console.log("[GET ALLOWANCE ERROR]:: ", err);
          reject(err);
          getAllowanceError(dispatch, err);
          // TODO:: Include the getAllAllowancesError in this area
        });
    });
  };
};
