import {
  UPLOADING_REPAYMENTS,
  UPLOADING_REPAYMENTS_ERROR,
  UPLOADING_REPAYMENTS_SUCCESS,
  GET_REPAYMENTS,
  GET_REPAYMENTS_SUCCESS,
  GET_REPAYMENTS_ERROR,
  DELETE_REPAYMENTS,
  DELETE_REPAYMENTS_SUCCESS,
  DELETE_REPAYMENTS_ERROR,
  PROCESSING_REPAYMENTS,
  PROCESSING_REPAYMENTS_ERROR,
  PROCESSING_REPAYMENTS_SUCCESS,
  START_PROCESSING_REPAYMENTS_SUCCESS,
  START_PROCESSING_REPAYMENTS_ERROR,
  START_PROCESSING_REPAYMENTS,
  LOADING_REPORTS,
  REPORTS_SUCCESS,
  REPORTS_ERROR,
  REPORTS_DOWNLOAD_SUCCESS,
  REPORTS_DOWNLOAD_ERROR,
  PRE_REPAYMENT_RPTS_DWNLD_SUCCESS,
  PRE_REPAYMENT_RPTS_DWNLD_ERROR,
  DOWNLOAD_PRE_REPAYMENTS_REPORTS
} from "./types";

import payrollApi from "../api/payrollApi";

import { store } from "../store";

store.subscribe(listener);

let token;
function select(state) {
  if (state.auth.user) {
    return state.auth.user.token;
  }
}

function listener() {
  token = select(store.getState());
  if (token) {
    payrollApi.defaults.headers.common["Authorization"] = `Bearer ${token}`;
  }
}

const uploadRepaymentSuccess = (dispatch, repaymentRes) => {
  dispatch({ type: UPLOADING_REPAYMENTS_SUCCESS, payload: repaymentRes });
};

const uploadRepaymentError = (dispatch, error) => {
  dispatch({ type: UPLOADING_REPAYMENTS_ERROR, payload: error });
};

export const uploadRepayment = (payload) => {
  return (dispatch) => {
    dispatch({ type: UPLOADING_REPAYMENTS });

    return new Promise((resolve, reject) => {
      payrollApi
        .post("/repayments/uploads", payload)
        .then((res) => {
          const { data } = res;
          console.log("[REPAYMENTS UPLOAD RES]:: ", data);
        })
        .catch((err) => {
          console.log("[REPAYMENTS UPLOAD ERROR]:: ", err);
        });
    });
  };
};

const getRepaymentsSuccess = (dispatch, res) => {
  dispatch({ type: GET_REPAYMENTS_SUCCESS, payload: res });
};

const getRepaymentsError = (dispatch, error) => {
  dispatch({ type: GET_REPAYMENTS_ERROR, payload: error });
};

export const getRepayments = ({ onError }) => {
  return (dispatch) => {
    dispatch({ type: GET_REPAYMENTS });

    return new Promise((resolve, reject) => {
      payrollApi
        .get("/repayments/uploads")
        .then((res) => {
          const { data } = res;
          console.log("[GET REPAYMENT RES]:: ", res);
          console.log("[GET REPAYMENT DATA]:: ", data);
          getRepaymentsSuccess(dispatch, data.pageItems);
          // onSuccess();
          resolve(data);
          
        })
        .catch((err) => {
          getRepaymentsError(dispatch, err);
          console.log("[GET REPAYMENT ERROR]:: ", err);
          reject(err);
          onError();
        });
    });
  };
};

const deleteRepaymentSuccess = (dispatch, res) => {
  dispatch({ type: DELETE_REPAYMENTS_SUCCESS, payload: res });
};

const deleteRepaymentError = (dispatch, error) => {
  dispatch({ type: DELETE_REPAYMENTS_ERROR, payload: error });
};

export const deleteRepayment = (id) => {
  return (dispatch) => {
    dispatch({ type: DELETE_REPAYMENTS });
    return new Promise((resolve, reject) => {
      payrollApi
        .delete(`/repayments/upload/${id}`)
        .then((res) => {
          const { data } = res;
          console.log("[DELETE REPAYMENTS RES]:: ", data);
        })
        .catch((err) => {
          console.log("[DELETE REPAYMENT ERROR]:: ", err);
        });
    });
  };
};

const startProcessRepaymentSuccess = (dispatch, res) => {
  dispatch({ type: START_PROCESSING_REPAYMENTS_SUCCESS, payload: res });
};

const startProcessRepaymentError = (dispatch, error) => {
  dispatch({ type: START_PROCESSING_REPAYMENTS_ERROR, payload: error });
};

export const processRepayments = (requestData, { onSuccess, onError }) => {
  return (dispatch) => {
    dispatch({ type: START_PROCESSING_REPAYMENTS });
    return new Promise((resolve, reject) => {
      payrollApi
        .post(`/repayments/uploads/${requestData.id}/process?guid=${requestData.guid}`)
        .then((res) => {
          const { data } = res;
          console.log("[PROCESSING REPAYMENTS RES]:: ", data);
          onSuccess();
          startProcessRepaymentSuccess(dispatch, data);
          resolve();
        })
        .catch((err) => {
          console.log("[PROCESSING REPAYMENT ERROR]:: ", err);
          startProcessRepaymentError(dispatch, err);
          onError();
          reject();
        });
    });
  };
};

const handleTrackRepaymentSuccess = (dispatch, payload) => {
  dispatch({ type: PROCESSING_REPAYMENTS_SUCCESS, payload });
};

const handleTrackRepaymentError = (dispatch, error) => {
  dispatch({ type: PROCESSING_REPAYMENTS_ERROR, payload: error });
};

export const trackRepaymentStatus = (id, { onSuccess, onError }) => {
  return (dispatch) => {
    dispatch({ type: PROCESSING_REPAYMENTS });
    payrollApi
      .get(`/repayments/uploads/${id}/status`)
      .then((res) => {
        const { data } = res;
        console.log("[TRACK PROCESSED REPAYMENT]:: ", data);

        if (data.percentage === 100) {
          onSuccess();
        }
        handleTrackRepaymentSuccess(dispatch, data);
      })
      .catch((err) => {
        onError();
        console.log("[TRACK REPAYMENT ERROR]:: ", err);
        handleTrackRepaymentError(dispatch, err);
      });
  };
};

const handleReportSuccess = (dispatch, payload) => {
  dispatch({ type: REPORTS_SUCCESS, payload: payload });
};

const handleReportError = (dispatch, error) => {
  dispatch({ type: REPORTS_ERROR, payload: error });
};

export const getReports = (payload, { onError }) => {
  return (dispatch) => {
    dispatch({ type: LOADING_REPORTS });
    payrollApi
      .get(`/repayments/reports?year=${payload.year}&month=${payload.month}&page=${payload.page}&pageSize=${payload.pageSize}`)
      .then((res) => {
        const { data } = res;
        console.log ("--- RESPONSE FROM GET_REPORTS::", data);
        handleReportSuccess(dispatch, data);
        // onSuccess();
      })
      .catch((err) => {
        onError();
        handleReportError(dispatch, err);
      });
  };
};

const handleDownloadReportSuccess = (dispatch, payload) => {
  dispatch({ type: REPORTS_DOWNLOAD_SUCCESS, payload: payload });
};

const handleDownloadReportError = (dispatch, error) => {
  dispatch({ type: REPORTS_DOWNLOAD_ERROR, payload: error });
};

export const getDownloadReportData = (payload, { onError }) => {
  return (dispatch) => {
    dispatch({ type: LOADING_REPORTS });
    payrollApi
      .get(`/reports/downloads/post-repayment?year=${payload.year}&month=${payload.month}&page=${payload.page}&pageSize=${payload.pageSize}`)
      .then((res) => {
        const { data } = res;
        console.log ("--- RESPONSE FROM GET_REPORTS::", data);
        handleDownloadReportSuccess(dispatch, data);
        // onSuccess();
      })
      .catch((err) => {
        onError();
        handleDownloadReportError(dispatch, err);
      });
  };
};

const handlePreRepaymentReportsSuccess = (dispatch, payload) => {
  dispatch({ type: PRE_REPAYMENT_RPTS_DWNLD_SUCCESS, payload: payload });
};

const handlePreRepaymentReportsError = (dispatch, error) => {
  dispatch({ type: PRE_REPAYMENT_RPTS_DWNLD_ERROR, payload: error });
};

export const getPreRepaymentReportData = (payload, { onError }) => {
  return (dispatch) => {
    dispatch({ type: DOWNLOAD_PRE_REPAYMENTS_REPORTS });
    payrollApi
      .get(`/reports/downloads/pre-repayment?year=${payload.year}&month=${payload.month}&guid=${payload.guid}`)
      .then((res) => {
        const { data } = res;
        console.log ("--- RESPONSE FROM GET_PRE_REPAYMENT_REPORTS::", data);
        handlePreRepaymentReportsSuccess(dispatch, data);
        // onSuccess();
      })
      .catch((err) => {
        onError();
        handlePreRepaymentReportsError(dispatch, err);
      });
  };
};


