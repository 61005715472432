import { bindActionCreators } from "redux";
import {
  GET_REPAYMENTS_SUCCESS,
  GET_REPAYMENTS_ERROR,
  GET_REPAYMENTS,
  START_PROCESSING_REPAYMENTS_ERROR,
  START_PROCESSING_REPAYMENTS_SUCCESS,
  PROCESSING_REPAYMENTS,
  PROCESSING_REPAYMENTS_SUCCESS,
  PROCESSING_REPAYMENTS_ERROR,
  UPLOADING_REPAYMENTS,
  UPLOADING_REPAYMENTS_SUCCESS,
  UPLOADING_REPAYMENTS_ERROR,
  DELETE_REPAYMENTS,
  DELETE_REPAYMENTS_SUCCESS,
  DELETE_REPAYMENTS_ERROR,
  START_PROCESSING_REPAYMENTS,
  LOADING_REPORTS,
  REPORTS_SUCCESS,
  REPORTS_ERROR,
  REPORTS_DOWNLOAD_SUCCESS,
  REPORTS_DOWNLOAD_ERROR,
  PRE_REPAYMENT_RPTS_DWNLD_SUCCESS,
  PRE_REPAYMENT_RPTS_DWNLD_ERROR,
  DOWNLOAD_PRE_REPAYMENTS_REPORTS
} from "../actions";

const INITIAL_STATE = {
  uploadingRepayment: false,
  errorUploadingRepayment: false,
  errorUploadingRepaymentMsg: "",
  successUploadingRepayment: false,
  loading: false,
  error: false,
  errorMsg: "",
  data: [],
  deletingRepayment: false,
  deleteRepaymentSuccess: false,
  deleteRepaymentError: false,
  deleteRepaymentErrorMsg: "",
  startProcessingRepayment: false,
  processingId: null,
  processingRepaymentErrorMsg: "",
  errorProcessingRepayment: false,
  successProcessingRepayment: false,
  processingRepayment: false,
  processingRepaymentComplete: false,
  processingProgress: null,
  loadingReports: false,
  reports: null,
  reportsError: false,
  uploadedRepayments: [],
  totalReports: 0,
  reportDownloadData: [],
  preRepaymentReportDownloaded: false,
  prerepayments_reports: []

};

const RepaymentReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case GET_REPAYMENTS:
      return {
        ...state,
        loading: true,
        error: false,
        // prerepayments_reports: []
      };

    case GET_REPAYMENTS_SUCCESS:
      return {
        ...state,
        loading: false,
        error: false,
        data: action.payload,
        uploadedRepayments: action.payload,
        // prerepayments_reports: []
      };

    case GET_REPAYMENTS_ERROR:
      return {
        ...state,
        loading: false,
        error: true,
        errorMsg: action.payload.message,
        uploadedRepayments: []
      };

    case UPLOADING_REPAYMENTS:
      return {
        ...state,
        uploadingRepayment: true,
        errorUploadingRepayment: false,
        successUploadingRepayment: false,
      };

    case UPLOADING_REPAYMENTS_SUCCESS:
      return {
        ...state,
        uploadingRepayment: false,
        successUploadingRepayment: true,
        errorUploadingRepayment: false,
      };

    case UPLOADING_REPAYMENTS_ERROR:
      return {
        ...state,
        uploadingRepayment: false,
        successUploadingRepayment: false,
        errorUploadingRepayment: true,
        errorUploadingRepaymentMsg: action.payload.error,
      };

    case START_PROCESSING_REPAYMENTS:
      return {
        ...state,
        startProcessingRepayment: true,
        errorProcessingRepayment: false,
        successProcessingRepayment: false,
      };

    case START_PROCESSING_REPAYMENTS_SUCCESS:
      return {
        ...state,
        successProcessingRepayment: true,
        errorProcessingRepayment: false,
      };

    case START_PROCESSING_REPAYMENTS_ERROR:
      return {
        ...state,
        startProcessingRepayment: false,
        successProcessingRepayment: false,
        errorProcessingRepayment: true,
        processingRepaymentErrorMsg: action.payload.error,
      };

    case DELETE_REPAYMENTS:
      return {
        ...state,
        deletingRepayment: true,
        deleteRepaymentSuccess: false,
        deleteRepaymentError: false,
      };

    case DELETE_REPAYMENTS_SUCCESS:
      return {
        ...state,
        deleteRepaymentError: false,
        deleteRepaymentSuccess: true,
        deletingRepayment: false,
      };

    case DELETE_REPAYMENTS_ERROR:
      return {
        ...state,
        deletingRepayment: false,
        deleteRepaymentError: true,
        deleteRepaymentSuccess: false,
        deleteRepaymentErrorMsg: action.payload.error,
      };

    case PROCESSING_REPAYMENTS:
      return {
        ...state,
        processingRepayment: true,
        processingRepaymentComplete: false,
      };

    case PROCESSING_REPAYMENTS_SUCCESS:
      return {
        ...state,
        startProcessingRepayment:
          action.payload.status === "processing" ? true : false,
        processingRepayment:
          action.payload.status === "processing" ? true : false,
        processingRepaymentComplete:
          action.payload.status === "processing" ? false : true,
        processingProgress: action.payload,
      };

    case PROCESSING_REPAYMENTS_ERROR:
      return {
        ...state,
        processingRepayment: false,
        startProcessingRepayment: false,
        processingRepaymentErrorMsg: action.payload.message,
        processingRepaymentComplete: true,
      };

    case LOADING_REPORTS:
      return {
        ...state,
        loadingReports: true,
        reportError: false,
        reports: [],
        totalReports: 0,
        reportDownloadData: [],
        prerepayments_reports:[]
      };

    case REPORTS_SUCCESS:
      return {
        ...state,
        loadingReports: false,
        reportError: false,
        reports: action.payload.payload,
        totalReports: action.payload.totalFilteredRecords
      };

    case REPORTS_ERROR:
      return {
        ...state,
        loadingReports: false,
        reportError: true,
        reports: [],
        totalReports: 0
      };

    case REPORTS_DOWNLOAD_SUCCESS:
      return {
        ...state,
        loadingReports: false,
        reportError: false,
        reportDownloadData: action.payload.payload
      };

    case REPORTS_DOWNLOAD_ERROR:
      return {
        ...state,
        loadingReports: false,
        reportError: true,
        reportDownloadData: []
      };

    case PRE_REPAYMENT_RPTS_DWNLD_SUCCESS:
      return {
        ...state,
        preRepaymentReportDownloaded: true,
        prerepayments_reports: action.payload.payload
      };

    case PRE_REPAYMENT_RPTS_DWNLD_ERROR:
      return {
        ...state,
        preRepaymentReportDownloaded: false,
        prerepayments_reports: []
      };

    case DOWNLOAD_PRE_REPAYMENTS_REPORTS:
      return {
        ...state,
        preRepaymentReportDownloaded: false,
        prerepayments_reports: []
      };

    default:
      return state;
  }
};

export default RepaymentReducer;
