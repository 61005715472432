import {
  GET_ALL_LOAN_PRODUCTS,
  GET_ALL_LOAN_PRODUCTS_ERROR,
  GET_ALL_LOAN_PRODUCTS_SUCCESS,
} from ".";
import payrollApi from "../api/payrollApi";

import { store } from "../store";

store.subscribe(listener);

let token;
function select(state) {
  if (state.auth.user) {
    return state.auth.user.token;
  }
}

function listener() {
  token = select(store.getState());
  if (token) {
    payrollApi.defaults.headers.common["Authorization"] = `Bearer ${token}`;
  }
}

const getAllProductsSuccess = (dispatch, loanProducts) => {
  dispatch({ type: GET_ALL_LOAN_PRODUCTS_SUCCESS, payload: loanProducts });
};

const getAllProductsError = (dispatch, error) => {
  dispatch({ type: GET_ALL_LOAN_PRODUCTS_ERROR, payload: error });
};

export const getAllLoanProducts = (clientId) => { 
  return (dispatch) => {
    dispatch({ type: GET_ALL_LOAN_PRODUCTS });

    return new Promise((resolve, reject) => {
      payrollApi
        .get(`/loan_products?clientId=${clientId ? clientId : ""}`)
        .then((loan_products_res) => {
          const { data } = loan_products_res;

          resolve(data);

          console.log("[LOAN PRODUCTS]:: ", data);

          getAllProductsSuccess(dispatch, data.pageItems);
        })
        .catch((err) => {
          console.log("[LOAN PRODUCTS ERROR]:: ", err.response.data.message);
          reject(err);
          getAllProductsError(dispatch, err.response.data.message);
        });
    });
  };
};
