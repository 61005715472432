//** AUTH TYPES */
export const EMAIL_CHANGED = "EMAIL_CHANGED";
export const PASSWORD_CHANGED = "PASSWORD_CHANGED";
export const LOGGING_IN = "LOGGING_IN";
export const LOGIN_SUCCESS = "LOGIN_SUCCESS";
export const LOGIN_ERROR = "LOGIN_ERROR";
export const REGISTERING = "REGISTERING";
export const REGISTER_SUCCESS = "REGISTER_SUCCESS";
export const REGISTER_ERROR = "REGISTER_ERROR";
export const LOG_OUT = "LOG_OUT";

//** CLIENTS TYPES */
export const LOADING_CLIENTS = "LOADING_CLIENTS";
export const CLIENTS_SUCCESS = "CLIENTS_SUCCESS";
export const CLIENTS_ERROR = "CLIENTS_ERROR";

//** CLIENT TYPES */
export const LOADING_CLIENT = "LOADING_CLIENT";
export const CLIENT_SUCCESS = "CLIENT_SUCCESS";
export const CLIENT_ERROR = "CLIENT_ERROR";
export const SEARCH_CLIENTS = "SEARCH_CLIENTS";
export const SEARCH_CLIENTS_SUCCESS = "SEARCH_CLIENTS_SUCCESS";
export const SEARCH_CLIENTS_ERROR = "SEARCH_CLIENTS_ERROR";

//** CLIENT IMAGE TYPES */
export const LOADING_IMAGE = "LOADING_CLIENT_IMAGE";
export const IMAGE_SUCCESS = "IMAGE_SUCCESS";
export const IMAGE_ERROR = "IMAGE_ERROR";
export const GET_CLIENT_CUSTOM_FIELD = "GET_CLIENT_CUSTOM_FIELD";
export const GET_CLIENT_CUSTOM_FIELD_ERROR = "GET_CLIENT_CUSTOM_FIELD_ERROR";
export const GET_CLIENT_CUSTOM_FIELD_SUCCESS =
  "GET_CLIENT_CUSTOM_FIELD_SUCCESS";

//** CLIENTS IDENTIFIERS */
export const LOADING_CLIENT_IDS = "LOADING_CLIENT_IDS";
export const CLIENT_ID_LOADED_SUCCESS = "CLIENT_ID_LOADED_SUCCESS";
export const CLIENT_ID_LOADED_ERROR = "CLIENT_ID_LOADED_ERROR";

//** LOAN TYPES  */
export const GET_LOANS = "GET_LOANS";
export const GET_LOANS_ERROR = "GET_LOANS_ERROR";
export const GET_LOANS_SUCCESS = "GET_LOANS_SUCCESS";
export const GET_SINGLE_LOAN = "GET_SINGLE_LOAN";
export const GET_SINGLE_LOAN_SUCCESS = "GET_SINGLE_LOAN_SUCCESS";
export const GET_SINGLE_LOAN_ERROR = "GET_SINGLE_LOAN_ERROR";
export const SEARCH_LOANS = "SEARCH_LOANS";
export const SEARCH_LOANS_SUCCESS = "SEARCH_LOANS_SUCCESS";
export const SEARCH_LOANS_ERROR = "SEARCH_LOANS_ERROR";
export const GET_PAYROLL_LOANS_CUSTOM_FIELD = "GET_PAYROLL_LOANS_CUSTOM_FIELD";
export const GET_PAYROLL_LOANS_CF_SUCCESS = "GET_PAYROLL_LOANS_CF_SUCCESS";
export const GET_PAYROLL_LOANS_CF_ERROR= "GET_PAYROLL_LOANS_CF_ERROR";

//** ADD LOAN TYPES */
export const ADDING_LOAN = "ADDING_LOAN";
export const ADDING_LOAN_SUCCESS = "ADDING_LOAN_SUCCESS";
export const ADDING_LOAN_ERROR = "ADDING_LOAN_ERROR";

//** APPROVE LOAN TYPES */
export const APPROVING_LOAN = "APPROVING_LOAN";
export const APPROVE_LOAN_SUCCESS = "APPROVE_LOAN_SUCCESS";
export const APPROVE_LOAN_ERROR = "APPROVE_LOAN_ERROR";

//** UPDATE LOAN TYPES */
export const UPDATING_LOAN = "UPDATING_LOAN";
export const LOAN_UPDATE_SUCCESS = "LOAN_UPDATE_SUCCESS";
export const LOAN_UPDATE_ERROR = "LOAN_UPDATE_ERROR";

//** REJECT LOAN TYPES */
export const REJECT_LOAN = "REJECT_LOAN";
export const REJECT_LOAN_SUCCESS = "REJECT_LOAN_SUCCESS";
export const REJECT_LOAN_ERROR = "REJECT_LOAN_ERROR";

// ** ALLOWANCE CALCULATION TYPES
export const CALCULATE_ALLOWANCE = "CALCULATE_ALLOWANCE";
export const CALCULATE_ALLOWANCE_SUCCESS = "CALCULATE_ALLOWANCE_SUCCESS";
export const CALCULATE_ALLOWANCE_ERROR = "CALCULATE_ALLOWANCE_ERROR";

// ** CHECKLIST CALCULATION TYPES
export const PROCESS_APPROVAL_CHECKLIST = "PROCESS_APPROVAL_CHECKLIST";
export const PROCESS_CHECKLIST_SUCCESS = "PROCESS_CHECKLIST_SUCCESS";
export const PROCESS_CHECKLIST_ERROR = "PROCESS_CHECKLIST_ERROR";

//** AGENT TYPES */
export const GET_ALL_AGENTS = "GET_ALL_AGENTS";
export const GET_ALL_AGENTS_SUCCESS = "GET_ALL_AGENTS_SUCCESS";
export const GET_ALL_AGENTS_ERROR = "GET_ALL_AGENTS_ERROR";
export const GET_AGENT_LOANS = "GET_AGENT_LOANS";
export const GET_AGENT_LOANS_SUCCESS = "GET_AGENT_LOANS_SUCCESS";
export const GET_AGENT_LOANS_ERROR = "GET_AGENT_LOANS_ERROR";

//** LOAN PRODUCT TYPES */
export const GET_ALL_LOAN_PRODUCTS = "GET_ALL_LOAN_PRODUCTS";
export const GET_ALL_LOAN_PRODUCTS_SUCCESS = "GET_ALL_LOAN_PRODUCTS_SUCCESS";
export const GET_ALL_LOAN_PRODUCTS_ERROR = "GET_ALL_LOAN_PRODUCTS_ERROR";

//** ALLOWANCE TYPES */
export const GET_ALL_ALLOWANCES = "GET_ALL_ALLOWANCES";
export const GET_ALL_ALLOWANCES_SUCCESS = "GET_ALL_ALLOWANCES_SUCCESS";
export const GET_ALL_ALLOWANCES_ERROR = "GET_ALL_ALLOWANCES_ERROR";

//** LOAN SCHEDULE TYPES */
export const CALCULATE_LOAN_SCHEDULE = "CALCULATE_LOAN_SCHEDULE";
export const CALCULATE_LOAN_SCHEDULE_SUCCESS =
  "CALCULATE_LOAN_SCHEDULE_SUCCESS";
export const CALCULATE_LOAN_SCHEDULE_ERROR = "CALCULATE_LOAN_SCHEDULE_ERROR";

//** REPAYMENT TYPES */
export const UPLOADING_REPAYMENTS = "UPLOADING_REPAYMENTS";
export const UPLOADING_REPAYMENTS_SUCCESS = "UPLOADING_REPAYMENTS_SUCCESS";
export const UPLOADING_REPAYMENTS_ERROR = "UPLOADING_REPAYMENTS_ERROR";
export const GET_REPAYMENTS = "GET_REPAYMENTS";
export const GET_REPAYMENTS_SUCCESS = "GET_REPAYMENTS_SUCCESS";
export const GET_REPAYMENTS_ERROR = "GET_REPAYMENTS_ERROR";
export const DELETE_REPAYMENTS = "DELETE_REPAYMENTS";
export const DELETE_REPAYMENTS_SUCCESS = "DELETE_REPAYMENTS_SUCCESS";
export const DELETE_REPAYMENTS_ERROR = "DELETE_REPAYMENTS_ERROR";
export const PROCESSING_REPAYMENTS = "PROCESSING_REPAYMENTS";
export const START_PROCESSING_REPAYMENTS = "START_PROCESSING_REPAYMENTS";
export const PROCESSING_REPAYMENTS_ERROR = "PROCESSING_REPAYMENTS_ERROR";
export const PROCESSING_REPAYMENTS_SUCCESS = "PROCESSING_REPAYMENTS_SUCCESSS";
export const START_PROCESSING_REPAYMENTS_SUCCESS =
  "START_PROCESSING_REPAYMENTS_SUCCESS";
export const START_PROCESSING_REPAYMENTS_ERROR =
  "START_PROCESSING_REPAYMENTS_ERROR";
export const LOADING_REPORTS = "LOADING_REPORTS";
export const REPORTS_SUCCESS = "REPORTS_SUCCESS";
export const REPORTS_ERROR = "REPORTS_ERROR";
export const REPORTS_DOWNLOAD_SUCCESS = "REPORTS_DOWNLOAD_SUCCESS";
export const REPORTS_DOWNLOAD_ERROR = "REPORTS_DOWNLOAD_ERROR";
export const PRE_REPAYMENT_RPTS_DWNLD_SUCCESS = "PRE_REPAYMENT_RPTS_DWNLD_SUCCESS";
export const PRE_REPAYMENT_RPTS_DWNLD_ERROR = "PRE_REPAYMENT_RPTS_DWNLD_ERROR";
export const DOWNLOAD_PRE_REPAYMENTS_REPORTS = "DOWNLOAD_PRE_REPAYMENTS_REPORTS";

//** USER TYPES  */
export const LOADING_USERS = "LOADING_USERS";
export const GET_USERS = "GET_USERS";
export const GET_USERS_ERROR = "GET_USERS_ERROR";
export const GET_USERS_SUCCESS = "GET_USERS_SUCCESS";
export const LOADING_SINGLE_USER = "LOADING_SINGLE_USER ";
export const GET_SINGLE_USER = "GET_SINGLE_USER";
export const GET_SINGLE_USER_SUCCESS = "GET_SINGLE_USER_SUCCESS";
export const GET_SINGLE_USER_ERROR = "GET_SINGLE_USER_ERROR";

//** ADD USER TYPES */
export const ADDING_USER = "ADDING_USER";
export const ADDING_USER_SUCCESS = "ADDING_USER_SUCCESS";
export const ADDING_USER_ERROR = "ADDING_USER_ERROR";
export const EDITING_USER = "EDITING_USER";
export const EDITING_USER_SUCCESS = "EDITING_USER_SUCCESS";
export const EDITING_USER_ERROR = "EDITING_USER_ERROR";

//** UPDATE USER ROLES */
export const UPDATING_USER_ROLE = "UPDATING_USER_ROLE";
export const UPDATE_USER_ROLE_SUCCESS = "UPDATE_USER_ROLE_SUCCESS";
export const UPDATE_USER_ROLE_ERROR = "UPDATE_USER_ROLE_ERROR";

//** ROLE TYPES  */
export const LOADING_ROLES = "LOADING_ROLES";
export const GET_ROLES = "GET_ROLES";
export const GET_ROLES_ERROR = "GET_ROLES_ERROR";
export const GET_ROLES_SUCCESS = "GET_ROLES_SUCCESS";

// ** COMMISSION TYPES
export const LOADING_COMMISSION = "LOADING_COMMISSION";
export const LOADING_COMMISSION_SUCCESS = "LOADING_COMMISSION_SUCCESS";
export const LOADING_COMMISSION_ERROR = "LOADING_COMMISSION_ERROR";

export const LOADING_PAYMENT_TYPES = "LOADING_PAYMENT_TYPES";
export const PAYMENT_TYPES_SUCCESS = "PAYMENT_TYPES_SUCCESS";
export const PAYMENT_TYPES_ERROR = "PAYMENT_TYPES_ERROR";
export const PAY_COMMISSION = "PAYING_COMMISSION";
export const PAY_COMMISSION_ERROR = "PAY_COMMISSION_ERROR";
export const PAY_COMMISSION_SUCCESS = "PAY_COMMISSION_SUCCESS";
export const APPROVE_COMMISSION = "APPROVE_COMMISSION";
export const APPROVE_COMMISSION_SUCCESS = "APPROVE_COMMISSION_SUCCESS";
export const APPROVE_COMMISSION_ERROR = "APPROVE_COMMISSION_ERROR";
export const ADD_COMMISSION = "ADD_COMMISSION";
export const ADD_COMMISSION_SUCCESS = "ADD_COMMISSION_SUCCESS";
export const ADD_COMMISSION_ERROR = "ADD_COMMISSION_ERROR";
export const CALC_COMMISSION = "CALC_COMMISSION";
export const CALC_COMMISSION_SUCCESS = "CALC_COMMISSION_SUCCESS";
export const CALC_COMMISSION_ERROR = "CALC_COMMISSION_ERROR";

// ** LOAN CALCULATOR TYPES
export const CALCULATE_LOAN = "CALCULATE_LOAN";
export const CALCULATE_LOAN_SUCCESS = "CALCULATE_LOAN_SUCCESS";
export const CALCULATE_LOAN_ERROR = "CALCULATE_LOAN_ERROR";

// ** NOTIFICATION RESET TYPE
export const RESET_NOTIFICATION = "RESET_NOTIFICATION";
