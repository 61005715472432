import axios from "axios";
import { store } from "../store";
import { BASE_URL } from "../constants";
// const url = "http://localhost:8080/payroll/api/v1";
// const url = "https://payrolltest.linkexchange.com.gh/payroll/api/v1";

const instance = axios.create({
  baseURL: BASE_URL,
  headers: {
    "Content-Type": "application/json",
  },
});

instance.interceptors.response.use(
  function (response) {
    return response;
  },
  function (error) {
    console.log("[INTERCEPTOR ERROR]:: ", error);

    if (error.message === "Request failed with status code 403") {
      window.location.href = `/sign-in`;
    }

    return Promise.reject(error);
  }
);

export default instance;
