/*!
=========================================================
* Muse Ant Design Dashboard - v1.0.0
=========================================================
* Product Page: https://www.creative-tim.com/product/muse-ant-design-dashboard
* Copyright 2021 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/muse-ant-design-dashboard/blob/main/LICENSE.md)
* Coded by Creative Tim
=========================================================
* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/
import "antd/dist/antd.css";
import { Route, Switch } from "react-router-dom";
import "./assets/styles/main.css";
import "./assets/styles/responsive.css";
import Main from "./components/layout/Main";
import AddLoan from "./pages/AddLoan";
import EditLoan from "./pages/EditLoan";
import ApproveLoan from "./pages/ApproveLoan";
import Billing from "./pages/Billing";
import Client from "./pages/Client";
import Clients from "./pages/Clients";
import Commissions from "./pages/Commissions";
import Home from "./pages/Home";
import Loan from "./pages/Loan";
import Loans from "./pages/Loans";
import PayCommission from "./pages/PayCommission";
import Profile from "./pages/Profile";
import Repayments from "./pages/Repayments";
import Reports from "./pages/Reports";
import Rtl from "./pages/Rtl";
import SignIn from "./pages/SignIn";
import SignUp from "./pages/SignUp";

import UserDetails from "./pages/UserDetails";
import UsersList from "./pages/Users";
import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import NotFoundComponent from "./pages/404";
import AddNewCommission from "./pages/AddNewCommission";
import { openNotifcation } from "./components/Notification";
import { trackRepaymentStatus } from "./actions";

import LoanCalculator from "./pages/LoanCalculator";
import AffordabilityCalculator from "./pages/AffordabilityCalculator";

const App = () => {
  const user = useSelector(({ auth }) => auth.user);
  const repayments = useSelector(({ repayments }) => repayments);
  const dispatch = useDispatch();

  let myInterval = null;

  const handleProcessingComplete = (id) => {
    openNotifcation(
      "success",
      `Repayment file id - ${id} processing complete!`
    );
    console.log("[MY INTERVAL]:: ", myInterval);
    clearInterval(myInterval);
  };

  const handleProccessingError = (id) => {
    openNotifcation(
      "error",
      `Error processing repayment file id - ${id}. Please contact Tech Team for clarification!`
    );
    console.log("[MY INTERVAL]:: ", myInterval);
    clearInterval(myInterval);
  };

  const btnClickTest = (id) => {
    myInterval = setInterval(() => {
      dispatch(
        trackRepaymentStatus(id, {
          onSuccess: () => handleProcessingComplete(id),
          onError: () => handleProccessingError(id),
        })
      );
    }, 5000);
  };

  useEffect(() => {
    renderComponents();
  }, [user]);

  const renderComponents = () => {
    if (user) {
      const { userRole } = user;

      switch (userRole) {
        case "Admin":
          return (
            <React.Fragment>
              {/* <Route exact path="/dashboard" component={Home} /> */}
              {/* <Route exact path="/tables" component={Tables} />
              <Route exact path="/billing" component={Billing} />
              <Route exact path="/rtl" component={Rtl} />
              <Route exact path="/profile" component={Profile} /> */}
              <Route exact path="/users" component={UsersList} />
              <Route exact path="/users/view/:userId" component={UserDetails} />
              <Route exact path="/loans" component={Loans} />
              <Route exact path="/loan/:loanId" component={Loan} />
              <Route
                exact
                path="/approve-loan/:loanId"
                component={ApproveLoan}
              />
              <Route exact path="/commissions" component={Commissions} />
              <Route exact path="/pay-commission" component={PayCommission} />
              <Route exact path="/repayments" component={Repayments} />
              <Route exact path="/clients" component={Clients} />
              <Route exact path="/add-loan" component={AddLoan} />
              <Route exact path="/edit-loan" component={EditLoan} />
              <Route exact path="/client/:clientId" component={Client} />
              <Route exact path="/reports" component={Reports} />
              <Route exact path="/loan-calculator" component={LoanCalculator} />
              <Route exact path="/affordability-calculator" component={AffordabilityCalculator} />
            </React.Fragment>
          );

        case "Admin Officer":
          return (
            <React.Fragment>
              <Route exact path="/clients" component={Clients} />
              <Route path="/client/:clientId" component={Client} />
              <Route exact path="/add-loan" component={AddLoan} />
              <Route exact path="/edit-loan" component={EditLoan} />
              <Route exact path="/repayments" component={Repayments} />
              <Route path="/reports" component={Reports} />
              <Route exact path="/loan-calculator" component={LoanCalculator} />
              <Route exact path="/affordability-calculator" component={AffordabilityCalculator} />
            </React.Fragment>
          );

        case "Admin Manager":
          return (
            <React.Fragment>
              <Route exact path="/loans" component={Loans} />
              <Route exact path="/loan/:loanId" component={Loan} />
              <Route
                exact
                path="/approve-loan/:loanId"
                component={ApproveLoan}
              />
              <Route exact path="/clients" component={Clients} />
              <Route path="/client/:clientId" component={Client} />
              <Route exact path="/repayments" component={Repayments} />
              <Route path="/reports" component={Reports} />
              <Route exact path="/loan-calculator" component={LoanCalculator} />
              <Route exact path="/affordability-calculator" component={AffordabilityCalculator} />
            </React.Fragment>
          );

        case "Loan Manager":
          return (
            <React.Fragment>
              <Route exact path="/clients" component={Clients} />
              <Route path="/client/:clientId" component={Client} />
              <Route exact path="/loans" component={Loans} />
              <Route exact path="/loan/:loanId" component={Loan} />
              <Route exact path="/add-loan" component={AddLoan} />
              <Route exact path="/edit-loan" component={EditLoan} />
              <Route exact path="/loan-calculator" component={LoanCalculator} />
              <Route exact path="/affordability-calculator" component={AffordabilityCalculator} />
            </React.Fragment>
          );

        default:
          return (
            <React.Fragment>
              <Route exact path="/loans" component={Loans} />
              <Route exact path="/loan/:loanId" component={Loan} />
              <Route exact path="/loan-calculator" component={LoanCalculator} />
              <Route exact path="/affordability-calculator" component={AffordabilityCalculator} />
            </React.Fragment>
          );
      }
    }
  };

  return (
    <Switch>
      <Route path="/sign-up" exact component={SignUp} />
      <Route path="/sign-in" exact component={SignIn} />
      <Route exact path="/" component={SignIn} />

      <Main>
        {/* <Route exact path="/dashboard" component={Home} /> */}
        {/* {renderComponents()} */}
        <Route exact path="/users" component={UsersList} />
        <Route exact path="/users/view/:userId" component={UserDetails} />
        <Route exact path="/loans" component={Loans} />
        <Route exact path="/loan/:loanId" component={Loan} />
        <Route exact path="/approve-loan/:loanId" component={ApproveLoan} />
        <Route exact path="/commissions" component={Commissions} />
        <Route exact path="/pay-commission" component={PayCommission} />
        <Route exact path="/new-commission" component={AddNewCommission} />
        <Route
          exact
          path="/repayments"
          render={(props) => (
            <Repayments {...props} onProcessClicked={btnClickTest} />
          )}
        />
        <Route exact path="/clients" component={Clients} />
        <Route exact path="/add-loan" component={AddLoan} />
        <Route exact path="/edit-loan/:loanId" component={EditLoan} />
        <Route exact path="/client/:clientId" component={Client} />
        <Route exact path="/reports" component={Reports} />
        <Route exact path="/loan-calculator" component={LoanCalculator} />
        <Route exact path="/affordability-calculator" component={AffordabilityCalculator} />
      </Main>
    </Switch>
  );
};

export default App;
