import payrollApi from "../api/payrollApi";
import { store } from "../store";
import { GET_ROLES_ERROR, GET_ROLES_SUCCESS, LOADING_ROLES } from "./types";

store.subscribe(listener);

let token;

function select(state) {
  if (state.auth.role) {
    return state.auth.role.token;
  }
}

function listener() {
  token = select(store.getState());
  if (token) {
    payrollApi.defaults.headers.common["Authorization"] = `Bearer ${token}`;
  }
}

const getAllRolesSuccess = (dispatch, roles) => {
  dispatch({
    type: GET_ROLES_SUCCESS,
    payload: roles,
  });
};

const getAllRolesError = (dispatch, error) => {
  dispatch({
    type: GET_ROLES_ERROR,
    payload: error,
  });
};

export const getAllRoles = (params) => {
  return (dispatch) => {
    dispatch({
      type: LOADING_ROLES,
    });

    return new Promise((resolve, reject) => {
      payrollApi
        .get(`/roles?page=${params.page}&pageSize=${params.size}`)
        .then((roles_res) => {
          const { data } = roles_res;

          console.log("[ROLES RES]:: ", data.pageItems);
          getAllRolesSuccess(dispatch, data.pageItems);
        })
        .catch((err) => {
          //** THROW SYSTEM ERROR */
          reject(err);
          getAllRolesError(dispatch, err);
          console.log("[GET ALL ROLES ERROR]:: ", err);
        });
    });
  };
};
