import React, { useEffect } from "react";

import { Row, Col, Card, Table, Button, Typography } from "antd";

import { withRouter } from "react-router-dom";
import { pendingCommissions } from "../data/pending_comms";
import moment from "moment";
import { openNotifcation } from "../components/Notification";

// import { commissions } from "../data/commissions";
import { useDispatch, useSelector } from "react-redux";
import { getCommissions } from "../actions";
import "./Loans.scss";

const { Title } = Typography;

// table code start
const pendingCommissionsColumn = [
  {
    title: "AGENT",
    dataIndex: "agent",
    key: "agent",
  },
  {
    title: "LOAN #",
    dataIndex: "loanNumber",
    key: "loanNumber",
  },

  {
    title: "CLIENT",
    key: "client",
    dataIndex: "client",
  },
  {
    title: "LOAN VALUE",
    key: "loanValue",
    dataIndex: "loanValue",
  },
  {
    title: "COMMISSION DUE",
    key: "commissionDue",
    dataIndex: "commissionDue",
  },
  {
    title: "DATE",
    key: "date",
    dataIndex: "date",
  },
  {
    title: "ACTION",
    key: "action",
    dataIndex: "action",
  },
];

// project table start
const commisionColumns = [
  {
    title: "AGENT",
    dataIndex: "agent",
  },
  {
    title: "LOAN #",
    dataIndex: "loanNumber",
  },
  {
    title: "CLIENT",
    dataIndex: "client",
  },
  {
    title: "LOAN VALUE",
    dataIndex: "loanValue",
  },
  {
    title: "COMMISSION PAID",
    dataIndex: "commissionPaid",
  },
  {
    title: "COMMISSION DUE",
    dataIndex: "commissionDue",
  },
  {
    title: "PAID",
    dataIndex: "paid",
  },
  {
    title: "ACTION",
    dataIndex: "action",
  },
];

const Commissions = withRouter(({ history }) => {
  const commissions = useSelector(({ commissions }) => commissions.data);
  const loading = useSelector(({ commissions }) => commissions.loading);
  const error = useSelector(({ commissions }) => commissions.error);

  const dispatch = useDispatch();

  const fetchCommissions = (params) => {
    dispatch(getCommissions(params));
  };

  useEffect(() => {
    fetchCommissions({ size: 10, page: 0 });
  }, []);

  const renderProcessColor = (paid) => {
    switch (paid) {
      case false:
        return "arrears";

      default:
        return "approved";
    }
  };

  //** Route to Pending Commision */
  const routeToComm = (commission, pending) => {
    history.push({
      pathname: "/pay-commission",
      state: { commission, pending },
    });
  };

  let pendingCommissionRows = [];
  if (pendingCommissions.length !== 0) {
    pendingCommissions.map((comm, i) => {
      comm.Date = moment(comm.date).format("DD-MM-YYYY");

      const _comm = {
        key: `pending-comm-${i}`,
        agent: (
          <>
            <div className="avatar-info">
              <Title level={5}>{comm.agentName}</Title>
            </div>
          </>
        ),
        loanNumber: (
          <>
            <div className="avatar-info">
              <Title level={5}>{comm.loanId}</Title>
            </div>
          </>
        ),
        client: (
          <>
            <div className="avatar-info">
              <Title level={5}>{comm.clientName}</Title>
            </div>
          </>
        ),
        loanValue: (
          <>
            <div className="avatar-info">
              <Title level={5}>{comm.disbursedLoanAmt}</Title>
            </div>
          </>
        ),
        commissionDue: (
          <>
            <div className="avatar-info">
              <Title level={5}>{comm.commissionDue}</Title>
            </div>
          </>
        ),
        date: (
          <>
            <div className="avatar-info">
              <Title level={5}>{comm.withholdingTaxAmt}</Title>
            </div>
          </>
        ),
        action: (
          <>
            <Button
              onClick={() => routeToComm(comm, comm.paid)}
              type="default"
              className="tag-primary"
            >
              Pay
            </Button>
          </>
        ),
      };

      pendingCommissionRows.push(_comm);
    });
  }

  let commissionRows = [];
  if (commissions.length !== 0) {
    commissions.map((comm, i) => {
      const _comm = {
        key: `pending-comm-${i}`,
        agent: (
          <>
            <div className="avatar-info">
              <Title level={5}>{comm.agentName}</Title>
            </div>
          </>
        ),
        loanNumber: (
          <>
            <div className="avatar-info">
              <Title level={5}>{comm.loanId}</Title>
            </div>
          </>
        ),
        client: (
          <>
            <div className="avatar-info">
              <Title level={5}>{comm.clientName}</Title>
            </div>
          </>
        ),
        loanValue: (
          <>
            <div className="avatar-info">
              <Title level={5}>GHC {comm.disbursedLoanAmt}</Title>
            </div>
          </>
        ),
        commissionDue: (
          <>
            <div>
              {!comm.paid && (
                <Title level={5}>GHC {comm.totalCommission}</Title>
              )}
            </div>
          </>
        ),
        commissionPaid: (
          <>
            <div className="avatar-info">
              {comm.paid ? (
                <Title level={5}>GHC {comm.totalCommission}</Title>
              ) : (
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                  }}
                >
                  <Title level={5}> --- </Title>
                </div>
              )}
            </div>
          </>
        ),
        paid: (
          <>
            <div className={`avatar-info`}>
              <Title className={renderProcessColor(comm.paid)} level={5}>
                {JSON.stringify(comm.paid)}
              </Title>
            </div>
          </>
        ),
        action: (
          <>
            <Button
              onClick={() => routeToComm(comm, true)}
              type="default"
              className="tag-primary"
            >
              Pay
            </Button>
          </>
        ),
      };

      commissionRows.push(_comm);
    });
  }

  const routeToAddCommission = () => {
    history.push("/new-commission");
  };

  return (
    <>
      <div className="tabled">
        <Row gutter={[24, 0]}>
          <Col xs="24" xl={24}>
            <Card
              bordered={false}
              className="criclebox tablespace mb-24"
              title="Commissions"
              extra={
                <>
                  <Button onClick={() => routeToAddCommission()} type="primary">
                    Add New Commission
                  </Button>
                </>
              }
            >
              <div className="table-responsive">
                <Table
                  loading={loading}
                  columns={commisionColumns}
                  dataSource={commissionRows}
                  pagination={false}
                  className="ant-border-space"
                />
              </div>
            </Card>
          </Col>
        </Row>
        {error &&
          openNotifcation(
            "error",
            "Commissions Error",
            "Oops, error getting commissions data"
          )}
      </div>
    </>
  );
});

export default Commissions;
